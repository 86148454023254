.ir-ws-coming-main-container {
    padding-top: 83px;
    .ir-ws-coming-bg {
        display: block;
        width: 100%;
        height: 100%;
        // position: absolute;
    }

    .ir-ws-coming-inner-container {
        height: calc(100vh - 85px);
        // background: url('../../assets/images/coming-soon-bg.png');
        .ir-ws-coming-sub-container {
            top: 50%;
            left: 50%;
            transform: translateY(-55%) translateX(-50%);
            max-width: 800px;
            padding: 0 50px;

            .ir-ws-coming-heading {
                // color: #fff;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 50px;
                margin: 0;
                padding-bottom: 20px;
                font-weight: bold;
                letter-spacing: 0.2px;
                animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
                animation-duration: 4s; 
            }

            .ir-ws-coming-content {
                text-align: center;
                // color: #fff;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0.2px;
                margin: 0;
                margin-bottom: 16px;
                color: #737373;
                margin: 0;
                animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
                animation-duration: 4s; 
            }
        }
        
        .ir-ws-cominsoon-txt {
            text-align: center;
            font-weight: bold;
            margin: 0;
            font-size: 16px;
            animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
            animation-duration: 4s; 
        }

        .ir-ws-comingl-bg-img {
            bottom: 0;
            right: 0;
            // transform: scale(1.2);
            transform-origin: bottom right;
            opacity: 0.5;
        }
        .ir-ws-comingtop-bg-img {
            bottom: 0;
            left: 0;
            transform: rotateY(180deg);
        }

        
        .ir-ws-animate-excel {
            animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
            animation-duration: 4s; 
        }
    }
}