.ir-ws-hme-exprt-inner-container {
    margin-top: 100px;

    .ir-ws-hme-exprt-img-card {
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 5px 19px 0px rgba(0, 0, 0, 0.1);

        .ir-ws-prvw-card-thumbnail-container {
            width: 150px;
            height: 150px;
            border: 1px solid rgba(0,0,0,0.08);
        }
    }

    .ir-ws-hme-exprt-card-body-content-container {
        margin-top: 80px;

        .ir-ws-hme-exprt-name {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.1px;
            margin: 0;
        }

        .ir-ws-hme-exprt-designation {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.1px;
            margin: 0;
            padding-bottom: 15px;
        }

        .ir-ws-hme-exprt-card-content-container {
            padding: 0 15px;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                background-image: url('../../assets/images/quote-left.png');
                width: 16px;
                height: 16px;
            }

            &:after {
                position: absolute;
                content: '';
                bottom: 0;
                right: 0;
                background-image: url('../../assets/images/quote-right.png');
                width: 16px;
                height: 16px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.1px;
                padding: 15px 0;
                margin-bottom: 0;
                // text-align: justify;
            }
        }
    }

    .slick-slider {
        width: 100%;

        .slick-track {
            display: flex !important;
            padding-top: 100px;
            padding-bottom: 20px;

            .slick-slide {
                height: inherit;
                // background-color: red;

                > div {
                    height: 100%;
                    margin-right: 20px;
                    box-shadow: 0px 5px 19px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 16px;
                    // background-color: yellow;

                    .ir-ws-hme-prvw-card-container {
                        // background-color: pink;
                        height: 100%;
                        // background-color: red;
                        padding-right: 0;

                        .ir-ws-card-main-container {
                            box-shadow: none;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}