.ir-ws-webinar-user-screen-container {
  width: 90vw;
  height: 90vh;
  margin: 0 auto;

  video {
    width: 100%;
    height: 100%;
    object-fit: none !important;
  }
}

// .ir-ws-webinar-user-main-screen-container
//   .ir-ws-webinar-presenter-meeting-container {
//   height: 100%;
//   background-color: #000;
// }

.ir-ws-presenter-user-remote-video {
  width: 100% !important;
  height: 100% !important;
}

.ir-ws-webinar-user-video-content-share-active {
  width: 100% !important;
  height: 100% !important;
}

.ir-ws-webinar-user-main-screen-container
  .ir-ws-webinar-presenter-meeting-control-container {
  bottom: 20px !important;
  background-color: transparent !important;
  border: none !important;
}
.ir-ws-webinar-black-user-screen {
  width: 100% !important;
  height: 100% !important;
  background-color: black;
  border-radius: 15px;
}
.ir-ws-webinar-participant-full-screen-active {
  width: 30px;
  color: #fff;
  position: absolute;
  bottom: 85px;
  right: 10px;
  cursor: pointer;
  z-index: 3;
}
.ir-ws-webinar-participant-full-screen-inactive {
  width: 30px;
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 3;
}

.ir-ws-webinar-user-meeting-control-container {
  width: auto !important;

  .ir-ws-webinar-presenter-meeting-leave-btn {
    width: 200px;
    margin: 0 auto;
    padding: 15px 0 !important;
    font-weight: bold;
    // display: block;
  }
}
.ir-ws-webinar-user-chat-message-container {
  // box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100% !important;
}
.ir-ws-webinar-user-chat-container {
  // height: 60% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ir-ws-webinar-user-chat-message-box {
  height: 100% !important;
}

/* @media only screen and (max-width: 1200px) {
  body {
    margin-inline: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  body {
    margin-inline: 1rem;
  }
}
@media only screen and (max-width: 500px) {
  body {
    margin-inline: 2rem;
  }
} */

.ir-ws-webinar-user-group-btn {
  display: none;
}
.ir-ws-webinar-user-chat-btn {
  display: none;
}
@media only screen and (max-width: 768px) {
  .ir-ws-webinar-user-chat-leave-box {
    gap: 10px;
  }
  .ir-ws-webinar-user-group-btn {
    display: block;
  }
  .ir-ws-webinar-user-chat-btn {
    display: block;
  }
}
