.ir-ws-webinar-payment-success-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 75%,
    rgba(172, 195, 146, 0.5) 100%
  );

  .ir-ws-webinar-payment-success-box {
    width: 450px;
    .ir-ws-webinar-payment-ir4u-logo {
      width: 150px;
      padding-bottom: 30px;
    }
    .ir-ws-webinar-payment-success-logo-container {
      flex-direction: column;
      border-radius: 15px;
      background-color: #fff;
      // border: 2px solid #63b863;

      .ir-ws-webinar-payment-success-logo {
        width: 100px;
        padding-block: 30px;
      }

      .ir-ws-webinar-payment-success-text {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        margin: 0;
        color: #63b863;
        text-transform: uppercase;
      }
      .ir-ws-webinar-payment-receipt-date-text {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        color: #a3a3a3;
        padding-block: 5px;
      }
      .ir-ws-webinar-payment-receipt-text {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
      .ir-ws-webinar-payment-status-button-container {
        margin: 0;
        padding-block: 30px;
        button {
          width: 150px;
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.ir-ws-webinar-payment-failure-container {
  background: linear-gradient(
    rgba(255, 255, 255, 0) 75%,
    rgba(195, 146, 146, 0.5) 100%
  );
  .ir-ws-webinar-payment-success-box {
    .ir-ws-webinar-payment-failure-logo-container {
      // border: 2px solid #f44336;
      .ir-ws-webinar-payment-failure-text {
        color: #f44336;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
}

.ir-ws-webinar-payment-progress-container {
  background: linear-gradient(
    rgba(255, 255, 255, 0) 75%,
    rgba(255, 192, 72, 0.5) 100%
  );
  .ir-ws-webinar-payment-success-box {
    .ir-ws-webinar-payment-progress-logo-container {
      border: 2px solid #ffc048;
      .ir-ws-webinar-payment-progress-logo {
        width: 130px;
        padding-block: 30px;
      }
      .ir-ws-webinar-payment-progress-text {
        color: #ffc048;
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
}

.payment-process-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-processing-text {
  font-size: 48px;
  font-weight: 700;
}
.order-dnc-text {
  font-size: 18px;
  font-weight: 600;
}

.css-loader-box {
  margin-top: 60px;
}

@media (max-width: 500px) {
}
