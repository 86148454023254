.ir-ws-webiner-admin-container {
  position: relative;
}
.ir-ws-webiner-admin-inner-container {
  padding-left: 25px;
  padding-top: 10px;

  .ir-ws-webinar-text {
    font-family: Poppins-Bold;
    margin: 0;
    color: rgba(104, 66, 239, 0.5);
    text-transform: uppercase;
    font-size: 60px;
    position: absolute;
    right: 50px;
    top: 3px;
    line-height: 1;
  }
}