.ir-ws-terms-and-condition-container {
  margin-top: 35px;
  .ir-ws-terms-and-condition-input {
    width: 50px;
    transform: scale(1.5);
  }
  p {
    margin: 0;
    .ir-ws-terms-and-condition-link {
      text-decoration: none;
      color: #6610f2;
      font-weight: 600;
    }
  }
}
.ir-ws-book-again-box {
  margin-top: 35px;
  text-align: center;
}
.ir-ws-book-again-link {
  text-decoration: none;

  color: #ef8042;
  &:hover {
    color: #ef8042;
  }
}
.ir-ws-book-again-text {
  margin: 0;
  font-size: 18px;
  // color: #ef8042;

  // &:hover {
  //   text-decoration: underline;
  // }
}
@media (max-width: 500px) {
  .ir-ws-terms-and-condition-container .ir-ws-terms-and-condition-input {
    margin-top: -25px;
  }
}
@media (max-width: 768px) {
  .ir-ws-sign-popup-container
    .ir-ws-sign-popup-inner-container
    .ir-ws-signup-content-title {
    font-size: 24px;
  }

  .ir-ws-sign-popup-container
    .ir-ws-sign-popup-inner-container
    .ir-ws-signup-close-container {
    right: -10px;
  }
}
