.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
  }
  
  .spinning-icon {
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; 
    user-select: none;
    pointer-events: none;
  }
  .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span{
      margin-top: 3px;
      color: white;
      font-weight: bold;
    }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  