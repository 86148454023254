.ir-courses-container-thumb-img-container {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ir-courses-container-main-container{
  // border: 2px solid red;
  height: 540px;
  .ir-courses-container-inner-container{
    .ir-courses-container-thumb-container{
      // border: 2px solid red;
      height: 260px;
      .ir-courses-container-thumb-img-container{
        // height: 225px;
        height: 100%;
        // border: 2px solid rgb(0, 8, 255);
        width: 100%;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .ir-courses-container-content-container{
      // border: 2px solid rgb(238, 255, 0);
      height: 280px;
    }
  }
}

img.ir-courses-container-thumb-img {
  max-width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.ir-courses-container-play-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
}

img.ir-courses-container-thumb-play-btn {
  max-width: 100%;
}

.ir-courses-container-thumb-img-container:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ir-courses-container-content-inner-container {
  background: #cbbdff;
  padding: 20px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  // border: 2px solid red;
}

.ir-courses-container-title-container {
  // text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
  height: 50px;
}

.ir-courses-content-container-para {
  font-size: 14px;
  height: 120px;
}

.ir-courses-price-bottom-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.ir-courses-container-price {
  color: #6842ef;
  font-weight: 700;
  font-size: 20px;
}

.ir-courses-container-readmore {
  font-size: 14px;
  color: #6842ef;
  font-weight: 700;
  cursor: pointer;
}

.ir-courses-container-content-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.ir-courses-container-inner-container {
  border-radius: 10px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.ir-pop-static-content-container {
  max-width: 1300px;
  margin: 0 auto;
}
.ir-pop-static-inner-container {
  padding-top: 75px;
}

p.ir-pop-static-content-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.ir-pop-static-content-container {
  .ir-pop-static-inner-content-container {
    // align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    // gap: 10rem;
    gap: 30px;
    flex-direction: column;
    .ir-ws-pop-static {
      width: 100%;
      flex-direction: column;
      gap: 20px;
      img {
        margin-right: 0;
      }
      p {
        text-align: center;
      }
    }
  }
}

.ir-ws-pop-static.div-container {
  width: 45%;
  align-items: center;
}

.ir-ws-pop-static.div-container img {
  // width: 175px;
  margin-right: 35px;
  max-height: 120px;
}

.ir-pop-courses-inner-container h3 {
  margin-bottom: 30px;
}

.ir-courses-container-readmore:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #6842ef;
}
.ir-ws-course-homepage-rating-container {
  position: absolute;
  top: 10px;
  left: 10px;
}

.ir-courses-container-play-container {
  .ir-pop-course-play-btn {
    font-size: 80px;
  }
}

@media (min-width: 768px) {
  .ir-courses-price-bottom-container {
    margin-top: 30px;
  }
  .ir-courses-content-container-para {
    font-size: 16px;
  }
}

@media (min-width: 991px) {
}
@media (min-width: 1200px) {
  // .ir-courses-container-title-container {
  //   height: 50px;
  // }
  .ir-pop-static-content-container {
    .ir-pop-static-inner-content-container {
      // align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      // gap: 10rem;
      gap: 30px;
      flex-direction: row;
      .ir-ws-pop-static {
        width: 100%;
        flex-direction: row;
        gap: 20px;
        img {
          margin-right: 0;
        }
        p {
          text-align: start;
        }
      }
    }
  }
  .ir-courses-content-container-para {
    // height: 100px;
  }
}
