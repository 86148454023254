// .ir-ws-course-video-player-container {
//   padding-top: 150px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding-top: 100px;
// }

.ir-ws-course-video-player-size {
  // width: 840px;
  // height: 520px;
  width: 340px;
  height: 220px;
  background-color: #000;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .ir-ws-course-video-player-size {
    width: 480px;
    height: 320px;
  }
}

@media (min-width: 991px) {
  .ir-ws-course-video-player-size {
    width: 720px;
    height: 480px;
  }
}
