.ir-ws-hme-join-main-container {
    padding-top: 150px;
    padding-bottom: 150px;

    .ir-ws-hme-join-inner-container {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;

        .ir-ws-hme-highlight-text {
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 24px;
        }

        .ir-ws-hme-join-title {
            font-weight: bold;
            font-size: 40px;
            line-height: 50px;
            letter-spacing: 0.2px;
        }

        .ir-ws-hme-join-para-content {
            font-size: 14px;
            padding-bottom: 80px;
        }

        .ir-ws-hme-join-input-container {
            display: flex;
            width: 100%;
            align-content: stretch;

            .ir-ws-hme-join-input-field {
                flex: 1 0 auto;
                padding: 15px 20px;
                font-size: 14px;
                line-height: 28px;
                height: 54px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
                width: 50%;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border: 1px solid #E6E6E6;
                }
            }

            .ir-ws-hme-join-sub-btn {
                padding: 15px 20px;
                line-height: 28px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px; /* 157.143% */
                letter-spacing: 0.2px;
                position: relative;
                background-size: 400%;
                height: 54px;
                background-size: 400%;
                color: #fff;
                border: none;
            

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    width: 100%;
                    height: inherit;
                    border-radius: inherit;
                    background: rgb(43,167,255);
                    background: linear-gradient(90deg, rgba(43,167,255,1) 0%, rgba(104,66,239,1) 100%);
                    transition: all 0.8s;
                    }

                &:hover::before {
                    transform: scaleX(1);
                }

                span {
                    position: relative;
                    z-index: 1;
                }
                
            }
        }
    }

    .ir-ws-thansk-newsletter-subsciber-container {
        width: fit-content;
        // background-color: red;
        margin: auto;
        padding: 5px 10px;
        display: none;

        &.ir-ws-display-successMessage {
            display: block;
        }

        &.ir-ws-display-existingSubscriberMessage {
            display: block;

            p {
                color: red;
            }
        }

        p {
            font-size: 12px;
            margin-bottom: 0;
        }
    }
}

@media (min-width: 1024px) {
    .ir-ws-hme-join-main-container .ir-ws-hme-join-inner-container .ir-ws-hme-join-input-container .ir-ws-hme-join-input-field {
        width: calc(100% - 115px);
    }
}