.ir-ws-purchase-history-conatiner {
  .ir-ws-purchase-history-title-text {
    font-size: 24px;
    margin: 0;
    margin-block: 30px;
    // font-weight: 600;
    font-weight: bold;
    color: #6842ef;
  }
  .ir-ws-purchase-history-box {
    border: 1px solid #929292;
    width: 100%;
    // padding-bottom: 2rem;
    // border: 2px solid red;
    .ir-ws-purchase-history-title-container {
      list-style: none;
      margin: 0;
      background-color: #e9e9e9;
      border-bottom: 1px solid #929292;
      padding: 0;

      li {
        width: 300px;
        text-align: center;
        padding-block: 15px;
        text-transform: uppercase;
        color: #7e7e7e;
        // font-weight: 600;
        font-weight: bold;
      }
    }
    .ir-ws-purchase-history-details-container {
      overflow-x: scroll;
      // height: 500px;
      height: 100%;
      max-height: 500px;
      .ir-ws-purchase-history-details-box {
        border-bottom: 1px solid #929292;
        &:last-child {
          border-bottom: none;
        }
        .ir-ws-purchase-history-title-details-container {
          background-color: #fff;
          list-style: none;
          margin: 0;
          // border-bottom: 1px solid #929292;
          padding: 0;
          li {
            width: 300px;
            text-align: center;
            padding-block: 15px;
            text-transform: uppercase;
            color: #7e7e7e;
            // font-weight: 600;
            font-weight: bold;
            .ir-ws-purchase-accordian-dropdown-active {
              background-color: #6842ef;
              padding: 8px 48px;
              border-radius: 4px;
              cursor: pointer;
              .ir-wws-purchase-history-dropdown {
                color: #fff;
              }
              .ir-wws-purchase-history-dropdown {
                width: 24px;
              }
            }
            .ir-ws-purchase-accordian-dropdown-inactive {
              padding: 8px 48px;
              border-radius: 4px;
              background-color: #e9e9e9;
              cursor: pointer;
              transition: background-color 0.3s;
              &:hover {
                background-color: #6842ef;
                .ir-wws-purchase-history-dropdown {
                  color: #fff;
                }
              }
              .ir-wws-purchase-history-dropdown {
                width: 24px;
              }
            }
          }
        }
        .ir-ws-purchase-history-accordian-title {
          background-color: #fff;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            width: 300px;
            text-align: center;
            padding-block: 15px;
            padding-top: 40px;
            text-transform: uppercase;
            color: #7e7e7e;
            font-weight: 500;
          }
        }
        .ir-ws-purchase-history-accordian-details {
          background-color: #fff;
          list-style: none;
          margin: 0;
          // border-bottom: 1px solid #929292;
          padding: 0;
          li {
            width: 300px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 40px;
            color: #7e7e7e;
            // font-weight: 600;
            font-weight: bold;
          }
          .ir-ws-purchase-status-active {
            color: #009406;
          }
          .ir-ws-purchase-status-cancelled {
            color: #f03e3e;
          }
          .ir-ws-purchase-status-completed {
            color: #4263eb;
          }

          .ir-ws-purchase-history-copy-passcode-button {
            width: 24px;
            cursor: pointer;
            margin-left: 4px;
            &:hover {
              color: #6842ef;
            }
          }
        }
      }
    }
  }
}
