.ir-ws-webinar-page-webinar-list-main-container {
  background: linear-gradient(
      to bottom,
      rgba(230, 253, 252, 1) 0%,
      rgba(230, 253, 252, 0) 40%
    ),
    #ffffff;

  .ir-ws-hme-webinar-sessions-container {
    width: 100%;
    display: block;

    .ir-ws-hme-prvw-card-container {
      display: inline-block;
      margin-bottom: 30px;
      padding-right: 20px;

      .ir-ws-card-main-container {
        .ir-ws-card-body {
          padding: 20px 25px;
        }
        .ir-ws-hme-webinar-crd-para {
          height: 40px;
          margin-top: 0;
        }
      }
    }
  }

  .ir-ws-hme-webinar-tag-container {
    font-size: 12px;
    padding: 5px 0;
    font-weight: bold;
    color: #fff;
  }

  .ir-ws-webinar-page-webinar-list-title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 30px;
    padding-left: 132px;
  }

  .ir-ws-hme-webinar-division-main-container {
    width: 100%;
  }
}

.ir-ws-webinar-homepage-show-more-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  p {
    margin: 0;
    font-weight: bold;
    color: #6842ef;
    cursor: pointer;
  }
}
.ir-ws-webinar-list-page-dotted-border {
  border-top: 2px dotted #6842ef;
  border-bottom: 2px dotted #6842ef;
  height: 6px;
  width: 100%;
}
.ir-ws-weinar-active-container {
  padding-bottom: 0;
}
.ir-ws-weinar-upcoming-container {
  padding: 0;
  padding-top: 25px;
}
.ir-ws-weinar-completed-container {
  padding-top: 0;
  padding-top: 30px;
}

@media (max-width: 768px) {
  .ir-ws-webinar-page-webinar-list-main-container
    .ir-ws-webinar-page-webinar-list-title {
    padding-left: 0px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .ir-ws-webinar-page-webinar-list-main-container {
    .ir-ws-hme-webinar-division-main-container {
      width: 50%;
    }
  }
}

@media (min-width: 991px) {
  .ir-ws-webinar-page-webinar-list-main-container {
    .ir-ws-hme-webinar-division-main-container {
      width: 33.33%;
    }
  }
}

@media (min-width: 1300px) {
  .ir-ws-webinar-page-webinar-list-main-container {
    .ir-ws-hme-webinar-division-main-container {
      width: 25%;
    }
  }
}

// @media (min-width: 1400px) {
// 	.ir-ws-webinar-page-webinar-list-main-container {
// 		.ir-ws-hme-webinar-division-main-container {
// 			width: 20%;
// 		}
// 	}
// }

@media (min-width: 1600px) {
  .ir-ws-webinar-page-webinar-list-main-container {
    .ir-ws-hme-webinar-division-main-container {
      width: 20%;
    }
  }
}
