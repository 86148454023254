.features-container {
    text-align: center;
    // padding: 20px;
    // border: 2px solid red;
    padding-top: 0 !important;
  
    .features-title {
      font-size: 36px;
      margin-bottom: 10px;
      // color: #6842EF;
      font-weight: 600;
      @media (max-width:720px) {
        font-size: 26px;
        
      }
      @media (max-width:1300px) {
        font-size: 30px;
        
      }
    }
  
    .features-description {
      max-width: 1200px;
      margin: 0 auto;
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 80px;
      // color: #000;
      @media (max-width:720px) {
        font-size: 18px;
        
      }
      @media (max-width:1300px) {
        font-size: 20px;
        
      }

      .ir-title-features{
        color: #6842EF;
        font-size: 22px;
        font-weight: 600;
        @media (max-width:720px) {
          font-size: 20px;
          
        }
      }
  
    }
    
  
    .features-grid {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 70px;
  
      @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        // gap: 40px;
        // width: 80%;
      }
  
      @media (max-width: 860px) {
        grid-template-columns: 1fr;
        width: 300px;
        margin: 0 auto;
        // padding: 0 20px;
      }
    }
  
    .feature-card {
      border: 2px solid #6842EF;
      border-radius: 10px;
      padding: 30px 20px 20px 20px;
      width: 340px;
      height: 340px;
      @media (max-width: 860px) {
        width: 300px;
        height: 320px;
        padding: 25px 20px 20px 20px;
      }
  
      .icon {
        // background: no-repeat center;
        background-size: contain;
        height: 60px;
        margin-bottom: 20px;
      }
      
  
      .feature-title {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: center;
        color: #6842EF;
        font-weight: 600;
      }
  
      .feature-description {
        font-size: 14px;
        color: #212529;
        font-weight: 500;
        text-align: center;
        margin: 0 10px;

      }
    }
  }
  