.ir-footer-man-title-container {
  img {
    width: 200px;
  }

  p.ir-ws-footer-tagline {
    font-size: 12px;
    text-align: center;
    width: max-content;
    // box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.1);
    color: #fff;
    background-color: #737373;
    margin: 0;
    padding: 0 5px;
    font-weight: 500;
  }
}

.ir-ws-footer-main-inner-container {
  .ir-footer-man-title-container {
    p {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
  .ir-ws-footer-division-title-container {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.1px;
    margin-bottom: 20px;
  }

  .ir-ws-footer-list-container {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    .ir-ws-footer-list-item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
      .ir-ws-footer-list-link {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.2px;

        &:hover {
          color: #6842ef;
          text-decoration: none;
        }

        i {
          color: #6842ef;
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }
}

.ir-ws-footer-social .ir-ws-footer-list-container {
  display: flex;
  padding: 25px 0;
  margin-bottom: 0;
  justify-content: center;
  .ir-ws-footer-list-item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    .ir-ws-footer-list-link i {
      font-size: 25px;
      // color: #F6F7FB;

      // &:hover {
      //     -webkit-box-shadow: 0 0 15px 30px #4267B2 inset;
      //     box-shadow: 0 0 15px 30px #4267B2 inset;
      // }
    }
  }
}

.ir-ws-connect-inner-container {
  background-color: #f6f7fb;
  max-width: 1100px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
    0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
    0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
    0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);

  .contact-box {
    padding: 70px 50px;

    p {
      font-size: 15px;
      opacity: 0.8;
      margin-bottom: 16px;
    }
  }
}

.ir-ws-social-footer-icon-inner-container {
  max-width: 1100px;
  padding-top: 100px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 500px) {
  .ir-ws-footer-main-inner-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 765px) {
  .ir-ws-footer-main-inner-container {
    // display: block;
    display: flex;
    flex-direction: column;

    .ir-ws-footer-division-main-container {
      text-align: center;
      margin-bottom: 50px;
    }

    .ir-footer-man-title-container {
      text-align: center;

      p {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
