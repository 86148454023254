.ir-ws-webinar-link-modal-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 50px;
}
.ir-ws-webinar-link-modal-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.ir-ws-gmail-icon {
  width: 55px;
  height: 55px;
}
