.image-edit-chat-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.image-edit-chat-modal--open {
  display: flex;
}

.image-edit-chat-modal__content {
  background-color: white;
  // padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 50%;
  // max-width: 1400px;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  // display: flex;
  flex-direction: column;
  align-items: center;
  label {
    margin: 0px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
  .FIE_topbar-zoom-out-btn {
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }

  .FIE_topbar-zoom-in-btn {
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }
  // .sc-qvjmv1-0 {
  //   display: none;
  // }
  .SfxSelect-Container {
    display: none;
  }
  .SfxInput-root {
    display: none;
  }

  .FIE_text-tool-options {
    .sc-qvjmv1-1 {
      display: none;
    }
  }
  .sc-dwg14p-0 {
    .sc-dwg14p-1 {
      .iirfpj {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .sc-qvjmv1-1 {
    div:nth-child(1),
    div:nth-child(3) {
      display: none;
    }
  }
  .FIE_tools-bar-wrapper {
    padding: 20px 0;
  }
  .kBJBhJ[aria-selected="true"] {
    background: #cdbfff !important;
  }

  .kBJBhJ:hover {
    background: #cdbfff !important;
  }
  .cSszUr[aria-selected="true"] {
    background: #cdbfff !important;
  }
  .cSszUr:hover {
    background: #cdbfff !important;
  }
  .epDskT {
    background-color: #6842ef !important;
  }
  .oWOcv {
    background-color: #6842ef !important;
  }
  .sc-21g986-0 {
    // justify-content: center;
    // gap: 30px;
  }
  // .sc-m9ezm7-0 {
  //   position: fixed;
  //   bottom: 25px;
  //   right: 44%;
  // }
  .sc-dwg14p-1 {
    padding: 0px;
    // padding-bottom: 30px;
  }
  .sc-dwg14p-1 {
    ul li:nth-child(2),
    ul li:nth-child(3),
    ul li:nth-child(4),
    ul li:nth-child(5) {
      display: none;
    }

    ul {
      li {
        label {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.image-edit-chat-modal__close-btn {
  position: absolute;
  top: 1%;
  right: 22%;
  background: #fff;
  // background: #6842ef;
  color: #000;
  font-weight: 500;
  // padding: 10px 20px;
  width: 40px;
  height: 40px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  z-index: 999;
  border-radius: 50%;
}
.image-edit-chat-upload-btn {
  position: absolute;
  bottom: 28px;
  left: 14px;
  background: #6842ef;
  color: #fff;
  font-weight: 500;
  padding: 6px;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 999;
  width: 80px;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
}

// .image-edit-chat-modal__content .PinturaEditor {
//     font-size: 26px;
//     font-weight: 900;
// }

// .image-edit-chat-modal__content .PinturaEditor button,
// .image-edit-chat-modal__content .PinturaEditor input,
// .image-edit-chat-modal__content .PinturaEditor label {
//     font-size: 16px;
//     font-size: 26px;
//     font-weight: 900;
// }

// .image-edit-chat-modal__content .PinturaEditor .pintura-tool,
// .image-edit-chat-modal__content .PinturaEditor .pintura-annotate,
// .image-edit-chat-modal__content .PinturaEditor .pintura-finetune {
//     font-size: 16px;

//     font-size: 26px;
//     font-weight: 900;
// }
