.ir-crs-category-inner-container {
  background-color: #f6f3ff;
}

.ir-crs-cat-inner-section {
  display: grid;
  // grid-template-columns: repeat(6, minmax(0, 1fr));
  // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  // gap: 3rem;
  gap: 1rem;
}

.ir-crs-cat-section {
  margin-top: 50px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

// .cat-indi-inner-container img {
//   width: 75px;
//   height: 75px;
// }

// .cat-indi-inner-container p {
//   margin-bottom: 0;
//   margin-top: 20px;
//   text-align: center;
//   font-weight: bold;
//   color: #252b42;
// }

// .cat-indi-container {
//   background: #fff;
//   padding: 15px;
//   position: relative;
//   width: 175px;
//   height: 175px;
// }

// .cat-indi-inner-container {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translateX(-50%) translateY(-50%);
// }

.ir-courses-cate-context {
  background-color: #cbbdff;
}

.ir-courses-cate-context-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  p {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    // font-size: 32px;
    max-width: 1100px;
  }
}

// .ir-courses-cate-context-inner p {

// }

.flip-card {
  background-color: transparent;
  // width: 176px;
  // height: 175px;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  cursor: pointer;
  /*   perspective: 1000px; */
  &:hover {
    .arrow-1:before {
      animation: a1 0.6s linear forwards 1; /* Enable the animation on hover */
    }
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.flip-card-front {
  background: #fff;
  color: black;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.ir-crs-body-parts-icon {
  width: 75px;
  height: 75px;
}
.ir-crs-body-parts-front-text {
  margin-bottom: 0;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  color: #252b42;
}
.ir-crs-body-parts-back-text {
  margin-bottom: 0;
  //   margin-top: 20px;

  text-align: center;
  color: #ffff;
}
.flip-card-back {
  background: #6842ef;
  //   background: #6842ef;

  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  padding: 15px;
}
.arrow-1 {
  width: 40px;
  height: 22px;
  display: flex;
}
.arrow-1:before {
  content: "";
  background: currentColor;
  width: 15px;
  clip-path: polygon(
    0 10px,
    calc(100% - 6px) 10px,
    calc(100% - 15px) 0,
    100% 50%,
    calc(100% - 15px) 100%,
    calc(100% - 6px) calc(100% - 10px),
    0 calc(100% - 10px)
  );

  animation: none;
}

@keyframes a1 {
  90%,
  100% {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .ir-crs-cat-inner-section {
    gap: 3rem;
  }
  .ir-courses-cate-context-inner {
    p {
      font-size: 28px;
    }
  }
  .ir-crs-cat-inner-section {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .flip-card {
    width: 176px;
    height: 175px;
  }
}

@media (min-width: 991px) {
  .ir-crs-cat-inner-section {
    gap: 3rem;
  }
  .ir-courses-cate-context-inner {
    flex-direction: row;
    align-items: center;
    p {
      text-align: start;
      // font-size: 30px;
    }
  }
  .ir-pop-static-content-container .ir-pop-static-inner-content-container {
    flex-direction: row;
  }

  .ir-ws-course-unlock-text {
    margin-bottom: 0px;
  }
  .ir-ws-course-list-browsw-btn {
    margin-top: 0px;
  }
}
@media (min-width: 1200px) {
  .ir-courses-cate-context-inner {
    p {
      font-size: 35px;
    }
  }
  // .ir-pop-static-content-container .ir-pop-static-inner-content-container {
  //   flex-direction: row;
  // }
}
