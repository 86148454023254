.ir-ws-webinar-page-title {
  font-size: 42px;
}

.ir-ws-webinar-page-title-pad-top {
  padding-top: 100px;
}

.ir-ws-webinar-page-scnd-title {
  font-size: 32px;
}

.ir-ws-webinar-page-banner-content-para {
  font-size: 18px;
  max-width: 750px;
  font-weight: 400;
  line-height: 28px;
  margin: 30px auto 0;
}

.ir-ws-webinar-page-banner-content-inner-container {
  max-width: 1360px;
  margin: 0 auto;

  .ir-ws-webinar-page-join-now-btn {
    margin-bottom: 40px;

    .ir-ws-webinar-page-banner-btn {
      width: 200px;
      font-size: 16px;
    }
  }
}

.ir-ws-webinar-banner-last-conent-container {
  padding-bottom: 0;

  // .ir-ws-webinar-page-banner-content-para {
  //     margin-bottom: 0;
  // }
}

.ir-ws-webinar-page-banner-inner-container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ffffff 20%,
    rgba(230, 253, 252, 0.634) 30%,
    rgba(230, 253, 252, 1) 65%,
    #ffffff 65%,
    #ffffff 100%
  );
}

.ir-ws-webinar-banner-video-tile-one-container img {
  max-width: 100%;
}

@media (max-width: 768px) {
  .ir-ws-webinar-page-title {
    font-size: 28px;
  }

  .ir-ws-webinar-page-scnd-title {
    font-size: 22px;
  }

  .ir-ws-webinar-page-banner-content-para {
    font-size: 14px;
    margin: 20px auto 25px;
  }

  .ir-ws-webinar-banner-last-conent-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .ir-ws-webinar-page-webinar-list-main-container
    .ir-ws-hme-webinar-sessions-container
    .ir-ws-hme-prvw-card-container {
    padding-right: 0;
  }
}
