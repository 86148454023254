.ir-ws-about-main-container{
    padding-bottom: 100px;
    padding-top: 150px;

    .ir-ws-about-heading-container {
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 30px;
        font-size: 30px;
        font-weight: bold;

        &::after {
            position: absolute;
            content: "";
            width: 100px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 7px;
            background-color: #6842ef;
        }
    }

    .ir-ws-about-content p {
        letter-spacing: 0.2px;
        line-height: 28px;
    }

    .ir-ws-about-key-features {
        font-size: 22px;
        font-weight: 600;
        margin-top: 40px;
        margin-bottom: 0;
    }

    ul {
        margin-top: 40px;
        padding-left: 15px;

        li {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}