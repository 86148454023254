.ir-ws-webinar-admin-custom-tab {
  display: flex;
  margin: 0;
  gap: 8px;
  align-content: center;
  border-bottom: 1px solid #e5e7ea;
}

.ir-ws-custom-tab-button {
  margin-top: 10px;
  padding: 10px 40px;
  background: rgba(220, 223, 227, 0.75);
  border-radius: 20px 20px 0px 0px;
  margin-bottom: 0px;
  cursor: pointer;
  // font-family: Poppins-SemiBold;
  color: #6842ef;
  font-weight: bold;
}

.ir-ws-custom-tab-active {
  background: #ffff;
  font-weight: 500;
  color: #252b42;
}
