.ir-ws-preview-modal-player-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .ir-ws-preview-modal-box {
    position: relative;
    .ir-ws-preview-modal-player {
      // width: 840px;
      // height: 520px;
      width: 340px;
      height: 220px;
      background-color: #000;
      border-radius: 10px;
      outline: none;
    }
    .ir-ws-preview-modal-player-close-btn {
      position: absolute;
      cursor: pointer;
      // width: 40px;
      // height: 40px;
      width: 25px;
      height: 25px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      top: -35px;
      right: -10px;
      // right: -40px;
      z-index: 100;
      transition: all 0.3s;
      &:hover {
        opacity: 50%;
        // color: #fff;
        // background: rgba(28, 28, 28, 0.19);
        // background: #fff;
      }
      span {
        color: #000;
        // font-size: 1.3rem;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.modal-open {
  overflow: hidden;
}

@media (min-width: 768px) {
  .ir-ws-preview-modal-player-container {
    .ir-ws-preview-modal-box {
      .ir-ws-preview-modal-player {
        width: 480px;
        height: 320px;
      }
      .ir-ws-preview-modal-player-close-btn {
        width: 40px;
        height: 40px;
        right: -40px;
        span {
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: 991px) {
  .ir-ws-preview-modal-player-container {
    .ir-ws-preview-modal-box {
      .ir-ws-preview-modal-player {
        width: 720px;
        height: 480px;
      }
      .ir-ws-preview-modal-player-close-btn {
        width: 40px;
        height: 40px;
        right: -40px;
        span {
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: 991px) {
  .ir-ws-course-video-player-size {
    width: 720px;
    height: 480px;
  }
}
