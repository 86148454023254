::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // background: rgb(43, 167, 255);
  // background: linear-gradient(
  //   360deg,
  //   rgba(43, 167, 255, 0.5) 10%,
  //   rgba(75, 113, 247, 0.5) 50%,
  //   rgba(96, 79, 241, 0.5) 80%,
  //   rgba(104, 66, 239, 0.5) 100%
  // );
  background: #6842ef;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6842ef;
}

::-webkit-scrollbar:horizontal {
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #6842ef;
  border-radius: 10px;
}

body {
  font-family: "Montserrat", sans-serif !important;
}

//width

.ir-ws-width-100 {
  width: 100%;
}

.ir-ws-width-50 {
  width: 100%;
}

.ir-ws-width-60,
.ir-ws-width-40 {
  width: 100%;
}

.ir-ws-width-20 {
  width: 20%;
}

.ir-ws-width-25 {
  width: 25%;
}

.ir-ws-width-70 {
  width: 100%;
}

.ir-ws-width-30 {
  width: 100%;
}

.ir-ws-max-width-100 {
  max-width: 100%;
}

//height

.ir-ws-height-100-percent {
  height: 100%;
}

.ir-ws-height-100-vh {
  height: 100vh;
}

//margin

.ir-margin-0 {
  margin: 0;
}

.ir-ws-margin-o-auto {
  margin: 0 auto;
}

//padding

.ir-ws-padding-0 {
  padding: 0;
}

.ir-ws-padding-lr-132 {
  padding-left: 30px;
  padding-right: 30px;
}

.ir-ws-padding-tb-100 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ir-ws-padding-tb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ir-ws-padding-tb-125 {
  padding-top: 125px;
  padding-bottom: 125px;
}

//display

.ir-ws-flex {
  display: flex;
}

.ir-ws-block {
  display: block;
}

.ir-ws-inline-block {
  display: inline-block;
}

.ir-ws-none {
  display: none;
}

//alignment

.ir-ws-justify-center {
  justify-content: center;
}

.ir-ws-justify-left {
  justify-content: left;
}

.ir-ws-justify-space-btw {
  justify-content: space-between;
}

.ir-ws-align-center {
  align-items: center;
}

//list style

.ir-ws-list-style-none {
  list-style: none;
}

//border styles

.ir-ws-no-border {
  border: none;
}

.ir-ws-border-50 {
  border-radius: 50%;
}

//bg style

.ir-ws-no-bg {
  background-color: transparent;
}

.ir-ws-app-bg {
  background-color: #6842ef;
}

.ir-ws-gray-bg {
  background-color: #f6f7fb;
}

.ir-bg-white {
  background-color: #fff;
}

//color

.ir-ws-app-color {
  color: #6842ef;
}

.ir-color-white {
  color: #fff;
}

.ir-default-color {
  color: #737373;
}

.ir-ws-heading-default-color {
  color: #252b42;
}

//text style

.ir-txt-decrtn-none {
  text-decoration: none;
}

.ir-ws-text-center {
  text-align: center;
}

.ir-ws-text-uppercase {
  text-transform: uppercase;
}
.ir-ws-text-capitalize {
  text-transform: capitalize;
}

.ir-ws-font-700 {
  font-weight: 700;
}

.ir-ws-font-size-xxl {
  font-size: 28px;
}

.ir-ws-font-size-xl {
  font-size: 25px;
}

.ir-ws-font-size-l {
  font-size: 20px;
}

.ir-ws-font-size-m {
  font-size: 18px;
}

.ir-ws-font-size-s {
  font-size: 16px;
}

.ir-ws-font-size-xs {
  font-size: 12px;
}

.ir-ws-font-size-xxs {
  font-size: 10px;
}

.ir-ws-line-height-xxl {
  line-height: 40px;
}

//postions

.ir-ws-position-fixed {
  position: fixed;
}

.ir-ws-position-relative {
  position: relative;
}

.ir-ws-position-absolute {
  position: absolute;
}

//button styles

button {
  &:focus {
    outline: none;
  }
}

// banner styles

.ir-ws-carousel-item-img {
  z-index: 1;
  right: 50px;
  max-width: 100%;
  bottom: -24px;
}

.ir-ws-hme-banner-carousel-bg-img {
  right: 35px;
  bottom: 10px;
  transform: scale(1.1);
}

.ir-ws-banner-para-font {
  font-size: 14px;
}

.ir-ws-banner-padding-tb {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ir-ws-banner-img-container {
  display: none;
}

.ir-ws-banner-content-container {
  text-align: center;
}

//PreviewTab styles

.ir-ws-hme-prvw-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

//card styles

.ir-ws-card-main-container {
  border-radius: 5px;
  padding: 20px 20px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  height: 100%;
}

//dots

.ir-ws-prvw-signup-dot-small {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  background-color: #2257bf;
}

.ir-ws-prvw-signup-dot-large {
  background-color: #00b2ff;
  width: 50px;
  height: 12px;
}

.ir-ws-prvw-signup-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px; /* 208.333% */
  letter-spacing: 0.2px;
  margin: 0;
}

//button styles
.ir-ws-default-btn-container {
  margin-top: 16px;

  .ir-ws-default-btn {
    width: 300px;
    border-radius: 5px;
    padding: 15px 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.2px;
    position: relative;
    background-size: 400%;
    height: 52px;
    color: #fff;
    border: none;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    @media (max-width: 768px) {
      line-height: 18px;
      padding: 5px;
      font-weight: 500;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: inherit;
      border-radius: inherit;
      background: rgb(43, 167, 255);
      background: linear-gradient(
        90deg,
        rgba(43, 167, 255, 1) 0%,
        rgba(104, 66, 239, 1) 100%
      );
      transition: all 0.8s;
    }

    &:hover::before {
      transform: scaleX(1);
    }

    &:hover {
      text-decoration: none;
    }

    &.disabled,
    &:disabled {
      background: #b3a1f5;
      cursor: not-allowed;

      &:before {
        background: #b3a1f5;
        // transition: none;
        // transform: none;
      }

      span {
        color: #fff;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}

/***** media queries ******/

@media (min-width: 768px) {
  .ir-ws-width-50 {
    width: 50%;
  }

  .ir-ws-width-60 {
    width: 60%;
  }

  .ir-ws-width-40 {
    width: 40%;
  }

  .ir-ws-width-20 {
    width: 20%;
  }

  .ir-ws-width-70 {
    width: 70%;
  }

  .ir-ws-width-25 {
    width: 25%;
  }

  .ir-ws-width-30 {
    width: 30%;
  }

  .ir-ws-padding-tb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ir-ws-padding-lr-132 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .ir-ws-font-size-xxl {
    font-size: 32px;
  }

  .ir-ws-font-size-xl {
    font-size: 28px;
  }

  .ir-ws-font-size-l {
    font-size: 25px;
  }

  .ir-ws-font-size-m {
    font-size: 22px;
  }

  .ir-ws-font-size-s {
    font-size: 20px;
  }

  .ir-ws-line-height-xxl {
    line-height: 45px;
  }

  //banner styles

  .ir-ws-banner-content-container {
    text-align: left;
  }

  .ir-ws-banner-padding-tb {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .ir-ws-banner-height {
    height: 500px;
  }

  .ir-ws-hme-banner-carousel-bg-img {
    right: 0;
    bottom: -24px;
    transform: none;
    width: 120%;
  }

  .ir-ws-banner-img-container {
    display: block;
  }
}

@media (min-width: 991px) {
  .ir-ws-width-33 {
    width: 33%;
  }
}

/***** media queries 1024px ******/

@media (min-width: 1024px) {
  // .ir-ws-padding-lr-132 {
  // 	padding-left: 75px;
  // 	padding-right: 75px;
  // }

  // .ir-ws-width-33 {
  // 	width: 33%;
  // }

  .ir-ws-padding-tb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ir-ws-font-size-xxl {
    font-size: 45px;
  }

  .ir-ws-font-size-xl {
    font-size: 40px;
  }

  .ir-ws-font-size-l {
    font-size: 32px;
  }

  .ir-ws-font-size-m {
    font-size: 24px;
  }

  .ir-ws-font-size-s {
    font-size: 20px;
  }

  .ir-ws-line-height-xxl {
    line-height: 60px;
  }

  //banner styles
  .ir-ws-banner-padding-tb {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ir-ws-carousel-item-img {
    z-index: 1;
    right: 50px;
    max-width: 100%;
    bottom: -24px;
  }

  .ir-ws-hme-banner-carousel-bg-img {
    right: 0;
    bottom: -24px;
    width: 110%;
  }

  .ir-ws-banner-height {
    height: 650px;
  }

  .ir-ws-banner-para-font {
    font-size: 18px;
  }

  //Preview Tab Styles

  .ir-ws-hme-prvw-title {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 40px;
  }

  .ir-ws-hme-prvw-card-container {
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .ir-ws-banner-padding-tb {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .ir-ws-carousel-item-img {
    z-index: 1;
    right: 50px;
    max-width: 100%;
    bottom: -24px;
  }

  .ir-ws-hme-banner-carousel-bg-img {
    right: 0;
    bottom: 0;
    transform: scale(1);
    width: auto;
  }

  .ir-ws-font-size-xxl {
    font-size: 47px;
  }

  .ir-ws-font-size-xl {
    font-size: 42px;
  }

  .ir-ws-line-height-xxl {
    line-height: 70px;
  }

  .ir-ws-banner-para-font {
    font-size: 20px;
  }

  //PreviewTab styles

  .ir-ws-hme-prvw-title {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 50px;
  }

  //card styles
  .ir-ws-card-main-container {
    padding: 25px 40px;
  }
}
/***** media queries 1400px ******/

@media (min-width: 1400px) {
  .ir-ws-padding-lr-132 {
    padding-left: 132px;
    padding-right: 132px;
  }

  .ir-ws-font-size-xxl {
    font-size: 58px;
  }

  .ir-ws-line-height-xxl {
    line-height: 80px;
  }

  //bannerstyles

  .ir-ws-banner-padding-tb {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .ir-ws-banner-height {
    height: 720px;
  }

  .ir-ws-carousel-item-img {
    right: 50px;
    bottom: -24px;
  }

  .ir-ws-hme-banner-carousel-bg-img {
    right: 35px;
    bottom: 10px;
    transform: scale(1.1);
    width: auto;
  }
}

// chatroom related styles

@import url("./assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css");
@font-face {
  font-family: Poppins-Regular;
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("./assets/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("./assets/fonts/poppins/Poppins-SemiBold.ttf");
}

.bg-app-default-color {
  background-color: #6842ef;
  &:hover {
    background-color: #6842ef;
  }
  &.btn {
    background-color: #6842ef;
    border: 1px solid #6842ef;

    &:hover {
      background-color: #6842ef;
      border: 1px solid #6842ef;
    }
  }
}

.default-color {
  color: #6842ef;
}

.ps {
  // overflow: hidden!important;
  overflow-anchor: none;
  --ms-overflow-style: none;
  touch-action: auto;
  --ms-touch-action: auto;
}

.gap-4 {
  gap: 1.5rem !important;
}

.flex-column {
  --webkit-box-orient: vertical !important;
  --ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-column,
.flex-row {
  --webkit-box-direction: normal !important;
}

.list-group {
  display: var(--webkit-box);
  display: var(--ms-flexbox);
  display: flex;
  --webkit-box-orient: vertical;
  --webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.position-relative {
  position: relative;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.iks-mint-cust-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  width: 100%;
  height: 100%;

  .iks-mint-modal-container {
    width: auto;
    min-height: calc(100% - 3.5rem);
    max-width: 500px;
    margin: 1.75rem auto;
    display: flex;
    position: relative;
    align-items: center;

    &.iks-mint-width-inc-container {
      width: 90%;
      max-width: 1200px;
      margin: auto;

      .iks-mint-modal-content {
        height: 80vh;
      }
    }

    .iks-mint-modal-content {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: none;
      border-radius: 0.5rem;
      outline: 0;
      box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.15);

      .ir-form-title {
        padding: 1rem 1.5rem;
        align-items: center;
        background: rgb(27, 54, 100);
        background: linear-gradient(
          90deg,
          rgba(71, 189, 148, 1) 0%,
          rgba(27, 54, 100, 1) 100%
        );
        color: #fff;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        h2 {
          margin-bottom: 0;
          line-height: 1.5;
          font-size: 20px;
        }

        button {
          padding: 0.5rem;
          margin: -0.5rem -0.5rem -0.5rem auto;
          background-color: transparent;
          box-sizing: content-box;
          width: 1em;
          height: 1em;
          padding: 0.25em;
          border: 0;
          border-radius: 0.5rem;
          opacity: 0.5;
          color: #fff;
          opacity: 0.5;
          font-size: 22px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .ir-modal-btn-container {
      padding: 1rem 1.5rem;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      button {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 0.9em;
        font-weight: 500;
        font-family: Poppins-Medium;
        font-weight: bold;
        width: 300px;
        margin: 10px auto 0;
        background-color: #6842ef;
        padding: 15px;
        color: #fff;

        &:hover {
          background-color: #6842ef;
        }

        &.btn-cancel-btn {
          margin-left: 20px;
          // background-color: #212529;
        }
      }
    }
  }

  &.ir-chat-inside-modal-container {
    .iks-mint-modal-container {
      width: 90%;
      margin: auto;
      max-width: 1200px;

      .iks-mint-modal-content {
        height: 90vh;
        background: rgba(255, 255, 255, 0.8);

        .ir-share-modal-inside-content {
          height: calc(100% - 65px);
          position: relative;
        }

        .ir-modal-btn-container.record-btn-container {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.full-width-btn {
  width: 100%;
}

.swal2-container {
  .swal2-popup {
    padding-bottom: 25px;
  }
  .swal2-title {
    padding-top: 0.5em;
  }
  .swal2-styled.swal2-confirm {
    background-color: #6842ef;
    width: 100px;
    font-size: 1.25em;
    padding: 10px 0;
    font-weight: bold;
    border-radius: 10px;
  }
}

@keyframes modalBottomToTop {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}

.ir-drop-downmenu-trigger-container {
  position: relative;

  // &:hover {
  //   .ir-dropdown-container {
  //     display: block;
  //   }
  // }

  .ir-dropdown-container {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 25px;
    right: 0;
    width: auto;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: block;
    // visibility: hidden;
    // transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 5px 15px;
        font-size: 12px;
        font-family: Poppins-Medium;
        color: #212529;

        &:hover {
          background-color: #eee;
        }

        &.dropdown-divider {
          height: 0;
          margin: 0.5em 0;
          overflow: hidden;
          border-top: 1px solid #e9ecef;
        }

        &:empty {
          padding: 0;
        }
      }
    }
  }
}

.ir-share-modal-inside-content {
  padding: 20px;
  text-align: center;

  p {
    font-size: 14px;
  }

  .ir-modal-btn-container {
    justify-content: center;
    margin-top: 20px;
    button {
      background-color: #2a2a2a;
      color: #fff;
    }

    &.record-btn-container {
      button {
        width: 200px;
        padding: 10px 15px;
      }
    }
  }
}

p.ir-file-exceeded {
  position: absolute;
  background: #d73a3a;
  width: 100%;
  top: 90px;
  color: #fff;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  padding: 5px;
}

#circularcursor {
  background-color: transparent;
  border: 1px solid black;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: absolute;
  z-index: 1;
}

.canvas-btn {
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border-style: solid;
  padding: 5px 5px;
  border-color: gray;
  border-width: thin;
}

.btn-width {
  min-width: 50px;
}

.mouse-state {
  cursor: none;
}

.__dbk__gutter.Dark {
  background: #a0a2a5 !important;
  padding: 0 1px !important;
}

.ir-btn-default {
  border-radius: 5px;
  padding: 10px 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 157.143% */
  letter-spacing: 0.2px;
  position: relative;
  background-size: 400%;
  height: 40px;
  background-size: 400%;
  color: #fff;
  border: 0;
  display: inline-block;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background: rgb(43, 167, 255);
    background: linear-gradient(
      90deg,
      rgba(43, 167, 255, 1) 0%,
      rgba(104, 66, 239, 1) 100%
    );
    transition: all 0.8s;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  &:hover {
    text-decoration: none;
  }

  span {
    position: relative;
    z-index: 1;
  }
}

// modal styles for chatroom

.ir-ws-sign-popup-container {
  width: 100vw;
  height: 100vh;
  // background-color: rgba($color: #0d0e11, $alpha: 0.9);
  background: rgba(28, 28, 28, 0.19);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  transition: opacity 0.3s ease-out;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.imageEditModalMainContainer {
    .ir-ws-sign-popup-inner-container {
      max-width: 100%;
      height: 85vh;

      .ir-ws-signup-content-container {
        height: 100%;

        //   .FIE_root {
        //     position: relative;
        //     height: calc(100% - 20px) !important;
        //   }
      }
    }
  }

  .ir-ws-sign-popup-inner-container {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 50%;
    margin: 0 auto;
    max-width: 600px;
    padding: 30px 0px;
    border-radius: 12px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
      0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
      0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
      0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);

    @media (max-width: 768px) {
      // width: 80%;
      width: 95%;
      margin: 0 auto;
      // padding: 20px 30px;
      padding: 20px 15px;
      border-radius: 8px;
    }

    &.ir-ws-chat-upload-img-modal-container {
      max-width: 1400px;
      // height: 85vh;

      .iks-mint-modal-content {
        height: 100%;

        .ir-ws-signup-content-inner-container {
          height: calc(100% - 50px);

          .ir-share-container {
            height: calc(100% - 20px);
          }
        }
      }
    }

    .ir-ws-signup-content-title {
      font-size: 30px;
      line-height: 50px;
      letter-spacing: 0.2px;
      font-weight: 700;
      margin-bottom: 0;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.2px;
        font-weight: 600;
      }
    }

    .ir-ws-signup-form-container {
      margin-top: 60px;

      .ir-ws-signup-content-group {
        position: relative;
        margin: 45px 0;

        .ir-ws-signup-input-field {
          background: none;
          color: #0b2f8a;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 1.6px;
          padding: 10px 10px 10px 5px;
          display: block;
          width: 100%;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #c6c6c6;

          &:focus {
            outline: none;
          }
        }

        .ir-ws-signup-input-field:focus ~ .ir-ws-signup-label,
        .ir-ws-signup-input-field:valid ~ .ir-ws-signup-label {
          top: -14px;
          font-size: 12px;
          color: #6842ef;
        }
        .ir-ws-signup-input-field:focus ~ .ir-ws-signup-bar:before {
          width: 100%;
        }

        .ir-ws-signup-input-field[type="password"] {
          letter-spacing: 0.3em;
        }

        .ir-ws-signup-label {
          color: rgba(21, 24, 109, 0.6);
          font-size: 16px;
          font-weight: normal;
          position: absolute;
          pointer-events: none;
          left: 5px;
          top: 10px;
          transition: 300ms ease all;
          font-size: 16px;
        }

        .ir-ws-signup-bar {
          position: relative;
          display: block;
          width: 100%;

          &:before {
            content: "";
            height: 2px;
            width: 0;
            bottom: 0px;
            position: absolute;
            background: #6842ef;
            transition: 300ms ease all;
            left: 0%;
          }
        }
      }

      .ir-ws-sign-submit-btn {
        border-radius: 5px;
        padding: 15px 25px;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.2px;
      }
    }

    .ir-ws-login-social-container {
      margin-top: 20px;

      .ir-social-logo-container {
        margin-left: 35px;
        &:first-child {
          margin-left: 0;
        }
        img {
          width: 35px;
          height: 35px;
        }
      }
    }

    .ir-ws-signup-close-container {
      top: -40px;
      right: -30px;
      background-color: #fff;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
        0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
        0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
        0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
      transition: opacity 0.1s ease;
      &:hover {
        opacity: 0.75;
      }

      i {
        font-size: 20px;
        line-height: normal;
      }
    }

    // .FIE_topbar-center-options,
    // .FIE_image-tool-button,
    // .FIE_rect-tool-button,
    // .FIE_ellipse-tool-button,
    // .FIE_polygon-tool-button,
    // .FIE_topbar-reset-button {
    //   display: none;
    // }

    // .FIE_tools-bar {
    //   margin: 0 auto;
    //   width: fit-content;
    //   position: absolute;
    //   top: -40px;
    //   left: 50%;
    //   transform: translateX(-50%);

    //   .Qjcba[aria-selected="true"],
    //   .Qjcba:hover {
    //     background-color: rgba($color: #6842ef, $alpha: 0.2);
    //   }
    // }

    // .FIE_pen-tool-options,
    // .FIE_arrow-tool-options,
    // .FIE_line-tool-options {
    //   .FIE_annotation-option-triggerer[title="Stroke"] {
    //     display: block;
    //     position: relative;

    //     //    &:before {
    //     //         position: absolute;
    //     //         content: 'Stroke';
    //     //         color: #6842ef;
    //     //         width: 300px;
    //     //         font-size: 12px;
    //     //         left: 50%;
    //     //         font-family: Poppins-Medium;
    //     //         top: 50%;
    //     //         transform: translateY(-50%);
    //     //     }
    //   }
    // }

    // .FIE_text-tool-options {
    //   .FIE_annotation-option-triggerer[title="Opacity"],
    //   .FIE_annotation-option-triggerer[title="Stroke"],
    //   .FIE_annotation-option-triggerer[title="Shadow"],
    //   .FIE_annotation-option-triggerer[title="Position"] {
    //     display: none;
    //   }
    // }

    // .FIE_tools-bar-wrapper {
    //   padding: 0;

    //   .FIE_tool-options-wrapper {
    //     position: fixed;
    //     left: 53%;
    //     bottom: 15px;
    //     transform: translateX(-50%);

    //     .FIE_annotations-options {
    //       margin-top: 0;
    //     }
    //   }
    // }

    // .SfxLabel-root {
    //   margin-bottom: 0;
    // }

    // .FIE_topbar-history-buttons {
    //   button {
    //     width: 25px;
    //     height: 25px;
    //     svg {
    //       width: 20px;
    //       height: 20px;
    //     }
    //   }
    // }

    // .FIE_topbar-close-button {
    //   font-size: 0 !important;
    //   font-family: Poppins-Bold;
    //   padding: 0;
    //   position: relative;
    //   width: 100px;

    //   svg {
    //     visibility: hidden;
    //   }

    //   &:before {
    //     position: absolute;
    //     content: "Cancel";
    //     color: #768184;
    //     width: 100px;
    //     font-size: 14px;
    //     left: -10px;
    //     font-family: Poppins-Medium;
    //   }

    //   &:hover {
    //     &:before {
    //       color: #6842ef;
    //     }
    //   }
    // }
  }

  .ir-ws-signup-form-footer-container {
    border-top: 1px solid #aaa;
    margin-top: 30px;
    padding: 0.5rem 0;
    position: relative;
    text-align: center;
    width: 100%;

    &:after {
      background-color: #fff;
      content: "or connect with";
      font-size: 16px;
      left: 50%;
      padding: 0 8px;
      position: absolute;
      top: -13px;
      font-weight: 500;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      color: rgba(21, 24, 109, 0.6);
    }
  }

  // .eHHioP:first-child span {
  //   position: relative;
  //   width: 85px;
  //   font-size: 0;
  // }

  // .eHHioP:first-child span:after {
  //   position: absolute;
  //   content: "Annotate";
  //   left: 50%;
  //   top: 0;
  //   transform: translateX(-50%);
  //   z-index: 999;
  //   font-size: 11px;
  // }

  // .hWYIkd {
  //   padding: 0;
  // }

  // .FIE_main-container {
  //   position: relative;
  // }

  // .FIE_editor-content {
  //   height: calc(100% - 50px);
  // }

  // .hiSfoc.FIE_canvas-container {
  //   min-height: 100px;
  //   background: red;
  //   height: 100%;
  // }

  // .FIE_canvas-node {
  //   height: 100%;
  // }

  // .konvajs-content {
  //   height: 100% !important;
  // }

  // .konvajs-content canvas {
  //   height: 100% !important;
  // }
}

.signup-popup {
  display: none;
  &.show {
    display: flex;
  }
}

.ir-ws-file-field {
  position: relative;
  margin-top: 20px;

  .ir-ws-create-case-file-btn-container {
    position: relative;
    text-align: center;
    .btn {
      width: 125px;
      height: 35px;
      margin: 0.375rem 0 0 0;
      text-transform: uppercase;
      word-wrap: break-word;
      white-space: normal;
      border: 0;
      border-radius: 0.125rem;
      padding: 0.5rem 0.7rem;
      font-size: 0.74rem;
      cursor: pointer;
      color: #fff;
      -webkit-transition: 0.5s ease;
      transition: 0.5s ease;
      background: #6842ef;
    }
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 125px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
    transform: translateX(-50%);

    // &.input-file-exist {
    //     left: initial;
    // }
  }

  .ir-ws-file-path-wrapper {
    overflow: hidden;
    width: 100%;
    border-bottom: 2px solid #d7d7d7;
    margin-right: 0.375rem;
    margin-bottom: 10px;

    &:empty {
      display: none;
    }

    .file-field-wrapper {
      margin-right: 8px;
      background-color: rgba($color: #6842ef, $alpha: 0.1);
      padding: 4px 8px;
      display: inline-flex;
      width: 140px;
      margin-bottom: 5px;
      align-items: center;
    }

    .file-field-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 120px;
      display: inline-block;
      overflow: hidden;
    }

    span.remove-file-item {
      font-size: 11px;
      font-family: Poppins-Bold;
      padding-top: 5px;
      padding-left: 15px;
      opacity: 0.5;
      cursor: pointer;
    }
  }

  input.file-path {
    box-sizing: content-box;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #ced4da;
    border-radius: 0;
    outline: 0;
    width: 100%;
    height: 36px;
    font-size: 16px;
    color: #555555;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 80px;
      padding: 4px 8px;
      background-color: rgba($color: #6842ef, $alpha: 0.5);
    }
  }
}

.ir-ws-webinar-image-crop-container {
  .reactEasyCrop_Contain {
    width: 95%;
  }
}

.ir-ws-no-bg.ir-ws-app-color.ir-ws-book-now-text {
  padding: 12px 0;
  font-weight: bold;
}

.debug-border {
  border: 2px solid red;
}

.ir-ws-chat-flex-toggle {
  display: flex !important;
}

.ir-ws-chat-block-toggle {
  display: block !important;
}
.ir-ws-chat-none-toggle {
  display: none !important;
}

.ir-chat-none-flex-toggle{
  display: none !important;
}
@media (max-width: 768px) {
  .ir-ws-chat-display-flex {
    display: flex !important;
  }

  .ir-ws-chat-display-none {
    display: none !important;
  }
  .ir-ws-chat-display-block {
    display: block !important;
  }
  .ir-ws-chat-flex-toggle {
    display: none !important;
  }
  .ir-ws-chat-block-toggle {
    display: none !important;
  }
  .ir-ws-chat-none-toggle {
    display: block !important;
  }
  .ir-chat-none-flex-toggle{
    display: flex !important;
  }
  .ir-ws-sign-popup-container .ir-ws-sign-popup-inner-container .ir-ws-signup-form-container {
    margin-top: 30px;
  }
  .ir-ws-sign-popup-container .ir-ws-sign-popup-inner-container .ir-ws-signup-form-container .ir-ws-signup-content-group {
    margin: 20px 0;
  }
  .ir-ws-file-field {
    margin-top: 10px;
  }
}
