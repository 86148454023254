.ir-cases-banner-main-container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 18%,
    rgba(226, 255, 197, 1) 100%
  );
  .ir-cases-banner-inner-container {
    display: block;
    padding-top: 75px;
    @media (min-width: 1300px) {
      padding-top: 125px;
    }
      
 
    .top-sub-heading{
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 30px;
        .live-btn {
            background-color: #6C4EFC; 
            color: white; 
            padding: 3px 20px; 
            border-radius: 20px; 
            font-weight: bold; 
            font-size: 16px; 
            display: inline-flex;
            align-items: center; 
            justify-content: center; 
            
            &::before {
              content: "";
              width: 16px;
              height: 16px;
              background-color: white; 
              border-radius: 50%;
              margin-right: 15px; 
              margin-left: -15px;
            }
          }
          
    }
    .improve-sub-heading{
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 10px;
        
    }
    .ire-welcom-title{
        font-weight: 600;
        font-size: 28px;
        // padding-bottom: 0;
        margin-bottom: 10px;
    }

    .join-btn{
       margin-top: 20px;
      //  background: #6842EF;
       color: #FFF;
      //  width: 207px;
      //  height: 58px;
       border: none;
      //  border-radius: 8px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;

    }
    
    .join-btn-container{
      @media (max-width: 576px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ir-ws-cases-banner-title {
      // margin-bottom: 0;
      max-width: 900px;

      text-align: center;
      background: #d0ffa0;
      width: fit-content;
      padding: 2px 10px;
      // margin-top: -10px;
      margin-bottom: 30px;
    }

    .ir-cases-banner-title {
      
      // font-size: 18px;
      font-size: 14px;
      margin-top: 10px;
      padding-top: 0;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 40px;
      @media (min-width:1200px) {
        max-width: 800px;
        text-align: center;
      }
    }
  }
}


.ir-cases-banner-img-container {
  display: none;
}

@media (min-width: 768px) {
  .ir-cases-banner-main-container {
    .ir-cases-banner-inner-container {
      display: flex;
      .ir-cases-banner-content-container {
        // border: 2px solid rgb(0, 255, 26);
        width: 60%;
        .ir-ws-cases-banner-title {
          text-align: start;
        }
        .ir-cases-banner-title {
          text-align: start;
          font-size: 18px;
        }
      }
    }

    .ir-cases-banner-img-container {
      display: block;
      width: 40%;
      img{
        // width: 520px;
      }
      // border: 2px solid red;
    }
  }
}
