.ir-cs-inner-list-container {
  background: #ffffff;
}
.active-case-heading {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 30px;
}

.price-continer {
  display: flex;
  gap: 10px;
  .price-tag-icon {
    height: 25px;
    width: 25px;
  }
  .ir-case-pice-tag {
    color: #6842ef;
    font-weight: 700;
    font-size: 20px;
  }
}

.ir-cs-list-grid-ind-container {
  .ir-cs-list-grid-ind-inner {
    // position: relative;
    // border: 2px solid red;
    .ir-ws-case-list-main-box {
      flex-direction: column;

      .ir-cs-list-grid-img-container {
        position: relative;
        // border: 2px solid rgb(123, 255, 0);
        // height: 40%;
        height: 225px;
        @media (max-width: 1280px) {
          height: 160px;
        }
        .catogey-img-container {
          display: flex;
          position: absolute;
          bottom: -30px;
          margin-left: 30px;
          z-index: 10;
          // border-radius: 50%;
          .cat-main {
            background-color: #6842ef;
            padding: 12px;
            // margin-top: 2px;
            border: 3px solid #fff;
            border-radius: 50%;
            @media (max-width: 1280px) {
              padding: 8px;
              border: 2px solid #fff;
            }
            img {
              width: 45px;
              height: 45px;
              @media (max-width: 1280px) {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }

      .ir-cs-list-grid-content-container {
        // border: 2px solid red;
        // height: 60%;

        // height: 240px;
        padding: 20px;
        @media (max-width: 1280px) {
          height: 240px;
        }
        .ir-cs-list-grid-content {
          // border: 2px solid red;
          margin-top: 20px;
        }
      }
    }
  }
}
.price-buy-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 120px;

  position: absolute;
  bottom: 10px;
  .ir-case-pice-tag {
    // margin-left: 40px;
  }
}
.ir-cs-case-list-grid-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 1px solid #81818153;
  // height: 225px;
}
.ir-cs-list-grid-container {
  // max-width: 1200px;
  // width: 100%;
  // margin: 0 auto;
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // gap: 60px;

  // @media (max-width: 1024px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @media (max-width: 768px) {
  //   grid-template-columns: 1fr;
  // }
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px;

  // @media (max-width: 1280px) {
  //   gap: 40px;
  //   // max-width: 900px;
  //   // margin: 0 auto;
  // }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    // gap: 40px;
    // width: 80%;
  }

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
    width: 300px;
    margin: 0 auto;
  }
}
.ir-ws-case-list-main-box {
  display: flex;
  flex-direction: column;
}

.ir-cs-list-grid-ind-container {
  position: relative;
  width: 360px;
  height: 470px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (max-width: 1280px) {
    width: 300px;
    height: 400px;
  }
}

.ir-cs-list-grid-ind-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ir-cs-list-grid-img-container {
  // flex: 0 0 60%; // 60% height
  // position: relative;
  // overflow: hidden;
  cursor: pointer;
}

.ir-cs-list-grid-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ir-cs-list-grid-content-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // border: 2px solid red;
}

.ir-cs-list-grid-content-container {
  .cl-case-title {
    font-size: 16px;
    font-weight: bold;
    // text-transform: uppercase;
    margin-top: -30px;
    margin-left: 90px;
    color: #6842ef;
    @media (max-width: 1280px) {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: -30px;
      margin-left: 90px;
    }
  }
}

.ir-cs-list-grid-content-container {
  .cl-case-description {
    // color: #000;
    font-weight: 500;
    margin-top: 30px;
    font-style: 16px;
    @media (max-width: 1280px) {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}

.ir-cs-list-grid-price-container {
  font-size: 14px;
  margin-bottom: 1rem;
  width: 130px;
  cursor: pointer;
  // height: 50px;
  padding: 10px 0;
  background: #6842ef;
  border-radius: 10px;
  text-align: center;
  color: #f6f3ff;
  font-weight: bold;
  font-size: 18px;
}

.ir-cs-list-grid-price-read-container {
  display: flex;
  // justify-content: space-between;
  gap: 90px;
  @media (max-width: 1280px) {
    gap: 50px;
  }
}

.ir-cases-container-readmore {
  font-size: 14px;
  color: #6842ef;
  font-weight: bold;
}

.ir-cases-container-readmore:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ir-ws-course-show-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.ir-ws-course-homepage-show-more-text {
  cursor: pointer;
  color: #6842ef;
  font-weight: bold;
}

.modal-open {
  overflow: hidden;
}

.max-w-1200 {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  // border: 2px solid red;
}
