.ir-ws-home-chat-feature-inside-gradient {
    border-radius: 0px 48px 48px 48px;
    background: linear-gradient(270deg, #ffd1d1 0%, rgba(247, 169, 160, 0.00) 100%);
    padding: 4px;
}
.ir-ws-hme-cht-main-container {
    border-radius: 0px 48px 48px 48px;
    background: linear-gradient(89deg, #F6F7FB 0%, rgba(255, 239, 237, 0.9) 100%);

    .ir-ws-hme-cht-inner-container {
        padding-top: 50px;
        .ir-ws-hme-cht-content-container {

            .ir-ws-hme-cht-title {
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px; /* 125% */
                letter-spacing: 0.2px;
                padding: 35px 0;
                text-align: center;

                &::after {
                    position: absolute;
                    content: '';
                    width: 100px;
                    top: 0;
                    left: 45%;
                    height: 7px;
                    background-color: #FF681E;
                }
            }
            
            .ir-ws-hme-cht-scnd-title {
                color: #010101;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 150% */
                letter-spacing: 0.1px;
                text-align: center;
            }

            .ir-ws-hme-chat-para {
                margin: 24px 0 35px;
                text-align: center;
            }

            .ir-ws-hme-chat-btn-container {
                text-align: center;
            }

            .ir-ws-hme-chat-btn {
                // width: 320px;
                padding: 15px 40px;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px; /* 157.143% */
                letter-spacing: 0.2px;
                position: relative;
                background-size: 400%;
                height: 52px;
                background-size: 400%;
                color: #fff;
                border: none;
                background-color: #EA4C89;
                display: inline-block;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    width: 100%;
                    height: inherit;
                    border-radius: inherit;
                    background: rgb(255,158,110);
                    background: linear-gradient(83deg, rgba(255,158,110,1) 0%, rgba(234,76,137,1) 100%);
                    transition: all 0.8s;
                }

                &:hover::before {
                    transform: scaleX(1);
                }

                span {
                    position: relative;
                    z-index: 1;
                }
            }
        }

        .ir-ws-hme-cht-img-container {
            text-align: center;
            img {
                max-width: 100%;
            }
        }
    }
}

@media (min-width: 991px) {
    .ir-ws-hme-cht-main-container {
        .ir-ws-hme-cht-inner-container {
            display: flex;

            .ir-ws-hme-cht-img-container {
                width: 50%;
            }

            .ir-ws-hme-cht-content-container {
                max-width: 650px;

                .ir-ws-hme-cht-title {
                    text-align: left;

                    &::after {
                        left: 0;
                    }
                }

                .ir-ws-hme-cht-scnd-title {
                    text-align: left;
                }

                .ir-ws-hme-chat-para {
                    text-align: left;
                }

                .ir-ws-hme-chat-para {
                    margin: 24px 0 35px;
                    max-width: 600px;
                }

                .ir-ws-hme-chat-btn-container {
                    text-align: left;
                }
            }
        }
    }
}