.ir-ws-create-course-second-page-container {
  margin-top: 30px;
  display: grid;
  gap: 40px;
  color: #424242;
}

.ir-ws-second-page-section-heading {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  .heading-h {
    font-weight: bold;
    margin: 0;
    font-size: 1.4rem;
  }
  .heading-p {
    margin: 0;
    font-size: 1.4rem;
  }
}

.ir-ws-lecture-container {
  // height: 290px;
  height: max-content;
  background: #e0e0e0;
  // background: #b3b3b3;
  width: 75%;
  border-radius: 10px;
  border: 1px solid #b3b3b3;
}
.ir-ws-single-lecture-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
  padding-inline: 20px;
}
.ir-ws-lecture {
  height: 60px;
  // width: 79%;
  background: #ffffff;

  border-radius: 10px;
  border: 1px solid #b3b3b3;
  display: flex;
  align-items: center;
  gap: 20px;
  .ir-ws-lecture-paragraph {
    margin: 0;
    padding-left: 20px;
    font-size: 1.1rem;
  }
}
.ir-ws-lecture.expanded {
  // border-top: 2px solid rgb(176, 176, 176);
  // border-right: 2px solid rgb(176, 176, 176);
  // border-left: 2px solid rgb(176, 176, 176);
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ir-ws-lecture-add-button {
  height: 60px;
  width: 140px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.3s;
  color: #000;
  &:hover {
    background: #6842ef;
    color: white;
  }
}

.ir-ws-lecture-add-button-active {
  background: #6842ef;
  color: white;
}

.ir-ws-course-error-message-lecture {
  margin: 0;
  margin-left: 20px;
  padding-top: 20px;
  color: #c92a2a;
}
.ir-ws-course-section-error-message {
  margin: 0;
  margin-top: -35px;
  margin-left: 20px;
  color: #c92a2a;
}
.ir-ws-add-lacture-button {
  height: 60px;
  width: 200px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #424242;
  cursor: pointer;
  margin-left: 20px;
  margin-block: 20px;
  transition: all 0.3s;
  &:hover {
    background-color: #6842ef;
    color: #fff;
  }
}
.ir-ws-editor-svg {
  height: 20px;
  width: 20px;

  cursor: pointer;
  color: black;
  &:hover {
    fill: #6842ef;
  }
}
.ir-ws-add-section-button {
  height: 60px;
  width: 200px;
  // background-color: #fff;
  box-shadow: inset 0 0 0 2px #6842ef;
  margin-left: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #6842ef;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #6842ef;
    color: #fff;
  }
}
.opened-content {
  height: 200px;
  width: 79%;
}
.ir-ws-lecture-file-upload {
  height: max-content;
  background: #ffffff;
  // border-right: 2px solid rgb(176, 176, 176);
  // border-left: 2px solid rgb(176, 176, 176);
  // border-bottom: 2px solid rgb(176, 176, 176);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
}
.ir-ws-lecture-file-upload-content {
  background: rgb(220, 220, 220);
  border-radius: 10px;
  // margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // padding-inline: 35px;
  height: 100px;
  width: 100px;
  &:hover {
    border: 2px solid #6842ef;
  }
}
.ir-ws-uploaded-video-name {
  // width: 50%;
  // height: 10%;
  // overflow: y;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflow content */
  text-overflow: ellipsis;
  margin: 0;
}
.ir-ws-lecture-with-upload {
  width: 100%;
}
.ir-ws-lecture-with-upload-active {
  border-radius: 10px;
  border: 1px solid #6842ef;
}
.ir-ws-uploaded-file-show-container {
  width: 40%;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.ir-ws-uploaded-file-show {
  width: 300px;
  background: rgb(220, 220, 220);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-inline: 10px;
  // gap: 20px;
}
.ir-ws-uploaded-file-name {
  // margin-bottom: -1px;
  // margin-left: 10px;
  // width: 70%;
  // height: 20px;
  margin: 0;
  overflow: hidden;
}
.show-content-svg {
  height: 30px;
  width: 30px;
  cursor: pointer;
  opacity: 0.5;
}

.ir-ws-course-promotional-video-loader {
  width: 25px;
  animation: rotateLoader 2s linear infinite;
}

.ir-ws-course-promotional-video-uploaded {
  width: 25px;
}

@keyframes rotateLoader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
