.ir-ws-banner-main-container {
    padding-top: 100px;

        .ir-ws-not-show {
            display: none;
        }

        .ir-ws-prev {
            position: absolute;
            top: 50%;
            left: 1px;
            transform: translateY(-50%);
        }
    
        .ir-ws-next {
            position: absolute;
            top: 50%;
            right: 1px;
            transform: translateY(-50%);
        }
    
    @keyframes fade {
        from {
        opacity: 0.1;
        }
        to {
        opacity: 1;
        }
    }
    
    .ir-ws-arrown-btn {
        border: 0;
        background-color: Transparent;
        font-size: 20px;
        display: none;
    }
    
    .ir-ws-dots-container {
        text-align: center;

        .dot {
            cursor: pointer;
            height: 5px;
            width: 20px;
            margin: 0 2px;
            background-color: #ddd;
            display: inline-flex;
            transition: background-color 0.6s ease;
            // display: none;

            &.active {
                background-color: #717171;
                opacity: 1;
            }

            &:hover {
                background-color: #717171;
                opacity: 1;
            }
        }
    }

    .ir-ws-banner-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ir-ws-banner-content-container {
            .ir-ws-banner-highlight-text {
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                margin: 0;
                margin-bottom: 16px; 
                span {
                    display: inline-block;
                }

                .ir-ws-animate-learn {
                    animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
                    animation-duration: 4s; 
                }
                .ir-ws-animate-practice {
                    animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
                    
                    animation-duration: 4s; 
                }
                .ir-ws-animate-excel {
                    animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
                    animation-duration: 4s; 
                }
            }

            .ir-ws-banner-heading {
                font-weight: bold;
                letter-spacing: 0.2px;
                margin: 0;
                margin-bottom: 16px;
                animation: fadeInLeft; /* referring directly to the animation's @keyframe declaration */
                animation-duration: 4s; 
                

                span {
                    color: #2C3EE2;
                }
            }

            p {
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0.2px;
                margin: 0;
                margin-bottom: 16px;
                color: #737373;
                max-width: 600px;
                animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
                animation-duration: 3s; 

            }

            .ir-ws-banner-btn-container {
                margin-top: 16px;
                animation: slideInUp; /* referring directly to the animation's @keyframe declaration */
                animation-duration: 4s; 

                .ir-ws-banner-btn {
                    width: 320px;
                    border-radius: 5px;
                    padding: 15px 40px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px; /* 157.143% */
                    letter-spacing: 0.2px;
                    position: relative;
                    background-size: 400%;
                    height: 52px;
                    background-size: 400%;
                    color: #fff;
                    border: none;
                    display: inline-block;
                    text-align: center;
    
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        width: 100%;
                        height: inherit;
                        border-radius: inherit;
                        background: rgb(43,167,255);
                        background: linear-gradient(90deg, rgba(43,167,255,1) 0%, rgba(104,66,239,1) 100%);
                        transition: all 0.8s;
                      }
    
                    &:hover::before {
                        transform: scaleX(1);
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    span {
                        position: relative;
                        z-index: 1;
                    }
                }
            }
        } 

        .ir-ws-banner-img-container {
            text-align: right;
            width: 50%;
            animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
            animation-duration: 4s; 
        }
    }
}

.ir-ws-hme-banr-close-icon {
    display: none;
    bottom: 30px;
    right: 60px;
    cursor: pointer;
    z-index: 2;
}

.ir-carousel-show-item {
    opacity: 0;
    transition: all 5s;

    &.ir-ws-show {
        opacity: 1;
    }
}