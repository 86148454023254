.archive-case-price {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.archive-case-description {
  margin-top: 10px;
  font-size: 14px;
}
