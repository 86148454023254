.ir-ws-inner-privacy-main-container {
    max-width: 990px;
    margin: 0 auto;
}
    .ir-ws-privacy-policy-content-container {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
        .ir-ws-privay-subtitle {
            font-weight: 700;
            font-size: 22px;
        }

        .ir-ws-privacy-para {
            font-size: 14px;
            line-height: 30px;
        }

        .ir-ws-privacy-list-container {
            margin-top: 0;
            list-style:auto;
            padding-left: 40px;

            .ir-ws-privacy-list {
                font-size: 14px;
            }
        }
    }