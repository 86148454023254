// .ir-couser-loader-page-container{
//     height: 100vh;
// }
// .ir-ws-course-loader {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 150px;
//     height: 150px;
//     background: transparent;
//     border: 3px solid rgba(0, 102, 255, 0.1);
//     border-radius: 50%;
//     text-align: center;
//     line-height: 150px;
//     font-family: sans-serif;
//     font-size: 20px;
//     color: #0066ff;
//     letter-spacing: 2px;
//     text-transform: uppercase;
//     text-shadow: 0 0 10px #0066ff;
//     box-shadow: 0 0 20px rgba(0, 0, 0, .15);
//   }

//   .ir-ws-course-loader::before {
//     content: '';
//     position: absolute;
//     top: -3px;
//     left: -3px;
//     width: 100%;
//     height: 100%;
//     border: 3px solid transparent;
//     border-top: 3px solid #0066ff;
//     border-right: 3px solid #0066ff;
//     border-radius: 50%;
//     animation: animateC 2s linear infinite;
//   }

//   .ir-ws-course-loader span {
//     display: block;
//     position: absolute;
//     top: calc(50% - 2px);
//     left: 50%;
//     width: 50%;
//     height: 4px;
//     background: transparent;
//     transform-origin: left;
//     animation: animate 2s linear infinite;
//   }

//   @keyframes animateC {
//     0% {
//       transform: rotate(0deg);
//     }

//     100% {
//       transform: rotate(360deg);
//     }
//   }

//   @keyframes animate {
//     0% {
//       transform: rotate(45deg);
//     }

//     100% {
//       transform: rotate(405deg);
//     }
//   }

//   .ir-course-loading-heading{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #772ce0;
//     font-size: 35px;
//   }

.ir-course-preview-loader-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(202, 187, 255, 1) 100%
  );
  .ir-course-preview-loader-container {
    width: 50%;
    // margin-top: -150px;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ir-course-preview-loader-status-text {
      margin: 0;
      font-size: 2rem;
      font-weight: 700;
    }
    .ir-course-preview-loader-converting-text {
      margin: 0;
      font-size: 1.8rem;
      font-weight: 700;
    }
    .ir-course-preview-loader-box {
      width: 150px;
      height: 150px;
      border: 5px solid #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      position: relative;
      .ir-course-preview-upload-status-text {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        margin: 0;
        font-size: 2rem;
        font-weight: 700;
        color: #6842ef;
        transform: "rotate(90deg)";
      }
    }

    .ir-course-preview-loader-time-remaining-text {
      margin: 0;
      margin-top: 20px;
      // margin-bottom: 50px;
    }
  }
}
