.ir-chat-content-messages-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  // background-color: rgba(28, 59, 102, 0.1);
  background: url("../../../assets/images/chat-images/chat-content-bg.png");
  height: 100%;
}
.loadingContainer {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 9999;
  background: #00000055;
  align-items: center;
  justify-content: center;

  .loadingIcon {
    display: block;
    width: 100px;
    img {
      width: 100%;
    }
  }
}

.ir-chat-top-head-invite-media {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  background-color: #cdbfff;
  transition: background-color 0.3s ease;
  // flex-shrink: 0;
  // img{

  // }

  &:hover {
    background-color: #6842ef;
  }
}
.ir-chat-topbar {
  padding: 10px;
  .ir-chat-top-header {
    width: 100%;
    background: #fff;
     border-radius: 10px;
     display: flex;
     flex-direction: column;
    .ir-chat-top-header-main-cont{
      padding: 8px 20px;
      width: 100%;
      // border-bottom: 1px solid #cfcfcf;
    display: flex;
    align-items: center;
    // justify-content: space-between;
gap:10px;
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #212529;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .ir-chat-top-head-elements {
      display: flex;
    }

    .ir-chat-top-head-invite {
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      overflow: hidden;
      transition: width 0.3s ease;

      i {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        border-radius: 10px;
        background-color: #cdbfff;
        color: #fff;
        z-index: 2;
        transition: background-color 0.3s ease;
        flex-shrink: 0;
        &:hover {
          background-color: #6842ef;
        }
      }

      &.active {
        width: 450px;
        border-radius: 10px;

        i {
          // background-color: #6842ef;
        }
      }

      .ir-chat-search-input {
        position: absolute;
        right: 50px;
        height: 100%;
        width: 0;
        opacity: 0;
        border: none;
        outline: none;
        padding: 0 8px;
        font-size: 14px;
        border-radius: 10px;
        background-color: #cdbfff;
        color: #333;
        transition: width 0.3s ease, opacity 0.3s ease;

        &::placeholder {
          color: #aaa;
        }
      }

      &.active .ir-chat-search-input {
        width: calc(100% - 40px);
        opacity: 1;
        padding: 0 0 0 20px;
      }
    }

   }
   .ir-top-chat-bar-question-line{
    height: 1px;
    background: #DEDEDE;
    margin: 0 20px;
   }
   .ir-top-chat-question-cont{
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      height: 56px;
      padding: 8px 20px;
      // max-height: 0;
      overflow: hidden;
      position: relative;
      transition: height 0.3s ease-in-out;

      .ir-ws-chat-question-text-cont{
        width: 100%;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        .ir-ws-chat-text-box{


        display: flex;
        // align-items: center;
        gap:10px;

        p{margin: 0;}
        .ir-chat-q-text{
          font-size: 20px;
          color: #6842EF;
          font-weight: 700;
        }


          .ir-chat-main-ques-text {
            font-size: 14px;
            color: #424242;
            margin-top: 4.7px;
            text-transform: none;
          }

          .ir-chat-main-ques-text::first-letter {
            text-transform: uppercase;
          }


      }
      }
      &.open {
        // .ir-ws-chat-question-text-cont {
          height: 270px;
        // }
      }

      .ir-chat-questtion-pagination-cont{
        display: flex;
        align-items: center;
        gap:10px;
        position: absolute;
        bottom:8px;

        p {
          margin: 0;
          color: #CDBFFF;
           font-size: 14px;
           transition: all 0.3s;
           font-weight: 700;
              cursor: pointer;
            &:hover{
            color: #6842EF;
          }
        }
      }

   }
  }
}

.ir-chat-q-text{
    font-size: 20px;
    color: #6842EF;
    font-weight: 700;
  }
  .ir-chat-main-ques-text{
    font-size: 14px;
    color:#424242;
    margin-top: 4.7px;

  }

.ir-ws-chat-questio-expand-container{
    display: flex;
}

.ir-ws-question-img-main-cont{
      display: flex ;
      flex-direction: column;
      place-items: flex-end;
      gap:20px;
  .ir-ws-chat-questio-img-cont{
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background: #C4C4C4;
    overflow: hidden;

    .ir-ws-chat-questio-img{
        width: 100%;
        height: 100%;
        object-fit: contain;


    }
  }
}

.ir-chat-top-chevron{
  color: #6842EF;
  font-weight:bold;
  font-size: 20px;
  cursor: pointer;
}

.top-nav-bar-menu-items-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ir-chat-top-head-view-more {
  font-weight: 600;
  cursor: pointer;
  //  margin-left: 10px;
  position: relative;

  i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 10px;
    background-color: #cdbfff;
    color: #fff;
    z-index: 2;
    transition: background-color 0.3s ease;
    flex-shrink: 0;
    &:hover {
      background-color: #6842ef;
    }
  }
}

.ir-chat-menu-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  list-style: none;
  padding: 8px 0;
  margin: 8px 0 0;
  width: 222px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: background-color 0.3s ease;
  &:hover {
    i {
      background-color: #6842ef;
    }
  }
  .ir-chat-menu-item {
    padding: 4px 6px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    width: 100%;

    display: flex;
    align-items: center;

    &:hover {
      background-color: #f5f5f5;
    }
    i {
      font-size: 18px;
      font-weight: 600;
      margin-right: 6px;
    }
  }
}

.ir-chat-conversation {
  padding: 1.5rem;
  position: relative;
  flex: 1 1;
  overflow-y: auto;
  .simplebar-content {
    display: block;
  }

  .chat-conversation-list {
    // border: 2px solid red;
    list-style: none;
    display: var(--webkit-box);
    display: var(--webkit-flex);
    display: var(--moz-box);
    display: var(--ms-flexbox);
    display: flex;
    --webkit-box-orient: vertical;
    --webkit-box-direction: normal;
    --webkit-flex-direction: column;
    --moz-box-orient: vertical;
    --moz-box-direction: normal;
    --ms-flex-direction: column;
    flex-direction: column;
    --webkit-box-align: start;
    --webkit-align-items: flex-start;
    --moz-box-align: start;
    --ms-flex-align: start;
    align-items: flex-start;
  }

  .chat-list {
    max-width: 75%;
    margin-bottom: 1.5rem;
    // background: #fff;
    // padding: 5px 10px;
    // border-radius: 10px;
    .conversation-list {
      display: table;
      position: relative;

      width: 100%;
      max-width: 400px;

      .ir-chat-profile-dp-container {
        display: table-cell;
        width: 35px;
        height: 35px;
      }

      .ir-chat-profile-dp {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
        margin: 0;
        line-height: 2;
        background-color: #6842EF;
        border-radius: 50%;
        border: 2px solid #fff;
        color: #ffffff;
        font-family: Poppins-Bold;
        position: absolute;
      }

      .chat-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: inline-flex;
        margin-right: 10px;
      }
      .ir-chat-conv-info-container {
        position: relative;
        margin-top: 5px;
        // left: 30px;
        &:before {
          position: absolute;
          content: "";
          top: 0px;
          right: 0px;
          left: 0px;
          width: 0px;
          height: 0px;
          border: 5px solid #e1dafd;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }

        .ir-chat-conv-info-inner-container {
          background-color: #e3e3e3;
          //   border: 2px solid red;
          margin-left: 10px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 5px 8px;
          &.ir-chat-conv-info-container-poll {
            // &:before {
            //   border-color: white;
            //   border-left-color: transparent;
            //   border-bottom-color: transparent;
            // }
            background-color: #fff;

            .poll-question {
              color: #6842ef;
            }
            .poll-view-votes {
              // border: 2px solid red;
              color: #cdbfff;
              transition: color 0.3s ease;
              &:hover {
                color: #6842ef;
              }
            }
          }
        }
        .ir-chat-content-delete-icon {
          font-size: 16px;
          color: #6842ef;
          padding-left: 8px;
          cursor: pointer;
          //   &:hover{
          //     color: #6842ef;
          //   }
        }

        .ir-chat-conv-user-details {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ir-chat-conv-name {
            padding-right: 15px;
            // padding-left: 6px;
            font-size: 12px;
            font-weight: 600;
            text-transform: capitalize;
            color: #212529;
          }
        }

        .ir-chat-conv-time {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          // margin-top: 3.5px;
          color: rgba(0, 0, 0, 0.5);

          //   border: 2px solid red;
        }
      }

      .user-chat-content {
        .chat-image {
          cursor: pointer;
          height: 100%;
          width: 100%;
          max-height: 200px;
          object-fit: contain;
        }

        .ctext-content {
          display: block;
          font-size: 12px;
          position: relative;
          overflow-wrap: break-word;
          white-space: pre-wrap;

          video {
            height: 125px !important;
            cursor: pointer;
            width: 100%;
          }
          .ir-video-icon-container {
            position: relative;
            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
              font-size: 25px;
              color: #fff;
              background: #6842ef;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    &.chat-list-outgoing {
      margin-left: auto;

      .conversation-list {
        .ir-chat-profile-dp-container {
          position: absolute;
          left: -40px;
        }
        .ir-chat-conv-info-container {
          left: 0;
          &:before {
            border: 5px solid #d8ceff;
            border-left-color: transparent;
            border-bottom-color: transparent;
          }
          &.ir-chat-conv-info-container-poll {
            &:before {
              border-color: white;
              border-left-color: transparent;
              border-bottom-color: transparent;
            }
          }

          .ir-chat-conv-info-inner-container {
            // background: rgb(43, 167, 255);
            // background: linear-gradient(
            //   360deg,
            //   rgba(43, 167, 255, 1) 0%,
            //   rgba(75, 113, 247, 1) 1%,
            //   rgba(96, 79, 241, 1) 80%,
            //   rgba(104, 66, 239, 1) 100%
            // );
            background-color: #d8ceff;
            color: #424242;
            &.highlighted {
              background-color: #f5f5dc;
            }
            &.ir-chat-conv-info-container-poll {
              background: #fff !important;
              color: #000;

              .ir-chat-conv-user-details {
                .ir-chat-conv-name,
                .ir-chat-conv-time {
                  color: #000;
                }
              }

              .poll-question {
                color: #6842ef;
              }
              .poll-view-votes {
                // border: 2px solid red;
                color: #cdbfff;
                transition: color 0.3s ease;
                &:hover {
                  color: #6842ef;
                }
              }
            }

            .ir-chat-conv-user-details {
              .ir-chat-conv-name,
              .ir-chat-conv-time {
                color: #424242;
              }
            }
          }
        }
      }
    }
  }
}

.audio-waveform-container {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 20px;
  max-width: 350px;

  .play-pause-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      background-color: #20b357;
    }
  }

  .waveform {
    flex-grow: 1;
    margin-right: 10px;
    height: 40px;

    canvas {
      height: 40px !important;
    }
  }

  .time-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .current-time,
    .duration {
      font-size: 12px;
      color: #999;
    }

    .current-time {
      font-weight: bold;
      color: #333;
    }
  }
}

.highlighted {
  animation: scaleHighlight 0.4s ease-in-out;
  // background-color: #f5f5dc;
}

@keyframes scaleHighlight {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.date-separator {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  color: #888;
  font-size: 14px;

  .separator-content {
    display: inline-block;
    background-color: #f0f0f0;
    color: #212529;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #333;
  }
}

.sticky-date-header {
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  padding: 5px 10px;
  font-weight: bold;
  z-index: 10;
}

.chat-date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 1rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #666;
  background-color: #f3f3f3;
  border-radius: 5px;
}

.app-content-footer {
  padding: 1.5rem;

  flex-direction: column;
  gap: 0 !important;

  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;

    .chat-text-area {
      max-height: 100px;
      height: 50px;
      overflow-y: auto;
      padding: 1rem 3rem 1rem 3rem;
      resize: none;
      font-size: 14px;
      line-height: 15px;
      border-radius: 10px;

      &:focus {
        box-shadow: 0 0 0 1px #6842ef;
        outline: 0;
        border-color: #b2a7d1;
      }
    }

    .app-content-footer-send {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3.5rem;
      border-radius: 10px;
      color: #fff;
      width: 35px;
      height: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6842ef;
      cursor: pointer;
    }
    .app-content-footer-voice {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5rem;
      border-radius: 10px;
      color: #fff;
      width: 35px;
      height: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6842ef;
      cursor: pointer;
    }

    .app-content-footer-buttons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.5rem;
      border-radius: 10px;
      color: #fff;
      width: 35px;
      height: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6842ef;
      cursor: pointer;

      .bi-paperclip {
        font-size: 20px;
      }
    }

    .ir-ws-add-attachment-main-container {
      width: 100%;
      max-width: 200px;
      background-color: rgba(104, 66, 239, 1);
      position: absolute;
      bottom: 56px;
      // transform-origin: left bottom;
      transform: scale(0);
      opacity: 0;
      border-radius: 10px;
      position: absolute;
      z-index: 1000;
      padding: 9px 5px;

      &.addActiveOpen {
        transform-origin: left bottom;
        transform: scale(1);
        opacity: 1;
      }

      #sendAttachImage,
      #sendAattachDocs,#sendAttachVideo {
        display: none;
      }

      .add-attach-menu-item {
        width: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
        padding: 5px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background-color: #896df8;
        }

        label {
          cursor: pointer;
          width: 100%;
          margin-bottom: 0;
        }

        i {
          color: #fff;
          font-size: 16px;
          line-height: 1;
          cursor: pointer;
          z-index: 1;
          margin-right: 10px;
        }
      }
    }
  }
}

.gap-3 {
  gap: 1rem !important;
}

span.remove-file-item {
  font-size: 11px;
  font-family: Poppins-Bold;
  padding-top: 5px;
  padding-left: 15px;
  opacity: 0.5;
}

@keyframes ripple-out {
  0% {
    opacity: 1;
  }
  100% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 0;
  }
}

.ir-attach-image-file-preview {
  max-width: 90%;
  margin: 0 auto;
  max-height: 300px;
}

.button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.audio-controls,
.video-controls {
  margin-bottom: 20px;
}
.audio-player,
.video-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audio-player,
.video-player,
.recorded-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.live-player {
  height: 200px;
  width: 400px;
  border: 1px solid #646cff;
  margin-bottom: 30px;
}
.recorded-player video {
  height: 400px;
  width: 800px;
}

.iks-mint-cust-modal {
  &.ir-chat-inside-modal-container {
    &.ir-image-preview-main-modal {
      .iks-mint-modal-container {
        background-color: rgba(28, 28, 28, 0.19);
        width: 100%;
        max-width: none;
        display: block;
        height: 100%;
        overflow: hidden;
        padding: 20px;
        backdrop-filter: blur(8px);

        .ir-image-large-thumb-title-container {
          display: flex;
          width: 100%;
          justify-content: space-between;

          h3 {
            font-size: 24px;
            color: #fff;
            text-transform: uppercase;
          }

          .ir-image-head-thumb-right {
            button {
              background-color: #fff;
              border: 0;
              color: #000;
              font-size: 20px;
              height: 40px;
              width: 40px;
              border-radius: 50%;
              transition: opacity 0.3s;
              &:hover {
                opacity: 50%;
              }
              i {
                line-height: 2;
              }
            }
          }
        }

        .ir-modal-thumb-container-large-image {
          height: calc(100vh - 250px);
          position: relative;

          .chat-image,
          video {
            position: absolute;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translateX(-50%) translateY(-50%);
            background-color: #fff;
            top: 50%;
          }
        }

        .iks-carousel-inactive {
          display: none;
        }
        .ir-thumbanil-modal-image {
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          bottom: 0;
          .ir-car-item-container {
            // padding: 7px;
            margin-right: 10px;
            background-color: rgba($color: #6842ef, $alpha: 0.3);
            border-radius: 7px;
            border: 2px solid rgba($color: #6842ef, $alpha: 0.3);
            cursor: pointer;
            &:hover {
              border: 2px solid rgb(193, 155, 255);
            }

            &.iks-carousel-item-active {
              border: 2px solid rgb(193, 155, 255);
            }

            img,
            video {
              width: 60px;
              height: 60px;
            }

            .ir-video-icon-container {
              position: relative;
              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                font-size: 25px;
                color: #fff;
                background: #6842ef;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

.ir-chat-poll {
  // border-radius: 10px;
  padding: 15px;
  // font-family: Arial, sans-serif;
  width: 280px;
  // background-color: #004b46;

  & .sender-vote-poll {
    color: #ffffff;
  }
  & .receiver-vote-poll {
    color: #000;
  }

  .poll-question {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    // color: #ffffff;
  }

  .poll-options {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .poll-option {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      padding: 8px 0px;

      .poll-radio {
        display: flex;
        align-items: center;
        justify-content: center;

        input[type="radio"] {
          display: none;
        }

        input[type="radio"] + label {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: inherit;
        }

        input[type="radio"] + label::before {
          content: "";
          // content: '\2713';
          font-size: 12px;
          text-align: center;
          font-weight: 600;
          padding: 3px;
          display: block;
          width: 15px;
          height: 15px;
          border: 2px solid #788892;
          border-radius: 50%;
          margin-right: 8px;
          background-color: transparent;
          transition: background-color 0.2s, border-color 0.2s;
          line-height: 12px;
          color: transparent;
        }

        input[type="radio"]:checked + label::before {
          background-color: #6842ef;
          border-color: #6842ef;
          color: white;
        }
      }

      .poll-progress-bar-container {
        position: absolute;
        // left: 35px;
        bottom: -5px;
        height: 12px;
        background-color: #d3d3d3;
        border-radius: 12px;
        z-index: 0;
        overflow: hidden;
      }

      .poll-progress-bar-container {
        position: absolute;
        // left: 35px;
        bottom: -5px;

        width: 100%;
        height: 8px;
        border-radius: 12px;
        background-color: #d3d3d3;
        overflow: hidden;
        // position: relative;
      }

      .poll-progress-bar-bg {
        width: 100%;
        height: 100%;
        background-color: #d3d3d3;
      }

      .poll-progress-bar-fg {
        height: 100%;
        transition: width 0.3s ease;
        background-color: #6842ef;
      }

      .poll-label {
        flex-grow: 1;
        cursor: pointer;
        font-size: 14px;
        color: #ffffff;
        word-wrap: break-word;
      }

      .poll-vote-count {
        font-size: 12px;
        // color: #ffffff;
        margin-left: auto;
      }
    }
  }

  .poll-footer {
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-top: 30px;
    padding-top: 8px;
    border-top: 1px solid #cfcfcf;

    .poll-view-votes {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      // text-decoration: underline;
    }
  }
}

.poll-view-votes {
  cursor: pointer;
}

.voter-list-container {
  max-height: 350px;
  // width: 300px;
  overflow-y: auto;
  padding: 10px;
  // border: 1px solid #ddd;
  border-radius: 5px;
  // background-color: #f9f9f9;

  background: linear-gradient(
    360deg,
    rgb(43, 167, 255) 0%,
    rgb(75, 113, 247) 1%,
    rgb(96, 79, 241) 80%,
    rgb(104, 66, 239) 100%
  );
  padding: 10px 20px;
  border-radius: 10px;

  position: absolute;
  // left: -160px;
  top: 0;
  &.sender-voter-list-container {
    left: -160px;
  }
  &.receiver-voter-list-container {
    right: -160px;
  }

  .voter-list-option {
    margin-bottom: 20px;

    .option-title {
      font-weight: bold;
      margin-bottom: 10px;
      // color: #333;
    }

    .voter-list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .voter-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .voter-profile-pic {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid #ccc;
        }

        .voter-username {
          font-size: 14px;
          // color: #555;
        }
      }

      .no-votes {
        font-size: 14px;
        // color: #888;
        // font-style: italic;
      }
    }
  }
}

.ir-chat-list-container-documents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFF;
  padding: 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: Poppins-Bold;
  font-size: 12px;
  //   border: 2px solid red;
  width: 250px;
}

.ir-chat-list-container-documents a {
  width: 28px;
  border-radius: 50%;
  border: 1px solid #75a8ff;
  height: 28px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Bold;
  margin-left: 15px;
}

.ir-chat-list-container-documents a i {
  font-size: 14px;
}

.doc-file-type {
  font-size: 24px;
  color: #6842ef;
}

.ir-whiteboard-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.ir-whiteboard-modal-container {
  background-image: url("../../../assets/images/chat-images/whitebd-bg.png");
  background-repeat: repeat;
  background-color: #fff;

  .ir-form-title {
    display: flex;
    position: fixed;
    top: 20px;

    h2 {
      font-size: 24px;
    }

    button {
      background: transparent;
      border: 0;
      box-shadow: none;
    }
  }


}
.ir-wbd-list {
  position: fixed;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #cdbfff;
  left: 20px;
  padding: 15px 5px;
  border-radius: 8px;
  .ir-wbd-list-item {
   
    label {
      cursor: pointer;
      position: relative;
      margin: 5px 0;
      display: block;
      text-align: center;
    }
    input[type="radio"] {
      opacity: 0; /* hidden but still tabable */
      position: absolute;
    }
    input[type="radio"] + span {
      border-radius: 10px;
      padding: 12px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      // color: rgba(255, 255, 255, 0.6);
      color:#fff;
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 8px;
      }
      &:hover {
        background-color: #6842ef;
        color: #fff;
      }
    }
    input[type="radio"]:checked + span {
      background-color: #6842ef;
      color: #fff;
      // box-shadow: 0 0 8px rgba(104, 66, 239, 0.8);
      // font-weight: bold;
    }
  }
}
.ir-wbd-upload-btn-container {
  position: fixed;
  bottom: 18px;
  right: 20px;
  border-radius: 50%;
  background-color: #47bd94;

  button{
    padding: 0px !important;
    line-height: normal;
    width: 52px;
    height: 52px;
  }
  span {
    font-size: 40px;
    color: #fff;
    line-height: normal;
  }
}
.ir-wbd-btn-main-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ir-wbd-btn-container {
    padding: 6px;
    border-radius: 8px;
    background-color: #cdbfff;
    display: flex;
    align-items: center;
    gap:6px;

    .ir-wbd-undo-redo-btn {
      // background-color: transparent;
      // border: 0;
      // box-shadow: none;
      // color: rgba(255, 255, 255, 0.6);
      color:#fff;
      text-align: center;
      transition: all 0.3s;
      border-radius: 8px;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #6842ef;
        color: #fff;
      }
    }

    .ir-wbd-redo-undo-line{
      width: 1px;
      height: 38px;
      background: #fff;
    }
  }
  .ir-clear-screen {
    padding: 15px;
    border-radius: 8px;
    background-color: rgb(224, 41, 53);
    border: 0;
    color: #fff;
    margin-left: 20px;
    font-family: Poppins-Bold;
  }
}

// .ir-ws-sign-popup-container {
//   .FIE_topbar-buttons-wrapper {
//     order: 3;
//     display: flex;
//   }
//   button.FIE_topbar-save-button.SfxButton-root {
//     width: 100px;
//     padding: 10px;
//     height: 35px;
//     font-size: 20px !important;
//     background-color: #6842ef;
//     border-radius: 5px;
//   }

//   .FIE_topbar-save-wrapper {
//     width: 100px !important;
//     order: 3;
//     margin-left: 15px;
//   }

//   span.SfxButton-Label {
//     font-size: 0 !important;
//     font-family: Poppins-Bold;
//     padding: 0;
//     position: relative;
//     width: 100px;
//   }

//   .SfxButton-Label:before {
//     position: absolute;
//     content: "Upload";
//     color: #fff;
//     width: 100px;
//     font-size: 14px;
//     text-transform: uppercase;
//     left: -10px;
//     font-family: Poppins-Bold;
//     letter-spacing: 0.8px;
//   }
// }

.ir-ws-clinical-note-input-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  z-index: 99999999;
}

.ir-ws-clinical-note-input-field {
  width: 40%;
  padding: 10px;
  // margin-top: -20px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
}

.ir-carousel-action-btns {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #cdbfff;
  border-radius: 50%;
  border: 0;
  box-shadow: none;
  transition: all 0.3s;
  width: 40px;
  height: 40px;
  &:hover{
    background-color: #6842ef;
  }
  i {
    font-size: 20px;
    color:#fff;
    line-height: normal;
  }

  &.ir-right-car-btn {
    right: 0;
  }
}

.ir-share-modal-inside-content {
  .progress {
    width: 80%;
    margin: 0 auto;
    height: 30px;

    .progress-bar {
      background: linear-gradient(90deg, #47bd94 0%, #6842ef 100%);
    }
  }
}

.buyNowBtnParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  text-align: center;
  flex-wrap: wrap;
}
.buyNowBtn {
  height: 50px;
  background-color: #0074d4;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 8px 44px;
  cursor: pointer;
  border-radius: 4px;
}

.ir-ws-video-trim-container {
  // margin-top: 20px;
  text-align: center;
  // border: 2px solid red;
  // height: 70vh;
  // overflow: hidden;
}

.ir-ws-video-trim-wrapper {
  // height: 90%;
  padding: 0 30px;
  // border: 2px solid red;
}

.ir-ws-video-preview {
  // width: 60%;
  // height: 75%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40vh;
  background-color: #000;
}

.ir-ws-trim-slider-container {
  // border: 2px solid red;
  width: 96%;
  margin: 0 auto;

  margin-top: 20px;
}

.ir-ws-video-wrapper {
  // height: 100%;
  padding: 0 30px;
}

.progress {
  margin-top: 30px !important;
  margin: 30px;
  // justify-content: center;
  .progress-bar {
    background-color: #6842ef;
  }
}
.ir-ws-slider-labels {
  // border: 2px solid red;
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.ir-ws-slider-labels span {
  font-weight: 500;
}
.ir-ws-trim-title {
  font-size: 14px;

  margin-bottom: 10px;
  font-weight: 500;
  color: #000;
}

.ir-ws-trim-controls {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.ir-ws-trim-slider {
  width: 45%;
}

.ir-ws-trim-button-container {
  margin-top: 10px;
}

.ir-ws-trim-btn {
  padding: 10px 15px;
  border-radius: 4px;
}

.ir-ws-trim-slider {
  width: 100%;
  appearance: none;
  background: #ddd;
}

.ir-ws-trim-slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 50px;
  background: #007bff;
  // border-radius: 50%;
  cursor: grab;
}

.ir-ws-trim-slider {
  width: 100%;
  height: 50px;
}

.ir-ws-trim-slider {
  width: 100%;
  margin: 20px 0;
}

.ir-ws-trim-button-container {
  margin-top: 10px;
}

/* Recording wave Animation*/
.recording-wave {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 20px;
}

.wave-bar {
  width: 5px;
  height: 10px;
  // background-color: #ff3b3b;
  background-color: #6842ef;
  margin: 0 3px;
  animation: waveAnimation 1s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes waveAnimation {
  0%,
  100% {
    height: 10px;
  }
  50% {
    height: 30px;
  }
}

.recording-active .recording-wave {
  display: flex;
}

.recording-inactive .recording-wave {
  display: none;
}

.noUi-handle {
  display: none;
}

.noUi-connect {
  background: #6842ef;
  // height: 6px;
}

.noUi-target {
  height: 10px;
}
.ir-thumb-image-inner-container {
  // position: relative;
}
.chat-img-edit-btn {
  position: absolute;
  right: 0px;
  top: 15px;

  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #cdbfff;
  color: #fff;
  //   border: none;
  border-radius: 50%;
  transition: all 0.3s;
&:hover{

  background: #6842ef;
}
  i {
    color: #fff;
  }
}

.trim-upload-btn {
  margin-top: 30px;
}
.ir-ws-edit-img-upload-modal {
  height: 85vh;
}
.ir-ws-upload-video-text {
  margin-bottom: 30px !important;
  line-height: normal !important;
}

.poll-creation-container {
  text-align: center;
  margin-top: 20px;
}

.poll-create-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.poll-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  //   border-radius: 8px;
}

.poll-popup-content h2 {
  margin-bottom: 15px;
}

.poll-question-label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.poll-header {
  border-bottom: 1px solid #333;
  // text-align: left;
  margin-bottom: 30px;
}
.poll-heading {
  font-size: 18px;
  font-weight: 500;
  // margin-left: 40px;
}

//   .poll-question-input,
//   .poll-option-input {
//     width: 100%;
//     padding: 8px;
//     margin-bottom: 10px;
//     border: 1px solid #cfcfcf;
//     border-radius: 5px;
//     font-size: 14px;
//   }

.poll-question-input,
.poll-option-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 2px solid #cfcfcf;
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  background: transparent;
  outline: none;
  border-radius: 0;
  transition: border-color 0.3s ease;
}

.poll-option-input {
  margin-bottom: 20px;
}

.poll-question-input:focus,
.poll-option-input:focus {
  border-bottom: 2px solid #007bff;
  border-radius: 0;
}

.poll-buttons-container {
  display: flex;
  justify-content: space-between;
}

.poll-cancel-button {
  // padding: 8px 16px;
  // border: none;
  // border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.poll-submit-button {
  position: absolute;
  bottom: 30px;
  width: 90%;

  i {
    margin-right: 4px;
  }
}

.poll-cancel-button {
  // background-color: white;
  // background: transparent;
  color: #212529;
  font-size: 20px;
  // font-weight: 500;

  position: absolute;
  right: 15px;
  top: 16px;
}

.poll-popup {
  // top: 0;
  // left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.poll-popup-content {
  background-color: white;
  padding: 20px;
  width: 450px;
  // max-width: 400px;
  height: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  // text-align: center;

  position: relative;
}

.poll-error-message {
  color: red;
  font-size: 12px;
  // margin-top: 5px;
  font-weight: 500;
}

//   @@@@@@@@@@@@@@@@

.poll-option-wrapper {
  display: flex;
  // align-items: center;
  // padding: 8px;
  // background-color: white;
  // margin-bottom: 8px;
  // border: 1px solid #ddd;
  // border-radius: 5px;
  // cursor: grab;
}

.poll-option-drag-icon {
  text-align: right;
  cursor: grab;
  margin-left: 10px;
  font-size: 18px;
  color: #555;
}
.option-input-cont {
  flex: 1;
}

.ir-chat-view-more-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ir-chat-view-more-modal-container {
  background: #ffffff;
  border-radius: 10px;
  height: auto;
  height: auto;
  max-height: 500px;
  width: 100%;
  max-width: 500px;
  padding: 20px 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: left;
  overflow-y: auto;
  position: relative;

  // border: 2px solid red;
}

.ir-chat-view-more-modal-close-btn {
  background: none;
  border: none;
  font-size: 40px;
  position: absolute;
  top: 0px;
  right: 15px;
  color: #212529;
  cursor: pointer;
  transition: color 0.3s ease;
}

.ir-chat-view-more-modal-close-btn:hover {
  color: #6842ef;
}

.ir-chat-view-more-modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: #212529;
  position: relative;
  width: 95%;

  &::after {
    content: "";
    display: block;
    width: 80%;
    height: 2px;
    background-color: #6842ef;
    margin: 8px auto 0;
  }
}

.ir-chat-view-more-modal-user-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 60%;
  overflow-y: auto;
}

.ir-chat-view-more-modal-user-item {
  padding: 12px 15px;
  // border-bottom: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  transition: background 0.3s ease;
}

.ir-chat-view-more-modal-user-item:last-child {
  border-bottom: none;
}

.ir-chat-view-more-modal-user-item:hover {
  background: #cdbfff;
  cursor: pointer;
}

.ir-chat-view-more-modal-empty {
  font-size: 14px;
  color: #777;
  text-align: center;
  margin-top: 20px;
}

.ir-chat-content-download-icon {
  font-size: 16px;
  color: #007bff;
  margin-left: 10px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #0056b3;
  }
}

.chat-header {
  position: fixed;
  bottom: 64px;
  // background-color: #f8f9fa;
  z-index: 10;
  padding: 10px;
  display: flex;
  // width: 40%;
  width: 100%;
  max-width: calc(100% - 930px);
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #ddd;

  .filter-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 10px;

    span {
      font-size: 15px;
      font-weight: 600;
      color: #555;

      strong {
        color: #000;
      }
    }

    .clear-filters-btn {
      // padding: 5px 10px;
      // background-color: #dc3545;
      // color: #000;
      border: none;
      // border-radius: 5px;
      cursor: pointer;
      text-decoration: underline;
      color: #6842ef;
      // transition: background-color 0.3s;

      // &:hover {
      //     background-color: #0056b3;
      // }
    }
  }
}

.ir-chat-frozen-message {
  text-align: center;
  padding: 20px;
  // background-color: #f8d7da;
  // color: #721c24;
  // border: 1px solid #f5c6cb;
  border-radius: 5px;

  display: flex;
  width: 100%;
  gap: 8px;
  // height: 50px;
  justify-content: center;
  align-items: center;

  .group_unfreeze_btn {
    border: none;
    color: #6842ef;
    font-weight: 500;
    font-size: 14px;
    margin-top: -14px;
    background: transparent;
    transition: text-decoration 0.4s ease-in;
    &:hover {
      text-decoration: underline;
      transition: transform 0.4s ease-in;
    }
  }
}

.ir-chat-menu-container {
  position: relative;

  .ir-chat-menu-button {
    background: none;
    border: none;
    font-size: 16px;
    color: #424242;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  .ir-chat-menu-options {
    position: absolute;
    // width: 180px;
    //   top: 25px;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    z-index: 10;

    .ir-chat-menu-option {
      padding: 10px 15px;
      font-size: 14px;
      color: #333;
      background: #fff;
      border: none;
      text-align: left;
      cursor: pointer;
      width: 100%;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .ir-chat-emoji-picker {
    position: absolute;
    top: -50px;
    left: 0;
    z-index: 20;
    // background: #fff;
    // border: 1px solid #ddd;
    // border-radius: 8px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    // padding: 10px;

    // .emoji-picker-react {
    //   border: 2px solid red;
    //   width: 280px;
    //   max-height: 400px;
    //   overflow-y: auto;

    //   // Example of customizing emoji categories
    //   .emoji-picker-react-category {
    //     padding: 5px;
    //     font-size: 12px;
    //     font-weight: bold;
    //     color: #555;
    //   }
    // }
  }
}

.ir-chat-menu-options {
  position: absolute;
  // width: 180px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 10;

  &.sender-menu {
    left: -147px;
    top: 30px;
  }

  &.receiver-menu {
    left: 0;
  }

  .ir-chat-menu-option {
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    background: #fff;
    border: none;
    text-align: left;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.ir-chat-emoji-picker {
  position: absolute;
  top: 80px;
  z-index: 20;
//   background: #fff;
//   border: 1px solid #ddd;
//   border-radius: 8px;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   padding: 10px;

//   &.sender-picker {
//     //   right: 0;
//     left: -647px;
//     //   top: 20px;
//   }

//   &.receiver-picker {
//     left: 0;
//   }

&.sender-picker {
    left: -547px;
  }

  &.receiver-picker {
    left: 0;
  }

  &.top {
    // bottom: auto;
    top: -340px;
  }

  &.bottom {
    // top: auto;
    // bottom: -320px;
  }
aside{
    width: 320px !important;
    height: 400px !important;
}
  // Style the emoji picker itself
  .emoji-picker-react {
    border: 2px solid #ddd;
    width: 280px;
    max-height: 400px;
    overflow-y: auto;

    .emoji-picker-react-category {
      padding: 5px;
      font-size: 12px;
      font-weight: bold;
      color: #555;
    }
  }
}

.reaction-container {
  position: absolute;

  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.reaction-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 12px;
  padding: 2px 6px;
  font-size: 14px;
  cursor: pointer;
}

.reactions-popup {
  position: absolute;
  // top: 50%;
  // left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 320px;
  padding: 15px;

  &.sender-reaction-con {
    // left: auto;
    right: 20px;
    color: #000;
  }

  &.receiver-reaction-con {
    top: 82px;
    left: 340px;
  }
}
.all-reaction-close-btn {
  border: none;
  font-size: 28px;
  background: transparent;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #666;
}

.popup-filters {
  display: flex;
  gap: 5px;
  margin-bottom: 14px;
  overflow-x: auto;
  font-size: 14px;
}

.filter-button {
  border: none;
  background: #f1f1f1;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}

.filter-button.active {
  background: #6842ef;
  color: white;
}

.popup-content {
  max-height: 200px;
  overflow-y: auto;
}

.reaction-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
  font-size: 14px;
}

.reaction-user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.clinical-notes {
  font-size: 12px;
  // color: #555;
  // background-color: #f5f5f5;
  // border-radius: 4px;
  padding: 6px 8px;
  width: 100%;
  word-wrap: break-word;
}

.create-question__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-question__popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.create-question__popup-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.create-question__textarea {
  width: 100%;
  // height: 80px;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.create-question__file-input {
  display: block;
  margin-bottom: 15px;
}

.create-question__checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 15px;
}

.create-question__checkbox {
  margin-right: 8px;
}

.create-question__actions {
  display: flex;
  justify-content: flex-end;
}

.create-question__submit-button,
.create-question__cancel-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.create-question__submit-button {
  background-color: #007bff;
  color: #fff;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.create-question__submit-button:hover {
  background-color: #0056b3;
}

.create-question__cancel-button {
  background-color: #f44336;
  color: #fff;
  transition: background-color 0.3s;
}

.create-question__cancel-button:hover {
  //   background-color: #c62828;
}
.rc-slider-track,
.rc-slider-tracks {
  background-color: #6842ef !important;
  opacity: 0.7 !important;
}
.rc-slider-handle {
  border: solid 2px #6842ef !important;
  background-color: #6842ef !important;
  opacity: 1 !important;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #6842ef !important;
  box-shadow: 0 0 0 5px #6842ef !important;
}

.create-question__popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  // border-radius: 8px;

  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.create-question__popup-content {
  // background-color: #fff;
  // padding: 20px;
  // border-radius: 8px;
  // width: 400px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  background-color: white;
  padding: 20px;
  width: 450px;
  // max-width: 400px;
  height: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  // text-align: center;

  position: relative;
}

.create-question__popup-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.create-question__textarea {
  width: 100%;
  // height: 80px;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.create-question__file-input {
  display: block;
  margin-bottom: 15px;
}

.create-question__checkbox {
  margin-right: 8px;
}

.create-question__actions {
  display: flex;
  justify-content: flex-end;
}

.create-question__submit-button,
.create-question__cancel-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.create-question__submit-button {
  background-color: #007bff;
  color: #fff;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.create-question__cancel-button {
  position: absolute;
  top: 8px;
  background-color: #fff;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  transition: background-color 0.3s;
}

// Checkbox alignment
.create-question__checkbox-container {
  display: flex;
  align-items: center;

  .create-question__checkbox-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 8px;
    cursor: pointer;

    .create-question__checkbox {
      margin: 0;
      width: 16px;
      height: 16px;
    }
  }
}

.create-question__textarea {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 400px;
  resize: none;
  overflow-y: scroll;
  transition: height 0.2s ease-in-out;

  outline: #007bff;
}

// File input styling
.create-question__file-input-container {
  margin-bottom: 15px;

  .create-question__file-input {
    display: none;
  }

  .create-question__file-label {
    display: inline-block;
    padding: 8px 12px;
    background-color: #6842ef;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      // background-color: #0056b3;
    }
  }
}

.create-question__image-preview {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;

  .create-question__preview-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .create-question__remove-img {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    font-size: 24px;
    line-height: 16px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      // background-color: rgba(255, 0, 0, 0.8);
    }
  }
}


.active-question-container{
    display: contents;
}
.up-chevron{
    position: absolute;
    bottom: 10px;
}

.pagination-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
//   transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

// .pagination-item {
//   width: 30px;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   color: #555;
//   background-color: #fff;
//   border: 1px solid #ddd;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s ease, color 0.3s ease;

//   &:hover {
//     background-color: #f0f0f0;
//   }

//   &.pagination-item--active {
//     color: #fff;
//     background-color: #6842ef;
//     border-color: #6842ef;
//     font-weight: bold;
//   }
// }


// .ir-ws-signup-content-inner-container {
//   .ir-share-container-image-modal {
//     height: calc(100% - 20px);
//     // .FIE_root {
//     //   color: #252b42 !important;
//     // }
//     label {
//       margin: 0px;
//     }

//     svg {
//       width: 20px;
//       height: 20px;
//     }
//     .FIE_topbar-save-button .SfxButton-Label::before {
//       content: "Done"; /* Replace "Save" with "Done" */
//     }

//     .FIE_topbar-save-button .SfxButton-Label {
//       visibility: hidden; /* Hide the original text */
//       position: relative;
//     }

//     .FIE_topbar-save-button .SfxButton-Label::before {
//       visibility: visible;
//       position: absolute;
//       left: 0;
//       top: 0;
//     }

//     .FIE_topbar-zoom-out-btn {
//       svg {
//         width: 14px !important;
//         height: 14px !important;
//       }
//     }

//     .FIE_topbar-zoom-in-btn {
//       svg {
//         width: 14px !important;
//         height: 14px !important;
//       }
//     }
//     .SfxSelect-Container {
//       display: none;
//     }
//     .SfxInput-root {
//       display: none;
//     }

//     .FIE_text-tool-options {
//       .sc-qvjmv1-1 {
//         display: none;
//       }
//     }
//     // .sc-qvjmv1-1 {
//     //   display: none;
//     // }
//     .sc-dwg14p-0 {
//       .sc-dwg14p-1 {
//         .iirfpj {
//           display: flex;
//           align-items: center;
//           justify-content: center;
//         }
//       }
//     }
//     .sc-qvjmv1-0 {
//       // display: none;
//     }
//     .kBJBhJ[aria-selected="true"] {
//       background: #cdbfff !important;
//     }

//     .kBJBhJ:hover {
//       background: #cdbfff !important;
//     }
//     .cSszUr[aria-selected="true"] {
//       background: #cdbfff !important;
//     }
//     .cSszUr:hover {
//       background: #cdbfff !important;
//     }
//     .epDskT {
//       background-color: #6842ef !important;
//     }
//     .oWOcv {
//       background-color: #6842ef !important;
//     }

//     .sc-21g986-0 {
//       // justify-content: center;
//       // gap: 30px;
//     }
//     // .sc-m9ezm7-0 {
//     //   position: fixed;
//     //   bottom: 25px;
//     //   right: 44%;
//     // }
//     .sc-dwg14p-1 {
//       padding: 0px;
//       // padding-bottom: 30px;
//     }

//     .sc-qvjmv1-1 {
//       div:nth-child(1),
//       div:nth-child(3) {
//         display: none;
//       }
//     }
//     .sc-dwg14p-1 {
//       ul li:nth-child(2),
//       ul li:nth-child(3),
//       ul li:nth-child(4),
//       ul li:nth-child(5) {
//         display: none;
//       }

//       ul {
//         li {
//           label {
//             margin-bottom: 0px;
//           }
//         }
//       }
//     }
//     .ir-chat-content-edited-img-main-cont {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: space-between;
//       height: 100%;
//       gap: 30px;
//       .ir-chat-content-edited-image-cont {
//         display: flex;
//         // flex-direction: column;
//         width: 90%;
//         height: 85%;
//         justify-content: center;
//         align-items: center;
//         border: 1px solid #cccc;
//         border-radius: 10px;
//         margin-top: 20px;

//         .ir-chat-cont-edited-img {
//           // width: 90%;
//           // height: 100%;
//           // width: 100%;
//           max-width: 100%;
//           max-height: 100%;
//           object-fit: contain;
//         }
//       }
//       .ir-chat-cont-img-input-cont {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         gap: 20px;

//         .ir-chat-cont-msg-send-btn {
//           background-color: #6842ef;
//           color: #fff;
//           padding: 10px 20px;
//           border-radius: 10px;
//           margin: 0px;
//           cursor: pointer;
//         }
//       }
//     }
//   }
// }
.oWOcv {
  background-color: #6842ef !important;
  transition: all 0.3s !important;
  &:hover {
    background-color: #5335bf !important;
  }
}

.ir-share-container-image-modal{
  height: 100%;
  
.ir-chat-multiple-image-cont {
  // width: 100%;
  // height: 100%;
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 20px;
  flex-wrap: nowrap;
  justify-content: center;
  height: 150px;
}

.ir-chat-multi-active-img{
  border: 1px solid #6842ef;
}

.ir-chat-multi-inactive-img{
  border: 1px solid #fff;
}


.ir-chat-mulit-image-box {
  flex-shrink: 0;
  // width: 50%; /* Each image takes 50% of the container width */
  width: 100px;
  height: 100px;
  // border: 1px solid #cccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden; /* Ensures the blur stays within the container's bounds */

  /* Icons container */
  .ir-ws-chat-multi-image-func {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    display: flex;
        align-items: center;
        gap: 10px;
    i {
      font-size: 24px;
      // margin: 20px;
      color: #6842ef;
    }
  }

  /* Hover effect for blur */
  &:hover {
    .ir-ws-chat-multi-image-func {
      opacity: 1; /* Smoothly show icons */
    }

    &::before {
      opacity: 1; /* Fade-in effect for the backdrop */
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Blackish semi-transparent layer */
    backdrop-filter: blur(8px); /* Apply blur effect */
    opacity: 0; /* Initial state is transparent */
    transition: opacity 0.3s ease-in-out;
    z-index: 1; /* Ensure it appears below the icons but above the image */
  }
}

.ir-chat-cont-img-input-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .ir-chat-cont-msg-send-btn {
    background-color: #6842ef;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 0px;
    cursor: pointer;
  }
}
}
.ir-chat-edit-container-image-modal {
  height: calc(100% - 30px);

  // .FIE_root {
  //   color: #252b42 !important;
  // }
  label {
    margin: 0px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
  .FIE_topbar-save-button .SfxButton-Label::before {
    content: "Done"; /* Replace "Save" with "Done" */
  }

  .FIE_topbar-save-button .SfxButton-Label {
    visibility: hidden; /* Hide the original text */
    position: relative;
  }

  .FIE_topbar-save-button .SfxButton-Label::before {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  .FIE_topbar-zoom-out-btn {
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }

  .FIE_topbar-zoom-in-btn {
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }
  .SfxSelect-Container {
    display: none;
  }
  .SfxInput-root {
    display: none;
  }

  .FIE_text-tool-options {
    .sc-qvjmv1-1 {
      display: none;
    }
  }
  // .sc-qvjmv1-1 {
  //   display: none;
  // }
  .sc-dwg14p-0 {
    .sc-dwg14p-1 {
      .iirfpj {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .sc-qvjmv1-0 {
    // display: none;
  }
  .kBJBhJ[aria-selected="true"] {
    background: #cdbfff !important;
  }

  .kBJBhJ:hover {
    background: #cdbfff !important;
  }
  .cSszUr[aria-selected="true"] {
    background: #cdbfff !important;
  }
  .cSszUr:hover {
    background: #cdbfff !important;
  }
  .epDskT {
    background-color: #6842ef !important;
  }
  .oWOcv {
    background-color: #6842ef !important;
  }
.FIE_tools-bar-wrapper{
  height: 104px;
  display: flex
;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
  .sc-21g986-0 {
    // justify-content: center;
    // gap: 30px;
  }
  // .sc-m9ezm7-0 {
  //   position: fixed;
  //   bottom: 25px;
  //   right: 44%;
  // }
  .sc-dwg14p-1 {
    padding: 0px;
    // padding-bottom: 30px;
  }
  

  .sc-qvjmv1-1 {
    div:nth-child(1),
    div:nth-child(3) {
      display: none;
    }
  }
  .sc-dwg14p-1 {
    ul li:nth-child(2),
    ul li:nth-child(3),
    ul li:nth-child(4),
    ul li:nth-child(5) {
      display: none;
    }

    ul {
      li {
        label {
          margin-bottom: 0px;
        }
      }
    }
  }

}

.ejsoKn {
  background-color: #6842ef !important;
  transition: all 0.3s;
  &:hover{
    background-color: #5335bf !important;
  }
}
.SfxModalTitle-Icon{
  background: transparent !important;
  position: relative;
  // svg{
  //   path{fill: #6842ef !important};
  // }
}
.sc-l3tf49-0.cdLlTF.SfxModalTitle-Icon svg {
  display: none; /* Hides the existing SVG */
}

.sc-l3tf49-0.cdLlTF.SfxModalTitle-Icon::before {
  content: ''; /* Placeholder for the custom icon */
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url('../../../assets/images/alert-circle-outline.svg'); /* Path to your custom icon */
  background-size: contain; /* Ensures the icon fits the container */
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -46%);
}

.ir-chat-content-multi-img-main-cont{
  display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;
}
.ir-chat-selected-multi-img-cont{
  display: flex;
  width: 90%;
      // height: 100%;
      max-height: 70%;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: #DEDEDE;
      /* margin: 0 50px; */
      margin: 0 auto;
  .ir-chat-selected-multi-img{
    // width: 80%;
    // max-width: 100%;
    // max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.ir-chat-mulit-image-box img {

  width: 100%;
  height: 100%;
  object-fit: cover;
}
// .ir-chat-multiple-image-cont::-webkit-scrollbar {
//   width: 12px; /* Increase the width of the scrollbar */
//   height: 12px; /* For horizontal scrollbar, if needed */
// }
// .ir-synopsis-sidebar {
//     position: absolute;
//     top: -100%;
//     left: 0;
//     width: 100%;
//     height: 50%;
//     background-color: #EDEDED;
//     color: #000;
//     padding: 20px;
//     overflow-y: auto;
//     z-index: 9999;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
//     transition: top .5s ease-in-out;
//   }

//   .ir-synopsis-sidebar.show {
//     top:65px;
//   }
.ir-share-container{
position: relative;
}
.ir-chat-record-time-status{
  position: absolute;
  bottom: -20px;
  /* text-align: center; */
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 24px;
}
.ir-chat-recorded-video-cont{
  display: flex
  ;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      video{
        width: 90%;
        border-radius: 10px;
      }
}
.recording-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 40px;
}

.recording-button{

  border: 1px solid #FF0000;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
}

.blinking-dot {
  width: 15px;
  height: 15px;
  background-color: #FF0000;
  border-radius: 50%;
  animation: blink 1s infinite ease-in-out;
}

/* Blinking effect */
@keyframes blink {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1);
  }
}
.ir-chat-video-crop-cont{
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background: #cdbfff;
  color: #fff;
  transition: all 0.3s;
  font-size: 18px;
  display: flex
;
  align-items: center;
  justify-content: center;
  /* line-height: normal; */
  line-height: 1;
  position: absolute;
  top: 10px;
  right: 40px;
  cursor: pointer;
  &:hover{
    background: #6842EF;
    line-height: normal;
  }
}

.ir-chat-crop-processing-text{
  text-align: center;
  margin-top: 30px;
}

.ir-synopsis-sidebar {

  width: 100%;

  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
margin-top: 30px;
color: #424242;
}

.ir-synopsis-sidebar.show {
  // top: 65px;
  // opacity: 1;
}

.ir-synopsis-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ir-synopsis-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.ir-synopsis-close-btn {
  // background-color: #1f1f1f;
  padding: 8px 12px;
  font-weight: 600;
  border: none;
  color: #000;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.ir-synopsis-body {
  flex-grow: 1;
}

.ir-synopsis-body h6 {
  font-size: 1.1rem;
  font-weight: bold;
}

.ir-synopsis-body p {
  font-size: 1rem;
  line-height: 1.5;
}
.ir-ws-chat-recorded-video {
  margin-top: 0px !important;
}

.bg-trns{
    // background-color: transparent !important;
    // border: 1px solid #737c84;
    border-radius: 4px;
    margin-bottom: 10px;
}

.ir-wb-main-cont{
  width: 100%;
  height: 100% ;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAlQTFRFAAAAAAAAAAAAg2PpwAAAAAN0Uk5TAClSO1Vr7QAAABdJREFUeJxjYBgFNAApKLwVeORGAdkAAO/bAXHvkhdXAAAAAElFTkSuQmCC);
background-repeat: repeat;
background-color: #fff;
}

.reply-preview {
    // border-left: 4px solid #007bff;
    padding: 10px;
    background-color: #dcd8eb;
    // margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .reply-content {
      flex: 1;

      p {
        margin: 0;
        font-size: 13px;
      }
    }

    .cancel-reply {
      background: none;
      border: none;
    //   color: #dc3545;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
    }
  }

.wb-apc{
    margin-left: 20px;
    margin-top: 10px;
}

.ans-privacy-upoadtn-con{
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // margin-top: 28px;
    // // margin: 0 auto;
    // justify-content: center;
    // gap: 10px;
}

.r-5rem{
    right: 6.5rem !important;
}


.custom-checkbox{
    accent-color: #6842ef;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
}


.poll-preview{
    span{
        font-size: 14px;
    }
    i{
        font-size: 14px;
    }
}


.answer-privacy-toggle-icon{
    margin-left: 14px;

}
.w-60{
    width: 90px !important;
}

.ans-privacy-checkbox{
    width: 40px;
}

.ir-ws-chat-title-main-cont{
  display: flex;
  align-items: center;
  // justify-content: center;
  gap:10px;
  width: 100%;
}
.ir-chat-bg-btn{
width: 30px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
font-size: 14px;
  background: #cdbfff;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover{
    background: #6842ef;
  }
  
}

.ir-chat-search-input-ph{
  border: none;
outline: none;
padding: 0 8px;
font-size: 14px;
border-radius: 10px;
background-color: #cdbfff;
height: 30px;
}

.ir-ws-wb-upload-modal{
height: 70%;
}


.epr_-3yva2a{
  display: none !important;
}
@media (max-width: 768px) {

  .ir-chat-emoji-picker aside {
    width: 250px !important;
    height: 350px !important;
  }
  .ir-audio-container audio{
    width: 200px;
  }
  .ir-chat-poll {
    width: 200px;
    padding: 10px;
  }
.ir-chat-menu-container .ir-chat-emoji-picker {

  top: 20px;
  left: -150px;
}
  .epr_346qu6{
    display: none !important;
  }
.ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont {
padding: 8px;
}

.ir-chat-top-head-view-more i {
  width: 30px;
  height: 30px;
  font-size: 14px;}

  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont h2 {
    font-size: 14px;
  }

  .ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont {
    height: 46px;
    padding: 8px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont .ir-ws-chat-question-text-cont {
   margin-top: 0px;
  }
  .ir-chat-conversation {
    overflow-x: hidden;
  }

.ir-chat-topbar {
padding: 10px;
}
.app-content-footer {
  padding: 10px;
}
.ir-chat-top-chevron {
line-height: 1.8;
}

.ir-chat-conversation {

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.ir-chat-conversation::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont .ir-chat-questtion-pagination-cont {
  bottom: 0px;
}

.up-chevron {
  bottom: 0px;
}

.ir-chat-topbar .ir-chat-top-header .ir-top-chat-bar-question-line {
  margin: 0 8px;
}


.ir-chat-menu-dropdown .ir-chat-menu-item i {
  font-size: 14px;
}
.ir-chat-view-more-modal-container {
  width: 95%;
}
.ir-chat-view-more-modal-title {
font-size: 16px;
}
.ir-chat-view-more-modal-user-list {
  height: 250px;
}
.ir-chat-view-more-modal-user-item {
  padding: 10px;
  font-size: 14px;
}
.ir-chat-menu-dropdown {
  width: 200px;
  padding: 4px 4px;
}
.ir-chat-menu-dropdown .ir-chat-menu-item {
  font-size: 12px;
  padding: 4px 6px;
}
.ir-chat-menu-dropdown .ir-chat-menu-item i {
  margin-right: 10px;
}
.search-overlay {
  right: 8px !important;
  width: 95% !important;
  top:57px !important;
}
.ir-ws-edit-img-upload-modal {
  height: auto;
}
.ir-chat-content-multi-img-main-cont {
  margin-top: 20px;
}
.ir-share-container-image-modal .ir-chat-multiple-image-cont {
  height: auto;
}

.ir-ws-clinical-note-input-field {
  width: 100%;
}
.ir-ws-video-trim-wrapper {
  padding: 0 10px;
}
.ir-ws-video-wrapper {
  padding: 0 10px;
}
.ir-chat-video-crop-cont {
  width: 30px;
  height: 30px;
  font-size: 14px;
  right: 20px;
}
.poll-popup {
  padding: 0px;
}
.poll-popup-content {
  width: 95%;
  max-width: 450px;
  max-height: 600px;
}
.create-question__popup {
  padding: 0px;
}
.create-question__popup-content {
  width: 95%;
  max-width: 450px;
  max-height: 600px;
}
.chat-header {
  max-width: 100%;
  right: 0px;
}
.chat-img-edit-btn {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.ir-ws-wb-upload-modal{
  height: 90%;
}
.ir-wbd-btn-main-container .ir-wbd-btn-container .ir-wbd-undo-redo-btn {
  width: 30px;
  height: 30px;
  span{font-size: 16px;}
}
.ir-wbd-btn-main-container .ir-wbd-btn-container .ir-wbd-redo-undo-line {
  height: 30px;
}
.ir-wbd-btn-main-container .ir-clear-screen {
  margin-left: 10px;
  width: 125px;
  font-size: 14px;
  padding: 10px;
}
.ir-wbd-upload-btn-container {
  bottom: 25px;
}
.ir-wbd-upload-btn-container button {
  width: 30px;
  height: 30px;
  font-size: 14px;
  span{
    font-size: 14px;
  }
}
}


@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ir-chat-topbar {
    padding: 8px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont {
    padding: 8px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont h2 {
    font-size: 14px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont .ir-chat-top-head-invite i {
    width: 26px;
    height: 26px;
    font-size: 12px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont .ir-chat-top-head-invite {
    width: 26px;
    height: 26px;
  }
  .ir-chat-menu-dropdown {
    margin: 2px 0;
    padding: 2px 0;
    width: 160px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont {
    padding: 8px;
    height: 42px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont .ir-ws-chat-question-text-cont {
    margin-top: 0px;
  }
  .ir-chat-conversation .chat-list .conversation-list .user-chat-content .ctext-content {
    font-size: 10px;
  }
  .ir-chat-conversation .chat-list {
    max-width: 50%;
  }
.ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont .ir-ws-chat-question-text-cont .ir-ws-chat-text-box .ir-chat-q-text {
  font-size: 16px;
}
  .ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont .ir-ws-chat-question-text-cont .ir-ws-chat-text-box .ir-chat-main-ques-text {
    font-size: 12px;
  }
  .ir-chat-menu-dropdown .ir-chat-menu-item {
    font-size: 10px;
  }
  .ir-chat-top-head-view-more i {   
     width: 26px;
    height: 26px;
    font-size: 12px;}

    
.app-content-footer {
  padding: 8px;
}
.app-content-footer .input-wrapper .app-content-footer-buttons {
  width: 26px;
  height: 26px;
}
.app-content-footer .input-wrapper .app-content-footer-buttons .bi-paperclip {
  font-size: 12px;
}
.app-content-footer .input-wrapper .chat-text-area {
  height: 36px !important;
  min-height: 36px !important;
  line-height: 24px;
  font-size: 12px;
  padding: 4px 38px;
}
.ir-ws-question-img-main-cont .ir-ws-chat-questio-img-cont {
  width: 150px;
  height: 150px;
}
.ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont.open {
  height: 200px;
}
.ir-chat-top-chevron {
  font-size: 16px;
}
.app-sidebar .ir-create-case-btn {
  height: 36px !important;
  font-size: 12px;
}
.app-content-footer .input-wrapper .ir-ws-add-attachment-main-container .add-attach-menu-item {
  font-size: 10px;
}
.app-content-footer .input-wrapper .ir-ws-add-attachment-main-container {
  width: 140px;
}
.app-content-footer .input-wrapper .app-content-footer-send {
  height: 26px;
  font-size: 12px;
  width: 70px !important;
  right: 40px;
}
.ir-chat-conversation .chat-list .conversation-list .ir-chat-profile-dp-container {
  width: 26px;
  height: 26px;
  left: -26px !important;

}
.ir-chat-conversation .chat-list .conversation-list .ir-chat-profile-dp {
  width: 26px;
    height: 26px;
    font-size: 10px;
    line-height: 24px;
}
.privacy-options-dropdown{

  padding: 8px !important; 
  width: 120px;
  bottom: 40px;

  label{
    gap: 30px !important;
    margin-bottom: 0px;
    
  }
}
.app-content-footer .input-wrapper .app-content-footer-voice {
  width: 26px;
  height: 26px;
  font-size: 12px;
}
.ir-share-container-image-modal .ir-chat-multiple-image-cont {
  height: 110px;
}
.ir-share-container-image-modal .ir-chat-mulit-image-box {
  width: 70px;
  height: 70px;
}
.ir-chat-selected-multi-img-cont {
  height: 100%;
}
.ir-ws-clinical-note-input-field {
  padding: 8px;
  font-size: 12px;
}
.ir-share-container-image-modal .ir-chat-cont-img-input-cont .ir-chat-cont-msg-send-btn {
  font-size: 12px;
  padding: 8px 16px;
}

.ir-ws-sign-popup-container .ir-ws-sign-popup-inner-container .ir-ws-signup-content-title {
  font-size: 20px;
}
.ir-ws-sign-popup-container .ir-ws-sign-popup-inner-container {
  width: 70%;
}
.ir-wbd-list .ir-wbd-list-item input[type=radio] + span {
  font-size: 14px;
  padding: 8px;
}
.ir-wbd-btn-main-container .ir-wbd-btn-container .ir-wbd-undo-redo-btn {
  width: 26px;
  height: 26px;
}
.ir-wbd-btn-main-container .ir-wbd-btn-container .ir-wbd-undo-redo-btn span {
  font-size: 16px;
}
.ir-wbd-btn-main-container .ir-clear-screen {
  margin-left: 10px;
  width: 125px;
  font-size: 14px;
  padding: 8px;
}
.ir-wbd-upload-btn-container button span {
  font-size: 14px;
}
.ir-wbd-btn-main-container .ir-wbd-btn-container .ir-wbd-redo-undo-line {
  height: 26px;
}
.ir-wbd-upload-btn-container button {
  width: 26px;
  height: 26px;
}
.ir-wbd-upload-btn-container {
  bottom: 24px;
}
.poll-heading {
  font-size: 14px;
}
.poll-question-label {
  font-size: 14px;
}
.poll-question-input{
  font-size: 12px;
}
.poll-option-input{
  font-size: 12px;
}
.create-question__popup-title {
  font-size: 14px;
}
.create-question__textarea {
  font-size: 12px;
}
.create-question__checkbox-container .create-question__checkbox-label {
  font-size: 12px;
}
.create-question__file-input-container .create-question__file-label {
  font-size: 12px;
}
.iks-mint-cust-modal.ir-chat-inside-modal-container.ir-image-preview-main-modal .iks-mint-modal-container .ir-image-large-thumb-title-container .ir-image-head-thumb-right button {
  width: 26px !important;
  height: 26px !important;
  font-size: 12px !important;
}
.chat-img-edit-btn {
  width: 26px !important;
  height: 26px !important;
  font-size: 12px !important;
}
.ir-carousel-action-btns {
  width: 26px !important;
  height: 26px !important;
 
}
.ir-carousel-action-btns i {
  font-size: 12px !important;
}
.ir-chat-menu-container .ir-chat-menu-options .ir-chat-menu-option {
  font-size: 10px;
  padding: 8px;
}
.ir-chat-menu-options.sender-menu {
  left: -115px;
  top: 22px;
}
.ir-chat-emoji-picker aside {
  width: 250px !important;
}
.ir-chat-emoji-picker.sender-picker {
  left: -250px;
  top: -5px;
}
.reactions-popup.sender-reaction-con {
  padding: 8px;
}
.reaction-detail {
  margin-bottom: 0px;
  font-size: 10px;
}
.filter-button {
  width: 26px;
  height: 26px;
  padding: 0;
  font-size: 10px;
}
.reactions-popup {
  width: 200px;
  right: 135px !important;

}
.reaction-user-image {
  width: 26px;
  height: 26px;
}
}

@media (min-width: 1280px) {
  .poll-popup-content {
    height: 630px;
  }
  .create-question__popup-content {
    height: 85%;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont {
    padding: 8px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont .ir-chat-top-head-invite i {
    width: 32px;
    height: 32px;
    font-size: 12px;
  }
  .ir-chat-top-head-view-more i {
    width: 32px;
    height: 32px;
    font-size: 12px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont .ir-chat-top-head-invite {
    width: 32px;
    height: 32px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont h2 {
    font-size: 12px;
  }
  
.ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont {
  padding: 8px;
}
.ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont {
  height: 48px;
}
.ir-chat-top-chevron {
  font-size: 16px;
}
.ir-synopsis-title {
  font-size: 16px;
}
.ir-synopsis-body h6 {
  font-size: 14px;
}
.ir-synopsis-body p {
  font-size: 12px;
}
.ir-chat-menu-dropdown .ir-chat-menu-item {
  font-size: 12px;
}
.ir-chat-menu-dropdown .ir-chat-menu-item i {
  font-size: 12px;
}
.ir-chat-menu-dropdown {
  width: 190px;
}
.ir-chat-conversation .chat-list .conversation-list .user-chat-content .ctext-content {
  font-size: 10px;
}
.ir-chat-conversation .chat-list .conversation-list .ir-chat-conv-info-container .ir-chat-conv-user-details .ir-chat-conv-name {
  font-size: 10px;
}
.ir-chat-poll .poll-question {
  font-size: 12px;
}
.ir-chat-poll {
  padding: 8px;
}
.ir-chat-poll .poll-options .poll-option {
  padding: 4px 0px;
}
.ir-chat-poll .poll-options .poll-option .poll-label {
  font-size: 12px;
}
.app-content-footer .input-wrapper .app-content-footer-buttons {
  width: 32px;
  height: 32px;
}
.app-content-footer .input-wrapper .app-content-footer-buttons .bi-paperclip {
  font-size: 12px;
}
.app-content-footer .input-wrapper .chat-text-area {
  font-size: 12px;
}
.app-content-footer .input-wrapper .app-content-footer-send {
  font-size: 12px;
  height: 32px;
}
.app-content-footer .input-wrapper .app-content-footer-voice {
  width: 32px;
  height: 32px;
  font-size: 12px;
}
.app-content-footer .input-wrapper .chat-text-area {
  height: 46px !important;
  min-height: 46px !important;
  line-height: 13px;

}
.app-content-footer .input-wrapper .ir-ws-add-attachment-main-container .add-attach-menu-item i {
  font-size: 12px;
}
.app-content-footer .input-wrapper .ir-ws-add-attachment-main-container .add-attach-menu-item {
  font-size: 10px;
}
.app-content-footer .input-wrapper .ir-ws-add-attachment-main-container {
  width: 150px;
}
.ir-chat-menu-container .ir-chat-menu-options .ir-chat-menu-option {
  // padding: 4px 8px;
  font-size: 10px;
}
.ir-chat-poll .poll-footer .poll-view-votes {
  font-size: 12px;
}
.ir-chat-content-multi-img-main-cont {
  gap: 10px;
}
.ir-ws-clinical-note-input-field {
  padding: 8px;
  font-size: 12px;
}
.ir-share-container-image-modal .ir-chat-cont-img-input-cont .ir-chat-cont-msg-send-btn {
  padding: 8px 20px;
  font-size: 12px;
}
.ir-share-container-image-modal .ir-chat-mulit-image-box {
  width: 95px;
  height: 95px;
}
}

@media (min-width: 1600px) {
  .ir-share-container-image-modal .ir-chat-mulit-image-box {
    width: 100px;
    height: 100px;
  }
  .ir-chat-content-multi-img-main-cont {
    gap: 20px;
  }
  .ir-ws-clinical-note-input-field {
    padding: 10px;
    font-size: 14px;
  }
  .ir-share-container-image-modal .ir-chat-cont-img-input-cont .ir-chat-cont-msg-send-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  .ir-chat-menu-container .ir-chat-menu-options .ir-chat-menu-option {
    // padding: 4px 8px;
    font-size: 14px;
  }
  .ir-chat-poll .poll-footer .poll-view-votes {
    font-size: 14px;
  }
  .app-content-footer .input-wrapper .ir-ws-add-attachment-main-container {
    width: 200px;
  }
  .app-content-footer .input-wrapper .ir-ws-add-attachment-main-container .add-attach-menu-item i {
    font-size: 16px;
  }
  .app-content-footer .input-wrapper .ir-ws-add-attachment-main-container .add-attach-menu-item {
    font-size: 14px;
  }
  .app-content-footer .input-wrapper .chat-text-area {
    height: 50px !important;
    min-height: 50px !important;
  }
  .app-content-footer .input-wrapper .app-content-footer-voice {
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
  .app-content-footer .input-wrapper .app-content-footer-send {
    font-size: 16px;
    height: 36px;
  }
  .app-content-footer .input-wrapper .chat-text-area {
    font-size: 14px;
  }
  .app-content-footer .input-wrapper .app-content-footer-buttons {
    width: 36px;
    height: 36px;
  }
  .app-content-footer .input-wrapper .app-content-footer-buttons .bi-paperclip {
    font-size: 18px;
  }
  .ir-chat-poll .poll-options .poll-option {
    padding: 8px 0px;
  }
  .ir-chat-poll .poll-options .poll-option .poll-label {
    font-size: 14px;
  }
  .ir-chat-poll {
    padding: 14px;
  }
  .ir-chat-poll .poll-question {
    font-size: 14px;
  }
  .ir-chat-conversation .chat-list .conversation-list .ir-chat-conv-info-container .ir-chat-conv-user-details .ir-chat-conv-name {
    font-size: 12px;
  }
  .ir-chat-conversation .chat-list .conversation-list .user-chat-content .ctext-content {
    font-size: 12px;
  }
  .ir-chat-menu-dropdown {
    width: 222px;
  }
  .ir-chat-menu-dropdown .ir-chat-menu-item i {
    font-size: 18px;
  }
  .ir-synopsis-body p {
    font-size: 16px;
  }
  .ir-synopsis-body h6 {
    font-size: 18px;
  }
  .ir-synopsis-title {
    font-size: 24px;
  }
  .ir-chat-top-chevron {
    font-size: 20px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont {
    height: 56px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-top-chat-question-cont {
    padding: 8px 20px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont h2 {
    font-size: 18px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont {
    padding: 8px 20px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont .ir-chat-top-head-invite {
    width: 40px;
    height: 40px;
  }
  .ir-chat-topbar .ir-chat-top-header .ir-chat-top-header-main-cont .ir-chat-top-head-invite i {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .ir-chat-top-head-view-more i {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .poll-popup-content {
    // height: 70%;
    height: 630px;
  }
  .create-question__popup-content {
    height: 60%;
  }
  .h70{
    height: 80%;
  }
}
