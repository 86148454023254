.ir-ws-case-container-question {
  margin-left: 300px;
}
.ir-ws-case-option {
  width: 1%;
}

.ir-ws-fill-in-input {
  width: 10%;
  margin-bottom: 10px;
}
.ir-ws-match-the-followings {
  display: flex;
}
.question-container {
  margin-bottom: 20px;
}

.ir-ws-match-following-option {
  display: flex;
}

.ir-ws-pair-input-container {
  display: flex;
  input {
    width: 10%;
  }
}
.ir-ws-pair-delte-box {
  display: flex;
  gap: 10px;
  margin-block: 2px;
}

.ir-ws-case-submit-answer {
  border: none;
  padding: 15px 30px;
  background: rgb(176, 66, 255);
}
.ir-ws-preview-case-cont {
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
}
.ir-ws-preview-case-title-cont {
  text-align: center;
  margin: 40px 0;
}
.abnormality-result-msg-container {
  font-size: 20px;
  margin: 8px 0;
}

.jumbling-option-cont {
  padding: 10px 0;
  margin: 5px;
  width: 150px;
  // background: #2f0a97;
  background: #ffffff;
  text-align: center;
  border-radius: 5px;
  color: #6842ef;
  font-weight: 500;
  @media (max-width: 720px) {
    width: 130px;
    font-size: 16px;
  }
}

.jumbling-option-cont-submit-btn {
  padding: 10px 0;
  margin: 5px;
  width: 310px;
  background: #6842ef;
  text-align: center;
  border-radius: 5px;
  color: #ffff;
  outline: none;
  text-transform: capitalize;
  font-weight: 500;

  border: none;
  transition: all 0.3s;
  &:hover {
    // background: #6842ef;
  }
}
.jumbling-option-cont-submit-btn:disabled {
  cursor: not-allowed;
}

.ir-ws-case-dropdown-active-cont {
  position: absolute;
  left: 90px;

  top: 3px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  gap: 5px;
  @media (max-width: 720px) {
    top: 0;
    left: 80px;
  }
  li {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #e5e5e5;
    border-radius: 10px;

    color: #6842ef;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #6842ef;
      color: #ffffff;
    }
    &.active {
      background-color: #6842ef;
      color: #ffffff;
    }
  }
}
