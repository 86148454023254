.ir-chat-admin-content-main-container {
    width: 100%;

    .ir-ct-admn-cntnt-inner-container {
        height: 100%;
        padding: 50px;
        position: relative;

        h2 {
            font-size: 30px;
            font-family: Poppins-Bold;
        }

        .ir-ct-admn-cntnt-in-container {
            height: calc(100% - 50px);
        }
        
        .ir-chat-rooms-tab-admin-container {
            height: 100%;
            padding: 20px 0;
        }

        .tab-content,
        .tab-pane {
            height: 100%;
        }
    }
}

span.ir-custom-tab-header {
    display: inline-table;
    padding: 15px 50px;
    width: 250px;
    text-align: center;
    font-family: Poppins-Bold;
    font-size: 18px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;

    &.ir-active-custom-tab {
        background: #dcdfe3;
    }
}
.ir-customtabs-main-container {
    .ir-customa-tabs-link {
        display: inline-block;
        padding: 15px 20px;
        text-decoration: none;
        margin-right: 15px;
        color: #000;
        text-align: center;
        font-family: Poppins-Bold;
        font-size: 18px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        cursor: pointer;
        min-width: 250px;

        &.activeTab {
            background: rgba(220, 223, 227, 0.75);
        }
    }
}

.ir-customtabs-child-container {
    padding: 30px 50px;
    background: rgba(220, 223, 227, 0.75);
    height: 100%;
    overflow-y: scroll;
    position: relative;

    .ir-found-no-results-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 20px;
        font-weight: bold;
    }
}


.ir-checkbox {
    display: flex;
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    label {
        position: relative;
        cursor: pointer;
        margin: 0;
        font-family: Poppins-Regular;
        font-size: 12px;
        color: #5c5c5c;

        &:before {
            content: "";
            --webkit-appearance: none;
            background-color: transparent;
            border: 2px solid #6842ef;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 6px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            border-radius: 3px;
        }
    }

    input:checked + label:before {
        background-color: #6842ef;
    }

    input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}
.ir-chat-admin-tab-in-list-container {
    padding: 0;
    list-style: none;
    width: 100%;
    .ir-chat-admin-tab-in-list-item-container {
        border-bottom: 1px solid rgba($color: #5c5c5c, $alpha: 0.2);

        &:nth-child(odd) {
            background-color: #f0f0f0;
        }

        .ir-chat-admin-tab-in-list-item {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;

            .ir-chat-admin-tab-in-list-item-check-container {
                width: 55px;
            }
            
            .ir-chat-admin-tab-in-list-item-more-width-container {
                width: 50%;
            }

            .ir-chat-admin-tab-in-list-actions-container {
                display: flex;
                width: 40%;
                justify-content: end;
            }

            .ir-chat-admin-tab-in-list-item-content-container {
                padding: 15px;
                vertical-align: middle;

                &.ir-chat-admin-tab-in-list-item-img-container {
                    p {
                        background-color: #47bc94;
                        border-radius: 50%;
                        border: 1px solid #fff;
                        color: #ffffff;
                        font-family: Poppins-Bold;
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        font-size: 15px;
                        margin: 0;
                        line-height: 35px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
                button {
                    color: #fff;
                    border: 0;
                    background-color: #31589b;
                    font-size: 13px;
                    padding: 10px 15px;
                    border-radius: 8px;
                    min-width: 125px;
                    width: max-content;

                    &:hover {
                        background-color: #6842ef;
                    }
                }
            }
        }
    }

    .expandable {
        box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
        padding: 50px;
        display: flex;
        // align-items: center;

        .ir-ad-tr-user-profile-container {
            margin-right: 50px;
            p {
                background-color: #47bc94;
                border-radius: 50%;
                border: 1px solid #fff;
                color: #ffffff;
                font-family: Poppins-Bold;
                text-transform: uppercase;
                display: inline-block;
                width: 125px;
                height: 125px;
                font-size: 30px;
                margin: 0;
                line-height: 120px;
                text-align: center;
            }

            img {
                width: 125px;
                height: 125px;
                border-radius: 50%;
            }
        }

        .ir-ad-user-details-container {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .ir-ad-user-details-title {
                font-family: Poppins-Medium;
                
            }
        }

    }
}

.ir-chat-admin-inside-tab-container {
    display: flex;
    h4 {
        margin-top: 15px;
        margin-bottom: 25px;
    }
    .ir-ad-profile-pic-main-container { 
        .avatar-lg {
            width: 125px;
            height: 125px;
            border-radius: 50%;
        }
    }
    
    .ir-ad-profile-details-container {
        padding-left: 5rem;
        width: 100%;
        .ir-set-prof-content-container {
            margin-bottom: 20px;

            .ir-set-prof-info-container {
                display: flex;

                .ir-set-prof-info-title-container {
                    width: 200px;
                }
                .ir-set-prof-info-content-container {
                    background-color: transparent;
                    border-bottom: 1px solid #47bd94;
                }
            }

            .ir-set-prof-info-icon-container i {
                font-size: 14px;
            }
        }
    }
}