.ir-ws-hme-team-main-container {
  padding-top: 150px;

  .ir-ws-hme-highlight-text {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 24px;
  }
  .ir-ws-hme-prvw-title {
    text-align: center;
    margin-bottom: 30px;
  }

  .ir-ws-hme-title-content-para {
    max-width: 685px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
    margin-bottom: 50px;
  }

  .ir-ws-hme-team-inner-container {
    justify-content: space-between;

    .ir-ws-hme-team-division-container {
      .ir-ws-card-main-container {
        padding: 0;

        .ir-ws-team-card-thumbnail-container {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
        }

        .ir-ws-card-body {
          padding: 30px;

          .ir-ws-hme-team-card-title {
            margin: 0;
            padding-bottom: 12px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: 0.1px;
          }

          .ir-ws-hme-team-designation {
            margin: 0;
            padding-bottom: 15px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.2px;
          }

          .ir-ws-hme-team-social-container {
            justify-content: center;

            .ir-ws-hme-team-social-icon-container {
              margin-right: 20px;

              .ir-ws-hme-team-icon {
                font-size: 25px;
                color: gray;

                &:hover {
                  color: #6842ef;
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .ir-ws-hme-team-content-card-container {
      margin-top: 50px;
      h4 {
        font-size: 22px;
        font-weight: 600;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        text-align: center;

        &::after {
          position: absolute;
          content: "";
          width: 75px;
          bottom: 0;
          left: 45%;
          height: 5px;
          background-color: #6842ef;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }

  .ir-ws-hme-team-btn-container {
    margin-top: 75px;
    text-align: center;
    .ir-ws-hme-team-btn {
      width: 320px;
      border-radius: 5px;
      padding: 15px 40px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.2px;
      position: relative;
      background-size: 400%;
      height: 52px;
      background-size: 400%;
      color: #fff;
      border: none;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        width: 100%;
        height: inherit;
        border-radius: inherit;
        background: rgb(43, 167, 255);
        background: linear-gradient(
          90deg,
          rgba(43, 167, 255, 1) 0%,
          rgba(104, 66, 239, 1) 100%
        );
        transition: all 0.8s;
      }

      &:hover::before {
        transform: scaleX(1);
      }

      span {
        position: relative;
        z-index: 1;
      }
    }
  }
}

@media (min-width: 991px) {
  .ir-ws-hme-team-main-container {
    .ir-ws-hme-prvw-title {
      text-align: left;
    }
    .ir-ws-hme-team-inner-container {
      display: flex;

      .ir-ws-hme-team-division-container {
        padding-right: 50px;
        width: 50%;
      }

      .ir-ws-hme-team-content-card-container {
        margin-top: 0;
        width: 50%;

        h4 {
          text-align: left;

          &::after {
            left: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .ir-ws-hme-team-main-container {
    .ir-ws-hme-team-inner-container {
      .ir-ws-hme-team-division-container {
        width: 33%;
      }

      .ir-ws-hme-team-content-card-container {
        width: 68%;
      }
    }
  }
}
