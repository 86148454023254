.ir-ws-create-case-main-container {
  margin-top: 1rem;
  color: #424242;
  width: 75%;
  .ir-ws-create-case-question-text-box {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    position: absolute;
    top: 60px;
    right: 26.5%;
    .ir-ws-create-case-question-text {
      margin: 0;
      font-size: 1rem;
      font-weight: 700;
      color: #424242;
    }
  }
  .ir-ws-create-case-all-questions-container {
    // border: 1px solid #b3b3b3;
    width: 100%;
    border-radius: 16px;
    // background-color: #e0e0e0;
    .ir-ws-create-case-all-questions-box {
      // padding: 25px;
      .ir-ws-multiple-choice-question-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
        .ir-ws-multiple-choice-question-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            width: 96.5%;
          }
        }
        .ir-ws-create-course-mcq-option-container {
          display: flex;
          width: 96.5%;
          align-items: center;
          justify-content: space-between;
          input {
            width: 32%;
          }
        }
        .ir-ws-create-course-mcq-answer-container {
          width: 96.5%;
          border-bottom: 1px solid #b3b3b3;
          padding-bottom: 25px;
          input {
            width: 32%;
          }
        }
      }
    }
  }
}
.ir-ws-create-case-types-of-question-container {
  .ir-ws-create-case-types-of-question-add-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ir-ws-create-case-all-types-question-box {
      padding: 15px 15px 15px 15px;
      background-color: #ffffff;
      width: 96.5%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border: 1px solid #b3b3b3;
      cursor: pointer;
      p {
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
        color: #424242;
      }
      .ir-ws-create-case-all-types-question-dropdown {
        width: 24px;
        cursor: pointer;
      }
    }
  }

  .ir-ws-create-case-types-of-question-list {
    // display: flex;
    // justify-content: end;
    width: 96.5%;
    overflow: hidden;

    ul {
      margin: 0;
      margin-top: 10px;
      list-style: none;
      padding: 0;
      width: 50%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // display: flex;
      // flex-direction: column;
      gap: 10px;
      transition: transform 0.3s ease, opacity 0.3s ease; /* Add transition properties */
      transform: translateY(
        -100%
      ); /* Initially move the dropdown above the container */
      opacity: 0; /* Initially hide the dropdown */

      &.show {
        transform: translateY(
          0
        ); /* Move the dropdown to its original position */
        opacity: 1;
        width: 100%; /* Show the dropdown */
      }
      // &.hide {
      //   display: none;
      // }
      li {
        padding: 15px;
        background-color: #fff;
        border-radius: 10px;
        font-weight: 500;
        border: 1px solid #b3b3b3;
        cursor: pointer;
        color: #424242;
        transition: all 0.3s;
        &:hover {
          border: 1px solid #6842ef;
        }
      }
    }
  }
  // }
}
.ir-ws-create-course-input-tag {
  padding: 15px 50px 15px 15px;
  border-radius: 10px;
  font-size: 1rem;
  border: 1px solid #b3b3b3;
  outline: none;
  &:focus {
    outline: 1px solid #6842ef;
  }
}

.ir-ws-creat-case-icon {
  width: 30px;
  fill: #424242;
  cursor: pointer;
  &:hover {
    fill: #6842ef;
  }
}

.fill-in-the-blanks-border {
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 25px;
}

.ir-ws-rapid-question-time-add-btn-container {
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 25px;
  .ir-ws-add-question-button {
    .ir-ws-add-question-rapid-btn {
      padding: 15px;
      background-color: #fff;
      margin: 0;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: #6842ef;
        color: #fff;
      }
    }
  }
}

.ir-ws-rapid-question-pair-container {
  width: 190px;
}

.ir-ws-create-case-image {
  display: none;
}

.ir-ws-create-case-select-image-container {
  width: 100px;
  height: 100px;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid #6842ef;
  }
  .ir-ws-create-case-image-icon {
    width: 50px;
  }
}
.ir-ws-create-case-upload-image-text {
  margin: 0;
}
.ir-ws-create-case-select-image-label {
  margin: 0;
}

.ir-ws-create-case-selected-image {
  width: 500px;
  //   height: 500px;
  // border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    // padding: 25px;
  }
}
.ir-ws-create-course-select-image-box {
  display: flex;
  width: 25%;
  align-items: center;
  // justify-content: space-between;
  gap: 10px;
}
.ir-ws-create-case-mark-answer-text {
  margin: 0;
  margin-bottom: 20px;
}

.ir-ws-creat-case-delete-selected-image {
  width: 30px;
  position: absolute;
  right: -40px;
  top: 0;
  fill: #424242;
  cursor: pointer;
  &:hover {
    fill: #6842ef;
  }
}
.ir-ws-creat-case-edit-selected-image {
  width: 23px;
  position: absolute;
  right: -40px;
  top: 40px;
  cursor: pointer;
  &:hover {
    fill: #6842ef;
  }
}

.ir-ws-create-case-slide-container {
  margin-bottom: 20px;
}

.ir-ws-create-case-pagination-slide-container {
  margin-top: 100px;
}

.ir-ws-create-case-pagination-number-container {
  gap: 20px;
  .ir-ws-creat-case-page-active-slide-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #6842ef;
    border-radius: 50%;
  }

  p {
    margin: 0;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
  }
}
.ir-ws-creat-case-page-inactive-slide-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #d9d9d9;
  border-radius: 50%;
  p {
    margin: 0;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    color: #424242;
  }
}

.ir-ws-create-case-add-slide-container {
  // margin-top: 12px;
  display: flex;
  justify-content: space-between;
  .ir-ws-create-case-add-slide-btn-box {
    width: 96.5%;
    margin-top: 25px;
    .ir-ws-create-case-add-slide-btn {
      width: 100%;
    }
  }
}
.ir-ws-creat-case-publish-button-container {
  justify-content: space-between;
  margin: 9px 0 50px 0;
  width: 96.5%;
  gap: 10px;
}

.ir-ws-create-case-category-container {
  // margin-bottom: 25px;
}

.ir-ws-create-case-category-icon-container {
  width: 100px;
  height: 100px;
}

.ir-ws-create-case-category-list {
  width: 96.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  visibility: hidden;
  overflow-x: scroll;
  gap: 20px;
  // margin-bottom: 25px;
  .ir-ws-creat-case-category-icon-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 0;
      text-align: center;
      height: 50px;
    }
    .ir-ws-create-case-category-icon-container {
      background-color: #fff;
      border: 1px solid #b3b3b3;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 1px solid #6842ef;
        cursor: pointer;
      }
      .ir-ws-create-case-category-icon {
        width: 48px;
      }
    }
  }

  /* Animation for dropdown */
  &.active-dropdown {
    visibility: visible;
    .ir-ws-creat-case-category-icon-list {
      transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease; /* Add opacity transition */
      max-height: 1000px; /* Set a sufficiently large max-height */
      opacity: 1;
      // width: 200px;
    }
  }

  .ir-ws-creat-case-category-icon-list {
    transition: max-height 0.3s ease, opacity 0.3s ease; /* Add opacity transition */
    max-height: 0; /* Initially hide the dropdown */
    opacity: 0;
  }
}

.ir-ws-create-case-all-questions-box {
  .ir-ws-multiple-choice-question-container {
    .ir-ws-multiple-choice-question-box {
      .quill {
        background-color: #fff;
        // border: 1px solid #b3b3b3;
        border-radius: 10px;
        width: 96.5%;
        // padding: 8px;
        .ql-toolbar {
          display: flex;
          // justify-content: end;
          border: none;
          padding: 8px;
          border-top: 1px solid #b3b3b3;
          border-right: 1px solid #b3b3b3;
          border-left: 1px solid #b3b3b3;
        }
        .ql-container {
          border: none;
          background-color: #fff;
          // border-radius: 10px;
          border: 1px solid #b3b3b3;
          height: auto;
          .ql-editor {
            padding: 15px 20px 15px 20px;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.ir-ws-creat-case-icon-margin {
  margin-top: -50px;
}

.ir-ws-create-case-video-icon {
  width: 50px;
}
.ir-ws-create-case-selected-video {
  // width: 560px;
  width: 100%;
  padding: 25px;
}

.ir-ws-create-case-category-margin {
  margin-bottom: 20px;
}
.ir-ws-create-case-category-selected-icon {
  width: 24px;
  margin-right: 10px;
}

.ir-ws-create-case-all-questions-box {
  .ir-ws-multiple-choice-question-container {
    .ir-ws-create-case-description-container {
      .quill {
        .ql-container {
          height: 150px;
        }
      }
    }
  }
}

.ir-ws-rapid-question-time-add-btn-container {
  .ir-ws-create-case-add-option-container {
    width: 32%;
    margin: 0;
    .ir-ws-create-case-add-option-btn {
      width: 100%;
    }
  }
}

.ir-ws-multiple-choice-question-container {
  .ir-ws-rapid-question-time-add-btn-container {
    .ir-ws-create-case-set-time-container {
      width: 100%;
      gap: 10px;
      p {
        margin: 0;
        font-size: 1rem;
      }
    }
  }
}

.ir-ws-create-case-title-text {
  margin-block: 10px;
  color: #424242;
  font-weight: bold;
}

.ir-ws-create-case-bulk-image-container {
  // border: 1px solid red;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: auto;
  width: 96.5%;
  // margin-bottom: 25px;

  .ir-ws-create-case-bulk-image-box {
    position: relative;
    width: 150px;
    height: 130px;
    // border: 1px solid lime;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    img {
      width: 100%;
      padding: 5px;
      aspect-ratio: 3/2;
      object-fit: contain;
      background-color: #fff;
      border-radius: 5px;
    }
    .ir-ws-create-case-bulk-image-name {
      margin: 0;
    }
    .ir-ws-create-case-delete-bulk-image {
      position: absolute;
      right: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      p {
        margin: 0;
        font-size: 14px;
      }
      &:hover {
        background-color: #6842ef;
        p {
          color: #fff;
        }
      }
    }
  }
}
.ir-ws-create-case-sequence-image-container {
  width: 150px;
  height: 150px;
  position: relative;
  .ir-ws-create-case-sequence-image-border-one {
    width: 150px;
    height: 100px;
    background-color: #fff;
    border-radius: 5px;
    border: 3px solid #b3b3b3;
    // border: 1px solid red;
    position: absolute;
    // top: 10px;
    // left: 10px;
  }
  .ir-ws-create-case-sequence-image-border-two {
    position: absolute;
    width: 150px;
    height: 100px;
    background-color: #fff;
    border-radius: 5px;
    border: 3px solid #b3b3b3;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .ir-ws-create-case-sequence-image-border-three {
    position: absolute;
    width: 150px;
    height: 102px;
    background-color: #fff;
    border-radius: 5px;
    border: 3px solid #b3b3b3;
    // border: 1px solid lime;
    position: absolute;
    top: 20px;
    left: 20px;
    .ir-ws-create-case-sequence-image {
      width: 100%;
      // padding: 5px;
      aspect-ratio: 3/2;
      object-fit: contain;
      background-color: #fff;
      border-radius: 2px;
    }
  }
  .ir-ws-create-case-sequence-image-cross-box {
    position: absolute;
    top: 20px;
    right: -20px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    p {
      margin: 0;
      font-size: 14px;
    }
    &:hover {
      background-color: #6842ef;
      p {
        color: #fff;
      }
    }
  }
}

.ir-ws-create-case-selected-video-slide {
  // width: 560px;
  width: 100%;
  padding: 25px;
}

.ir-ws-create-case-input {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #b3b3b3;
  outline: none;
}
.ir-ws-create-case-price-input {
  width: 96.5%;
}

.ir-ws-creat-case-discussion-cont {
  width: 96%;
  margin-top: 30px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  .ir-ws-default-btn-container {
    margin-top: 0px;
  }
}

.ir-ws-case-mandatory-text {
  font-size: 16px;
  color: #e03131;
}

.ir-ws-mandatory-filed-border {
  border: 1px solid #e03131 !important;
}
.ir-ws-normal-filled-border {
  border: 1px solid #b3b3b3;
}
.ir-case-mandatory-field-icon {
  color: #e03131;
  margin: 0 5px;
}
.check-edit-image {
  position: absolute;
  right: -45px;
  top: 25px;
  cursor: pointer;
  color: #42ef73;
  font-size: 40px;
  margin-left: 10px;
}
.pencile-edit-icon {
  position: absolute;
  right: -40px;
  top: 34px;
  cursor: pointer;
  color: #424242;
  font-size: 25px;
  margin-left: 10px;
  &:hover {
    color: #6842ef;
  }
}
