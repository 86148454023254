.ir-chat-finance-tab-container {
    .expandable {
        width: 100%;
    } 
    .ir-finance-table-container {
        width: 100%;
        table {
            width: 100%;
        }
    }
}