.ir-ws-webinar-presenter-main-container {
  left: 0;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;

  .ir-ws-webinar-presenter-inner-main-container {
    height: 100vh;
    width: 100vw;

    .ir-ws-webinar-presenter-inner-sub-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      margin: 0;

      .ir-ws-webinar-presenter-header {
        padding: 15px 25px;
        .ir-ws-webinar-presenter-logo-container {
          width: 20%;
          .ir-ws-webinar-presenter-logo {
            width: 75px;
          }
        }
      }

      .ir-ws-webinar-presenter-webinar-name-text {
        font-weight: bold;
        text-align: center;
        font-size: 28px;
        width: 100%;
      }

      .ir-ws-webinar-presenter-logo-pack {
        width: 20%;
        display: flex;
        justify-content: flex-end;
      }
      .ir-ws-webinar-presenter-logo-pack-record-container {
        // margin: 8px 15px;
        margin-left: 30px;
        border-radius: 10px;
        border: 2px solid #6842ef;
        width: 65%;
        padding: 10px 15px;
        cursor: pointer;
        // justify-content: flex-end;
      }

      .ir-ws-webinar-presenter-recording-text {
        font-size: 14px;
        font-weight: 500;
        width: 100%;
      }
      .ir-ws-webinar-presenter-recorder-logo {
        padding: 5px;
        border: 1px solid #000;
        border-radius: 50%;
        margin-left: 15px;
        cursor: pointer;
        .ir-ws-webinar-presenter-recoredr-logo-red-btn {
          width: 15px;
          height: 15px;
          background-color: red;
          border: 0;
          border-radius: 50%;
        }
        .ir-ws-webinar-presenter-recoredr-logo-purple-btn {
          width: 15px;
          height: 15px;
          background-color: #6842ef;
          border: 0;
          border-radius: 50%;
        }
      }

      .ir-ws-webinar-presenter-main-screen-container {
        padding: 25px;
        overflow-y: auto;
        height: 100%;
        position: relative;
        .ir-ws-webinar-presenter-inner-sub-screen-container {
          height: 100%;
          display: flex;
          justify-content: space-between;

          .ir-ws-webinar-presenter-participants-box {
            height: 100%;
            width: 18%;
            background-color: #fff;
            // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            border: 1px solid #d5d5d5;
            .ir-ws-webinar-presenter-participants-number-container {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              border-top: 1px solid #d5d5d5;
              border-left: 1px solid #d5d5d5;
              border-right: 1px solid #d5d5d5;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 18px 15px;
              background: linear-gradient(
                90deg,
                rgb(75, 113, 247) 1%,
                rgb(96, 79, 241) 50%,
                rgb(104, 66, 239) 100%
              );

              p {
                color: #fff;
                font-weight: 500;
              }
            }

            .ir-ws-webinar-presenter-participants-container {
              height: 67vh;
              overflow-y: auto;

              .ir-ws-webinar-participants-occupations-container {
                display: flex;
                align-items: center;
                padding: 10px 15px;
                border-top: 1px solid #d5d5d5;
                background-color: #f6f7fb;
                // justify-content: space-between;

                .ir-ws-webinar-participants-user-logo {
                  width: 40px;
                  height: 40px;
                  background-color: #d5d5d5;
                  border-radius: 50%;
                  margin-right: 15px;
                }
                .ir-ws-webinar-participants-text {
                  font-size: 14px;
                  font-weight: 500;
                }

                .ir-ws-webinar-occupations-text {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }
          }

          .ir-ws-webinar-presenter-message-notes-container {
            width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .ir-ws-webinar-presenter-notes-container {
              height: 100%;
              display: flex;
              flex-direction: column;
              margin-bottom: 16px;
              // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
              border-radius: 10px;
              border: 1px solid #d5d5d5;
            }
            .ir-ws-webinar-presenter-notes-text-box {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              border-top: 1px solid #d5d5d5;
              border-left: 1px solid #d5d5d5;
              border-right: 1px solid #d5d5d5;
              padding: 18px 15px;
              background: linear-gradient(
                90deg,
                rgb(75, 113, 247) 1%,
                rgb(96, 79, 241) 50%,
                rgb(104, 66, 239) 100%
              );
            }
            .ir-ws-webinar-presenter-notes-text {
              font-size: 16px;
              color: #ffff;
              font-weight: 500;
            }

            .ir-ws-webinar-presenter-note-input-box {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              outline: none;
              border: none;
              height: 100%;
              background: #f6f7fb;
              padding-top: 10px;
              padding-left: 15px;
              font-size: 14px;
              resize: none;
            }
          }

          .ir-ws-webinar-presenter-message-conatiner {
            width: 100%;
            height: 100%;
            // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            border: 1px solid #d5d5d5;
          }
          .ir-ws-webinar-presenter-chat-text-box {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-top: 1px solid #d5d5d5;
            border-left: 1px solid #d5d5d5;
            border-right: 1px solid #d5d5d5;
            padding: 18px 15px;
            background: linear-gradient(
              90deg,
              rgb(75, 113, 247) 1%,
              rgb(96, 79, 241) 50%,
              rgb(104, 66, 239) 100%
            );
            display: flex;
            align-items: center;
            font-weight: 500;
          }
          .ir-ws-webinar-presenter-chat-text {
            font-size: 1rem;
            color: #ffff;
          }
          .ir-ws-webinar-presenter-message-box {
            height: 35vh;
            background: #f3f3f3;
            overflow: auto;
          }
          .ir-ws-webinar-presenter-message-input-container {
            background: #f3f3f3;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          .ir-ws-webinar-presenter-message-input-send-btn-container {
            display: flex;
            background-color: #fff;
            justify-content: space-around;
            align-items: center;
            border-radius: 10px;
            width: 100%;
            margin: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          .ir-ws-webinar-presenter-message-input {
            outline: none;
            border: none;
            resize: none;
            width: 80%;
            font-size: 0.8rem;
            padding-right: 5px;
            padding-left: 5px;
            padding-top: 12px;
            background-color: #fff;
            color: #000;
          }
          .ir-ws-webinar-presenter-message-input::placeholder {
            color: #000;
          }
          .ir-ws-webinar-message-send-btn {
            width: 28px;
            transform: rotate(45deg);
            cursor: pointer;
            margin-right: 5px;
            transition: fill 0.3s;
            &:hover {
              fill: #6448f0;
            }
          }

          .ir-ws-webinar-presenter-message-sender {
            width: 30px;
            height: 30px;
            background-color: #6448f0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .ir-ws-webinar-presenter-message-name {
            font-size: 0.7rem;
            color: #ffff;
          }

          .ir-ws-webinar-sender-message {
            display: flex;
            flex-direction: row-reverse;
            gap: 3.5%;
            margin: 1rem 0.5rem 1rem 0.5rem;
          }

          .ir-ws-webinar-presenter-message-receiver-box {
            display: flex;
            margin: 1rem 0.5rem 1rem 0.5rem;
            gap: 3.5%;
          }

          .ir-ws-webinar-presenter-meeting-container {
            /* height: 100%; */
            /* overflow: hidden; */
            position: relative;
            width: 100%;
            margin: 0 20px;
          }

          .ir-ws-presenter-local-video {
            width: 100%;
            height: calc(100% - 75px);
            border-radius: 10px;
          }

          .ir-ws-webinar-local-video-content-share-active {
            // width: 100%;
            // height: 10%;
            width: 150px;
            height: 150px;
            position: absolute;
            z-index: 2;
            right: 1%;
            // top: 73%;
            bottom: 10px;
            right: 10px;
          }

          .ir-ws-webinar-presenter-meeting-control {
            display: flex;
          }

          .ir-ws-webinar-presenter-meeting-leave-btn {
            padding-block: 10px;
            padding-inline: 20px;
            background: rgba(255, 0, 31, 0.74);
            color: #ffff;
            border-radius: 15px;
            border: none;
            margin-right: 16px;
            cursor: pointer;

            &:hover {
              background: rgba(255, 0, 31, 1);
            }
          }

          .ir-ws-webinar-presenter-meeting-control-container {
            align-items: center;
            background: #f3f3f3;
            border: 2px solid #d9d9d9;
            border-radius: 15px;
            display: flex;
            // height: 8vh;
            justify-content: space-between;
            margin-bottom: 0;
            width: 500px;
            position: absolute;
            bottom: 0;
            left: 50%;
            padding: 8px 15px;
            transform: translateX(-50%);
          }

          .ir-ws-webinar-presenter-meeting-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 150px;

            .ir-ws-webinar-presenter-meeting-copy-btn {
              background: linear-gradient(
                90deg,
                rgb(43, 167, 255) 0%,
                rgb(104, 66, 239) 100%
              );
              color: #fff;
              border: 0;
              border-radius: 50%;
              width: 32px;
              height: 32px;
              margin-right: 15px;

              span {
                font-size: 18px;
              }
            }
            .eWhPFZ {
              margin: 0;

              button[label="Content"] {
                display: none;
              }
            }

            button {
              background: linear-gradient(
                90deg,
                rgb(43, 167, 255) 0%,
                rgb(104, 66, 239) 100%
              );
              color: #fff;
            }
          }

          .ir-ws-black-presenter-video {
            background-color: #000;
            width: 100%;
            height: 100%;
            border-radius: 15px;

            &.test-content-share {
              width: 0;
              height: 0;
            }
          }
          .ir-ws-black-presenter-video-pop-up {
            display: none;
          }
        }
      }
    }
  }
}
.ir-ws-webinar-meeting-presenter-passcode {
  margin-top: 15px;
}
.control-icon {
  color: #fff;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 1.7;
  cursor: pointer;
  border-radius: 50%;
  background-color: #4c70f7;
}
.control-icon-active {
  line-height: 1.9;
}
.control-icon-inactive {
  cursor: pointer;
  background: #f3f3f3;
  border: 3px solid #4c70f7;
  transition: background-color 0.3s;
  color: #4c70f7;
  &:hover {
    background-color: #4c70f7;
    color: #fff;
  }
}

.ir-ws-default-recording {
  width: 200px !important;
}

.ir-ws-webinar-toggle-dots {
  display: none;
}
.ir-ws-webinar-three-dots-cont {
  display: none;
}
.ir-ws-webinar-participant-close-btn {
  display: none;
}
@media only screen and (max-width: 768px) {
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-participants-box {
    // display: none;
    transition: opacity 0.3s;
  }
  .ir-ws-webinar-presenter-none {
    display: none;
    opacity: 0%;
    transition: opacity 0.3s;
  }
  .ir-ws-webinar-presenter-block {
    position: absolute;
    width: 70% !important;
    z-index: 10;
    height: 92% !important;
    // left: -7px;
    left: 0px;
    top: 0px;
    opacity: 100%;
    // background: white;
    transition: opacity 0.3s;
  }

  .ir-ws-webinar-chat-none {
    display: none !important;
    opacity: 0%;
    transition: opacity 0.3s;
  }
  .ir-ws-webinar-chat-block {
    position: absolute;
    width: 70% !important;
    z-index: 10;
    height: 92% !important;
    right: 0px;
    top: 0px;
    opacity: 100%;
    // background: white;
    transition: opacity 0.3s;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-message-notes-container {
    // display: none;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-message-notes-container
    .ir-ws-webinar-presenter-notes-container {
    // display: none;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-meeting-container {
    margin: 0 0;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-meeting-control-container {
    bottom: 0px !important;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-meeting-control-container {
    width: 100%;
    padding: 8px;
    // gap: 20px;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-logo-pack {
    width: 100%;
  }
  .ir-ws-webinar-presenter-notes-text-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ir-ws-webinar-presenter-chat-text-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-logo-pack-record-container {
    margin-left: 0px;
    width: 100%;
  }
  .ir-ws-webinar-toggle-dots {
    display: block;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-meeting-leave-btn {
    margin-right: 0px;
    padding-inline: 10px;
  }
  .ir-ws-webinar-toggle-dots {
    position: relative;
    display: block;
  }
  .ir-ws-webinar-three-dots-cont {
    display: block;
    position: absolute;
    bottom: 50px;
    right: 0px;
  }

  .ir-ws-webinar-group-chat-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container
    .ir-ws-webinar-presenter-meeting-control {
    width: 70%;
    position: relative;

    // margin-right: 10px;
  }
  .ir-ws-webinar-participant-close-btn {
    display: block;
    color: #fff;
  }
  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-webinar-name-text {
    font-size: 12px;
    padding: 0 8px;
    text-align: right;
  }

  .ir-ws-webinar-presenter-main-container
    .ir-ws-webinar-presenter-inner-main-container
    .ir-ws-webinar-presenter-inner-sub-container
    .ir-ws-webinar-presenter-main-screen-container
    .ir-ws-webinar-presenter-inner-sub-screen-container {
    height: 92%;
  }
}
