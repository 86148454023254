.ir-cours-inner-list-container {
  background: #f6f3ff;
}
.ir-cours-list-grid-container {
  display: grid;
  gap: 2rem;
  // grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.ir-cours-list-grid-img-container:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  left: 0;
}

.ir-cours-list-grid-img-container .ir-cours-list-grid-img {
  /* max-width: 100%; */
  height: 100%;
  left: 50%;
  max-width: none;
  position: absolute;
  top: 0;
  width: auto;
  transform: translateX(-50%);
}

i.ir-cours-list-grid-play-btn {
  font-size: 40px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  line-height: 0;
  opacity: 0.8;
}

i.ir-cours-list-grid-play-btn:hover {
  cursor: pointer;
  opacity: 1;
}

.ir-cours-list-grid-img-container {
  // width: 9rem;
  width: 100%;
  // height: 11rem;
  height: 16rem;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ir-cours-list-grid-ind-inner {
  // border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.ir-cours-list-grid-content-container {
  flex: 1;
  padding: 1rem;
  min-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ir-ws-course-list-main-box {
  flex-direction: column;
}
.ir-cours-list-grid-content-container h3 {
  // margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.ir-cours-list-grid-content-container p {
  margin-bottom: 0;
  font-size: 14px;
}

.ir-cours-list-grind-ind-container {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}

.ir-cours-list-grid-price-read-container {
  justify-content: space-between;
  align-items: center;
}

.ir-cours-list-grid-price-container {
  font-weight: bold;
  color: #6842ef;
  font-size: 20px;
}

.ir-cours-list-grid-readmore-container {
  font-size: 14px;
  color: #6842ef;
  font-weight: bold;
}

.ir-cours-list-grid-readmore-container:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ir-ws-course-homepage-show-more-text {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  margin-top: 10px;

  p {
    margin: 0;
    color: #6842ef;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
.ir-ws-course-show-button-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
}
@media (min-width: 991px) {
  .ir-cours-list-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
  .ir-ws-course-list-main-box {
    flex-direction: row;
  }
  .ir-cours-list-grid-img-container {
    width: 9rem;
    // width: 100%;
    height: 11rem;
    // height: 16rem;
  }
}
