.ir-ws-sign-inner-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 24px;
  /* Apply a box shadow to all sides */
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);

  .ir-forgot-password-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
    margin-top: -20px;
    p {
      text-align: right;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.ir-ws-signin-inner-container {
    max-width: 500px;
  }

  .ir-ws-sign-in-content-container {
    padding: 30px 60px;

    .ir-ws-signin-logo-inner-container {
      // margin-top: 10px;
      margin-bottom: 10px;

      .ir-ws-signin-logo-img {
        max-width: 100px;
      }
    }

    .ir-ws-signup-content-title {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 0.2px;
      line-height: 50px;
      margin-bottom: 0;
    }

    .ir-ws-signup-form-container {
      margin-top: 30px;

      .ir-ws-signup-content-group {
        position: relative;
        margin: 35px 0;

        .ir-ws-signup-input-field {
          background: none;
          color: #0b2f8a;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 1.6px;
          padding: 10px 10px 10px 5px;
          display: block;
          width: 100%;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #c6c6c6;

          &:focus {
            outline: none;
          }
        }

        .ir-ws-signup-input-field:focus ~ .ir-ws-signup-label,
        .ir-ws-signup-input-field:valid ~ .ir-ws-signup-label {
          top: -14px;
          font-size: 12px;
          color: #6842ef;
        }
        .ir-ws-signup-input-field:focus ~ .ir-ws-signup-bar:before {
          width: 100%;
        }

        .ir-ws-signup-input-field[type="password"] {
          letter-spacing: 0.3em;
        }
        .ir-ws-signup-label {
          color: rgba(21, 24, 109, 0.6);
          font-size: 16px;
          font-weight: normal;
          position: absolute;
          pointer-events: none;
          left: 5px;
          top: 10px;
          transition: 300ms ease all;
          font-size: 16px;
        }
        .ir-ws-signup-bar {
          position: relative;
          display: block;
          width: 100%;

          &:before {
            content: "";
            height: 2px;
            width: 0;
            bottom: 0px;
            position: absolute;
            background: #6842ef;
            transition: 300ms ease all;
            left: 0%;
          }
        }
      }

      .ir-ws-sign-submit-btn {
        border-radius: 5px;
        padding: 15px 25px;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.2px;
      }
    }

    .ir-ws-login-social-container {
      margin-top: 20px;

      .ir-social-logo-container {
        margin-left: 35px;
        &:first-child {
          margin-left: 0;
        }
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .ir-ws-signup-form-footer-container {
    border-top: 1px solid #aaa;
    margin-top: 30px;
    padding: 0.5rem 0;
    position: relative;
    text-align: center;
    width: 100%;

    &:after {
      background-color: #fff;
      content: "or connect with";
      font-size: 16px;
      left: 50%;
      padding: 0 8px;
      position: absolute;
      top: -13px;
      font-weight: 500;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      color: rgba(21, 24, 109, 0.6);
    }
  }

  .ir-ws-sign-footer-lin-container p {
    font-size: 12px;
    margin-top: 20px;
  }
}

.ir-ws-sign-in-main-container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  padding: 30px;
}

.ir-ws-sing-bumit-btn-hover {
  transition: background-color 0.6s ease; /* Add transition property */

  &:hover {
    background-color: #4f31bc;
  }
}
.ir-ws-register-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ir-ws-sign-button:disabled {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .ir-ws-sign-inner-container .ir-ws-sign-in-content-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.password-verification {
  color: rgb(0, 0, 0);
  height: 65px;
}
.ir-ws-change-password-btn {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.ir-ws-password-velbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  div {
    height: 6px;
    border-radius: 5px;
    background: #c1c1c1;
    width: 15%;
  }
}
.ir-ws-password-indicator {
  color: #ff0000;
  font-size: 12px;
}

@media (max-width: 720px) {
  .ir-ws-sign-in-main-container {
    padding: 10px;
  }
}