.ir-ws-searched-course-main-container {
  padding-top: 25px;
  .ir-ws-searched-courses-box {
    background: #fff;
  }
}

.ir-ws-course-search-query-text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ir-ws-course-search-query-text {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: center;
}
.ir-ws-go-back-btn {
  margin: 0;
  padding: 10px 30px;
  box-shadow: inset 0 0 0 2px #6842ef;
  background-color: #fff;
  color: #6842ef;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #6842ef;
    color: #fff;
  }
}

@media (min-width: 991px) {
  .ir-ws-course-search-query-text {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .ir-ws-course-search-query-text {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .ir-ws-course-search-query-text-box {
    margin-top: 50px;
  }
}
