.ir-ws-account-main-container {
  padding-top: 90px;

  .ir-ws-acc-title-container {
    padding-top: 80px;
    background-color: #fff;
    border-bottom: 2px solid #ececee;

    .ir-ws-account-title {
      padding-bottom: 30px;
      font-weight: 700;
      font-size: 32px;
    }
  }

  .ir-ws-account-details-main-container {
    padding-bottom: 50px;
    .ir-ws-account-details-heading {
      margin: 60px 0 40px;
    }

    .ir-ws-account-details-inner-container {
      background-color: #fff;
      border: 1px solid #e0e0e1;
      border-radius: 6px;
      padding: 30px;
      max-width: 360px;

      .ir-ws-account-details-title {
        margin: 0;
        font-size: 14px;
      }

      .ir-ws-account-details-content {
        margin-bottom: 10px;
        margin-top: 5px;
      }
    }
  }
}

.ir-ws-user-account-update-button {
  border: none;
  padding: 5px 20px;
  font-size: 16px;
  background-color: #6842ef;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  margin-right: 2px;
}
.ir-ws-webinar-update-verify-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
}
.ir-ws-verify-email-text {
  background: transparent;
  border: none;
  transition: text-decoration 0.3s ease;
  &:hover {
    text-decoration: underline;
  }
}
