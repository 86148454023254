.ir-ws-webinar-admin-user-list {
  margin-top: 5px;
}
.ir-ws-webinar-admin-userlist-detail {
  padding-bottom: 4px;
}
.ir-ws-user-delet-btn {
  background-color: #fa5252;
  transition: background-color 0.3s;
  color: #fff;
  &:hover {
    background-color: #c92a2a;
  }
}
