.ir-ws-webinar-meeting-login-container {
  left: 0;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;

  .ir-ws-webinar-meeting-login-inner-container {
    height: 100vh;
    width: 100vw;
  }

  .ir-ws-webinar-sub-inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0;
    // padding: 50px 0;
  }

  .ir-ws-webinar-meeting-logo-container {
    padding: 10px;
    text-align: center;

    .ir-ws-webinar-meeting-logo {
      width: 75px;
    }
  }

  .ir-ws-webinar-meeting-preview-container {
    flex-grow: 1;
    overflow: auto;

    .ir-ws-webinar-meeting-preview-inner-container {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 100%;
      width: 100%;
    }

    .ir-ws-webinar-meeting-preview-sub-container {
      align-items: center;
      display: flex;
      height: 540px;
      justify-content: center;
      width: 100%;
      flex-direction: column;
    }

    .ir-ws-webnar-meeting-webinar-name {
      text-align: center;
      font-weight: bold;
      font-size: 22px;
      padding-top: 15px;
    }

    p.ir-ws-webnar-meeting-webinar-time-date {
      text-align: center;
      padding-top: 10px;
      font-size: 16px;
      font-weight: 600;
    }
    .ir-ws-webnar-meeting-webinar-join-meeting-text {
      font-size: 32px;
      padding-top: 10px;
      font-weight: 600;
      color: #6842ef;
      text-align: center;
    }
    .ir-ws-webnar-meeting-webinar-timer {
      font-size: 60px;
      font-weight: 600;
      padding-top: 10px;
      color: #6842ef;

      span {
        font-size: 18px;
      }
    }

    .ir-ws-webinar-meeting-video-tile-preview {
      display: inline-flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      max-width: 600px;
    }

    .ir-ws-webinar-video-title-preview-inner {
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
      flex: 0 1;
      margin: 1rem 0.5rem 1rem 1rem;
      min-width: 448px;
      // overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
    }

    .ir-ws-webinar-video-title-preview-inner-sub {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .ir-ws-webinar-meeting-blank-video {
      background-color: rgb(32, 33, 36);
      box-sizing: border-box;
      padding-top: 56.25%;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 8px;

      .ir-ws-admin-video-off-container {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;

        img {
          opacity: 0.5;
        }
      }
    }

    .ir-ws-audioVideo-control {
      bottom: 16px;
      display: flex;
      left: 50%;
      transform: translateX(-50%);
      justify-content: space-between;
      width: 150px;

      div {
        // margin: 0;
        text-align: center;
      }

      &.ir-ws-camera-enabled {
        .ch-control-bar-item-iconButton {
          &[label="Video"] {
            background: linear-gradient(
              90deg,
              rgb(43, 167, 255) 0%,
              rgb(104, 66, 239) 100%
            );
          }
        }
      }

      .ch-control-bar-item-iconButton {
        color: #fff;
        border-radius: 50%;
        background-color: #d9d9d9;
        outline: none !important;
        border: 0 !important;
        // font-size: 24px;

        &:hover {
          background: linear-gradient(
            90deg,
            rgb(43, 167, 255) 0%,
            rgb(104, 66, 239) 100%
          );
        }

        &[label="Mute"] {
          background: linear-gradient(
            90deg,
            rgb(43, 167, 255) 0%,
            rgb(104, 66, 239) 100%
          );
        }

        &:focus {
          outline: 0;
          border: 0;
        }

        span {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .ir-ws-webinar-meeting-input-button-container {
    text-align: center;
    padding-bottom: 30px;
  }

  .ir-ws-webinar-meeting-presenter-name {
    text-align: center;
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .ir-ws-webinar-meeting-presenter-name-input {
    outline: none;
    border-radius: 10px;
    width: 100%;
    border: none;
    height: 40px;
    padding-left: 20px;
    font-size: 16px;
    :focus {
      outline: 2px solid #6842ef !important;
    }
  }
  .ir-ws-webinar-meeting-join-button {
    background-color: #6842ef;
    background-size: 400%;
    border: none;
    border-radius: 8px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    height: 40px;
    letter-spacing: 0.2px;
    line-height: 22px;
    padding: 10px 40px;
    position: relative;
    text-align: center;
    width: fit-content;
    margin-top: 15px;

    &:hover {
      text-decoration: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: inherit;
      border-radius: inherit;
      background: rgb(43, 167, 255);
      background: linear-gradient(
        90deg,
        rgba(43, 167, 255, 1) 0%,
        rgba(104, 66, 239, 1) 100%
      );
      transition: all 0.5s;
    }

    &:hover::before {
      transform: scaleX(1);
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}

.ir-ws-webinar-meeting-login-container
  .ir-ws-webinar-meeting-join-button:disabled {
  opacity: 0.6;
}

.ir-ws-webinar-meeting-login-container
  .ir-ws-webinar-meeting-join-button:disabled:hover:before {
  width: 0;
  transform: scaleX(0);
}

@media (max-width: 768px) {
  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webinar-video-title-preview-inner {
    max-width: 100%;
    margin: 1rem 0 1rem 0;
    min-width: 100%;
  }
  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webinar-meeting-video-tile-preview {
    padding: 0 15px;
    flex-grow: inherit;
  }

  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webnar-meeting-webinar-timer {
    font-size: 16px;
    text-align: center;
    padding: 0 15px;
  }
  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webinar-meeting-preview-sub-container {
    height: 100vh;
  }

  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webinar-meeting-blank-video {
    padding-top: 45%;
  }

  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webinar-meeting-blank-video
    .ir-ws-admin-video-off-container
    img {
    width: 50px;
  }

  .ir-ws-admin-presenter-screen-off-text {
    font-size: 12px;
  }
  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webinar-video-title-preview-inner-sub {
    height: 250px;
  }
  .ir-ws-webinar-meeting-login-container
    .ir-ws-webinar-meeting-preview-container
    .ir-ws-webnar-meeting-webinar-join-meeting-text {
    font-size: 20px;
  }
}
