.ir-course-accordion-section {
  width: 100%;
  // background-color: red;
  // border: 3px solid red;
  display: flex;
  font-family: "inter";
  .ir-course-accordian-container {
    // padding-inline: 8%;
    width: 100%;
    .ir-courses-course-content-text {
      // font-size: 1.6rem;
      font-size: 25px;
      font-weight: 700;
      color: #6842ef;
      margin: 0;
      // padding-left: 5rem;
      margin-bottom: 1rem;
    }
    .ir-courses-course-expand-sections-text {
      display: none;
      font-size: 1.2rem;
      font-weight: 700;
      color: #6842ef;
      margin: 0;
      text-align: right;
      margin-bottom: 1rem;
      cursor: pointer;
      padding-right: 4rem;
    }
    .ir-ws-accordion-container {
      background: #d8ceff;
      width: 100%;
      margin-bottom: 10px;
      height: max-content;
      // padding: 18px;
      padding: 14px;
      border-radius: 10px;
      cursor: pointer;
      .ir-ws-accordion-header {
        display: flex;
        // padding-inline: 3rem;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        .ir-ws-section-accordian-number {
          // margin-left: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          // gap: 20px;
          gap: 10px;
          .dorpdown-svg-accordian {
            height: 20px;
            width: 20px;
          }
          .ir-course-accordion-section-text {
            // margin-bottom: -1px;
            margin: 0;
            // font-size: 1.2rem;
            font-size: 16px;
            color: #424242;
            font-weight: 700;
          }
        }
        .ir-course-section-accordian-duration {
          // margin-right: 30px;
          display: none;
          margin: 0;
          font-size: 1.2rem;
          color: #424242;
          font-weight: 500;
        }
      }
      .ir-course-accordian-active-container {
        .ir-ws-accordian-content {
          // margin: 1rem;
          margin: 14px 2px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          // padding-inline: 5rem;
          // padding-block: 1rem;
          // padding: 14px;
          .ir-ws-lecture-checkbox-name-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            // gap: 10px;
            gap: 14px;

            .ir-course-watched-checbox {
              width: 20px;
              transform: scale(1.5);
            }
            .lecture-name {
              // width: 401px;
              // font-size: 1rem;
              font-size: 14px;
              font-weight: 700;
              color: #424242;
            }
            .ir-ws-lecture-duration {
              // margin-right: 30px;
              // width: 9%;
            }
          }
        }
      }
    }
  }
}
.ir-ws-lecture-duration {
  display: none;
}
// .ir-course-accordian-active-container > div:first-child {
//   padding-top: 1rem;
// }
// .ir-course-accordian-active-container > div:last-child {
//   padding-top: 1rem;
//   margin-bottom: 0;
// }

.ir-course-requirement-description-section {
  width: 100%;
  display: flex;
  font-family: "inter";
  // margin-block: 4rem;
  margin-bottom: 64px;
  .ir-course-requirement-description-container {
    // padding-inline: 8%;
    width: 100%;
    .ir-course-requirement-text {
      // font-size: 1.6rem;
      font-size: 25px;
      font-weight: 700;
      color: #6842ef;
      // margin: 0;
      margin-bottom: 10px;
      // padding-left: 5rem;
    }
    .ir-course-requirement-text-description {
      // font-size: 18px;
      font-size: 16px;
      margin: 0;
      // padding-block: 1rem;
      // padding-inline: 8rem;
      font-weight: 500;
      // margin-left: 1px;
    }
    .ir-ws-course-preview-requirements-list {
      font-size: 1.2rem;
      margin: 0;
      padding-block: 1rem;
      padding-inline: 9.5rem;
      font-weight: 500;
      margin-left: 1px;
    }
    .ir-course-review-cancel-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-block: 1rem;
    }
  }
}

@media (min-width: 768px) {
  .ir-ws-lecture-duration {
    display: block;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-courses-course-content-text {
    margin-bottom: 0;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-courses-course-expand-sections-text {
    display: block;
    padding-right: 0;
    font-size: 18px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-ws-accordion-container
    .ir-ws-accordion-header
    .ir-course-section-accordian-duration {
    display: block;
    font-size: 16px;
  }
}
@media (min-width: 991px) {
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-courses-course-content-text {
    padding-left: 50px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-courses-course-expand-sections-text {
    padding-right: 40px;
  }
  .ir-course-requirement-description-section
    .ir-course-requirement-description-container
    .ir-course-requirement-text {
    padding-left: 50px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-ws-accordion-container
    .ir-ws-accordion-header {
    padding: 10px 25px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-ws-accordion-container
    .ir-course-accordian-active-container
    .ir-ws-accordian-content {
    // margin: 10px 30px 10px 50px;
    margin: 0px 30px 0px 65px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ir-course-requirement-description-section
    .ir-course-requirement-description-container
    .ir-course-requirement-text-description {
    padding-left: 75px;
    padding-right: 75px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-ws-accordion-container
    .ir-ws-accordion-header
    .ir-ws-section-accordian-number
    .dorpdown-svg-accordian {
    width: 30px;
    height: 30px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-ws-accordion-container
    .ir-ws-accordion-header
    .ir-ws-section-accordian-number
    .ir-course-accordion-section-text {
    font-size: 18px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-ws-accordion-container
    .ir-ws-accordion-header
    .ir-course-section-accordian-duration {
    font-size: 18px;
  }
  .ir-course-accordion-section
    .ir-course-accordian-container
    .ir-ws-accordion-container
    .ir-course-accordian-active-container
    .ir-ws-accordian-content
    .ir-ws-lecture-checkbox-name-container
    .lecture-name {
    font-size: 18px;
  }
  .ir-ws-lecture-duration {
    font-size: 18px;
  }
  .ir-course-requirement-description-section
    .ir-course-requirement-description-container
    .ir-course-requirement-text-description {
    font-size: 18px;
  }
}
