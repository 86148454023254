.why-choose-us-container {
    text-align: center;
    // padding: 20px;
  
    .why-choose-us-main{
        
    background-color: #E2FFC5;

    .why-choose-us-header {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
    //   padding: 60px 0;
      padding-top: 60px;
      padding-bottom: 50px;
    //   border: 2px solid red;
            
      .why-choose-us-title {
        font-size: 36px;
        font-weight: 600;
        // color:#6842EF;
       
        @media (max-width:720px) {
          font-size: 26px;
          
        }
        @media (max-width:1300px) {
          font-size: 30px;
          
        }
      }
      
      .why-choose-us-subtitle {
        font-size: 24px;
        color: #6842EF;
        margin-top: 20px;
        font-weight: 600;
        @media (max-width:720px) {
          font-size: 18px;
          
        }
        @media (max-width:1300px) {
          font-size: 20px;
          
        }
      }
      
      .why-choose-us-description {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        padding: 0 60px;
        font-size: 18px;
        font-weight: 500;
        color: #252b42;
        @media (max-width:720px) {
          font-size: 16px;
          padding: 0 10px;
        }

      }
     }
    }
    
  
    .who-can-benefit {
    //   padding: 40px 0;
      // border: 2px solid red;
      padding-bottom: 0 !important;
  
      .who-can-benefit-title {
        font-size: 36px;
        font-weight: 600;
        // color: #6842EF;
        @media (max-width:720px) {
          font-size: 26px;
          
        }
        @media (max-width:1300px) {
          font-size: 30px;
          
        }
      }
  
      .who-can-benefit-description {
        font-size: 22px;
        font-weight: 500;
        color: #252b42;
        margin-bottom: 30px;

        @media (max-width:720px) {
          font-size: 18px;
          
        }
        @media (max-width:1300px) {
          font-size: 20px;
          
        }
      }
  
      .benefit-cards {
        // max-width: 1200px;
        // width: 100%;
        // margin: 0 auto;
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        // // gap: 80px;
  
        // @media (max-width: 768px) {
        //   flex-direction: column;
        //   align-items: center;
        //   gap: 40px;
        // }
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 70px;
      @media (max-width: 1320px) {
        gap: 40px;
      }
  
      @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        // width: 80%;
      }
  
      @media (max-width: 860px) {
        grid-template-columns: 1fr;
         width: 300px;
        margin: 0 auto;
      }
  
        .benefit-card {
          background-color: #6842EF;
          color: white;
          padding: 40px;
          border-radius: 10px;
          width: 340px;
          height: 340px;
          text-align: center;
          @media (max-width: 1280px) {
            width: 300px;
            height: 300px;
            padding: 30px;
          }    
  
          .benefit-icon {
            height: 70px;
            margin-top: 40px;
            margin-bottom: 10px;
            @media (max-width: 860px) {
              margin-top: 10px;
            }
          }
  
          .benefit-title {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: 600;
          }
  
          .benefit-description {
            font-size: 16px;
          }
        }
      }
    }
  }
  
 
  .doctors-icon {
    background: url('../../assets/images/case-doctor-icon.png') no-repeat center;
    background-size: contain;
  }
  
  .medicos-icon {
    background: url('../../assets/images/case-medicos-icon.png') no-repeat center;
    background-size: contain;
  }
  
  .senior-doctors-icon {
    background: url('../../assets/images/case-senior-icon.png') no-repeat center;
    background-size: contain;
  }
  