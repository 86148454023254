.ir-ct-an-sb-main-container {
    &.ir-chat-navigation-container { 
        &.side-menu {
            width: 260px;

            .logo {
                padding-bottom: 15px;
                margin: 0 25px;
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);

                img {
                    width: 65px;
                }
                p {
                    font-size: 15px;
                }
            }

            .profile-icon {
                height: 75px;
                width: 75px;
                border-radius: 50%;
                background-color: #fafafa;
                border: 1px solid #f6f6f9;
                padding: 0.25rem;
                max-width: 100%;
            }

            .ir-ct-an-side-over-flow-container {
                overflow-y: auto;
                height: 100%;
            }
            .ir-ct-an-profile-container {
                text-align: center;
                margin: 40px 0;
                .profile-bg-replace {
                    text-align: center;
                }
            }

            .ir-ct-an-sb-list-container {
                .side-nav-item {
                    &:not(.activeMenuItem) {
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    .nav-link {
                        padding-left: 40px;
                        justify-content: left;

                        .ir-ct-an-span-item {
                            padding-left: 20px;
                            z-index: 1;
                            color: #fff;
                            font-size: 15px;
                        }
                    }

                    &.activeMenuItem {
                        .ir-ct-an-span-item {
                            color: #555;
                        }
                    }
                }

                #ir-chat-admin-sidebar-logOut {
                    margin-top: auto;
                }
            }
        }
    }
}