.ir-ws-cases-inside-gradient-container {
  padding: 4px;
  border-radius: 48px 0px 48px 48px;
  background: linear-gradient(90deg, #fcd3d7 0%, rgba(247, 169, 160, 0) 100%);
  .ir-ws-hme-cases-main-container {
    border-radius: 48px 0px 0px 48px;
    background: linear-gradient(90deg, #fcffef 0%, #f6f7fb 100%);

    .ir-ws-hme-cases-inner-container {
      .ir-ws-hme-cases-img-container {
        text-align: center;
        img {
          width: 85%;
          max-width: 85%;
        }
      }
      .ir-ws-hme-cases-content-container {
        .ir-ws-hme-cases-title {
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 50px; /* 125% */
          letter-spacing: 0.2px;
          padding-bottom: 35px;
          text-align: center;

          &::after {
            position: absolute;
            content: "";
            width: 100px;
            bottom: 0;
            left: 45%;
            height: 7px;
            background-color: #ea4c89;
          }
        }

        .ir-ws-hme-cases-scnd-title {
          color: #010101;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          letter-spacing: 0.1px;
          margin-top: 30px;
          text-align: center;
        }

        .ir-ws-hme-chat-para {
          margin: 24px 0 35px;
          text-align: center;
        }

        .ir-ws-hme-chat-btn-container {
          text-align: center;
          .ir-ws-hme-chat-btn {
            // border-radius: 5px;
            padding: 15px 40px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px; /* 157.143% */
            letter-spacing: 0.2px;
            position: relative;
            background-size: 400%;
            height: 52px;
            background-size: 400%;
            color: #fff;
            border: none;
            background-color: #ea4c89;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              transform: scaleX(0);
              transform-origin: 0 50%;
              width: 100%;
              height: inherit;
              border-radius: inherit;
              background: rgb(255, 158, 110);
              background: linear-gradient(
                83deg,
                rgba(255, 158, 110, 1) 0%,
                rgba(234, 76, 137, 1) 100%
              );
              transition: all 0.8s;
            }

            &:hover::before {
              transform: scaleX(1);
            }

            span {
              position: relative;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
.ir-ws-home-impro-text {
  margin-bottom: 0px;
}
@media (min-width: 1024px) {
  .ir-ws-cases-inside-gradient-container {
    .ir-ws-hme-cases-main-container {
      .ir-ws-hme-cases-inner-container {
        display: flex;
        // padding-top: 75px;
        padding-top: 50px;
        align-items: center;

        .ir-ws-hme-cases-img-container {
          width: 55%;
          text-align: left;
        }
        .ir-ws-hme-cases-content-container {
          width: 45%;
          padding-left: 50px;
          max-width: 650px;

          .ir-ws-hme-cases-title::after {
            left: 0;
          }

          .ir-ws-hme-cases-scnd-title,
          .ir-ws-hme-cases-title,
          .ir-ws-hme-chat-para,
          .ir-ws-hme-chat-btn-container {
            text-align: left;
          }
        }
      }
    }
  }
}
