.ir-cases-category-main-container {
    background-color: #ffffff;

    .ir-ws-main-heading{
      max-width: 900px;
      margin: 0 auto;
        // color: #6842ef;
        // font-size: 38px;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 10px;
        @media (max-width:1320px) {
          font-size: 30px;
          
        }
        @media (max-width:720px) {
          font-size: 26px;
          
        }
    }
    .cat-sub-heading{
        font-weight: 500;
        // color: #000;
        max-width: 1200px;
        margin: 0 auto;
        font-size: 22px;
        @media (max-width:720px) {
          font-size: 18px;
          
        }
        @media (max-width:1320px) {
          font-size: 20px;
          
        }
    }
  }
  
  .ir-cases-cat-main-section {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin: 0 auto;

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
        // padding-left: 60px;
        // max-width: 100%;
        // padding: 0 20px;
        gap: 1.5rem;
        width: 250px;
        margin: 0 auto;
        // border: 2px solid red;
        // padding-left: 30px;
      }
    @media (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    
      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
      }
      // @media (max-width: 900px) {
      //   grid-template-columns: repeat(3, 1fr);
      //   gap: 2rem;
      // }
    
      @media (min-width: 1080px) {
        grid-template-columns: repeat(4, 1fr);
        gap: 1.5rem;
        // max-width: 1000px;
        // width: 80%;
      }
    
      @media (min-width: 1420px) {
        grid-template-columns: repeat(5, 1fr);
        gap: 1.5rem;
        // width: 100%;
      }
  
    .ir-case-cat-card {
      border: 2px solid #6842ef;
      display: flex;
    //   justify-content: space-between;
    //   align-items: center;
      gap: 14px;
      align-items: center;
    //   padding: 10px 30px;
      padding-left: 8px;
      border-radius: 40px;
      padding-top: 4px;
      padding-bottom: 4px;
      transition: background-color 0.5s ease-in-out;
      max-width: 250px;
      cursor: pointer;
  
      &:hover {
        background-color: #6842ef;
        color: #fff;
        .cat-img-container {
          background-color: #fff;

          .white-icon {
            display: none;
          }
    
         .purple-icon {
              display: block;
         }
  
          .ir-cases-body-parts-icon {
            filter: none;
            color: #6842ef;
          }
          
        }
        .ir-cases-cat-title{
            color: #FFF;
          }
      }
    }
  }
  
  .ir-cases-cat-section-container {
    margin-top: 50px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .cat-img-container {
    background-color: #6842ef;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px ;
    // padding-left: 20px;
    transition: background-color .5s ease-in-out;
     img{
        width: 40px;
        height: 40px;
        transition: opacity 0.5s ease-in-out;
     }

     .white-icon {
      display: block;
    }

    .purple-icon {
      display: none;
     }
  
  
    .ir-cases-body-parts-icon {
    //   width: 60px;
    //   height: 60px;
      color: #fff;
      transition: all 0.3s ease-in-out;
    }
  }
  
  .ir-cases-cat-title {
    // margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    color: #6842ef;
    transition: color 0.5s ease-in-out;
  }
  
