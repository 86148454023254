.ir-admin-navigation-container {
  width: 225px;
  --webkit-box-orient: vertical;
  --webkit-box-direction: normal;
  flex-direction: column;
  --webkit-box-pack: justify;
  -ms-flex-pack: justify;
  background: rgb(43, 167, 255);
  background: linear-gradient(
    360deg,
    rgb(75, 113, 247) 1%,
    rgb(96, 79, 241) 80%,
    rgb(104, 66, 239) 100%
  );
  --webkit-box-flex: 0;
  flex: none;

  .ir-ws-admin-logo-container {
    padding: 20px 0;
    .ir-admin-dashboard-logo {
      width: 105px;
      border-radius: 8px;
      padding: 5px;
    }

    p {
      margin: 4px;
      font-size: 16px;
    }
  }

  .ir-ws-admin-side-menu-nav {
    display: var(--webkit-box-flex);
    display: var(--ms-flexbox);
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    height: 70%;
    margin-top: auto;

    .ir-ws-admin-side-nav-item {
      margin-bottom: 15px;
      cursor: pointer;

      .ir-ws-admin-side-nav-item-link {
        padding: 15px 20px 15px 15px;
        padding-left: 40px;

        .ir-ws-admin-side-nav-list-icon {
          width: 30px;
          z-index: 1;
        }

        .ir-ws-admin-side-nav-list-text {
          font-size: 15px;
          padding-left: 20px;
          z-index: 1;
          color: #fff;
          font-weight: 500;
        }
        &.active:before {
          width: 95%;
        }
        &:before {
          background-color: #fff;
          border-radius: 40px 0 0 40px;
          content: "";
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: 0;
        }
      }
      &:not(.activeMenuItem):hover {
        .ir-ws-admin-side-nav-item-link {
          .ir-ws-admin-side-nav-list-text {
            color: #120a29;
            transition: color 0.3s ease;
          }
        }
      }

      &.activeMenuItem {
        .ir-ws-admin-side-nav-item-link {
          .ir-ws-admin-side-nav-list-text {
            color: #737373;
          }
        }
        &:before {
          background-color: initial;
          border-top-right-radius: 18px;
          box-shadow: 0 -20px 0 0 #fff;
          content: "";
          height: 35px;
          position: absolute;
          right: 0;
          top: 100%;
          width: 35px;
        }

        &:after {
          background-color: initial;
          border-bottom-right-radius: 18px;
          bottom: 100%;
          box-shadow: 0 20px 0 0 #fff;
          content: "";
          height: 35px;
          position: absolute;
          right: 0;
          width: 35px;
        }
      }

      &#Profile {
        padding: 25px;
        margin-top: auto;
        margin-bottom: 0;
        padding-bottom: 10px;
      }
    }
  }

  .ir-ws-admin-side-nav-profile {
    padding: 0;
  }

  .ir-ws-an-profile-container {
    text-align: center;

    .ir-ws-admin-profile-pic {
      border: 1px solid #f6f6f9;
      border-radius: 50%;
      height: 75px;
      max-width: 100%;
      width: 75px;
    }
  }

  .logout-button {
    margin: 0 auto;
    text-align: center;

    button {
      background-size: 400%;
      border: none;
      border-radius: 5px;
      color: #fff;
      display: inline-block;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      height: 45px;
      letter-spacing: 0.2px;
      line-height: 22px;
      padding: 10px 40px;
      position: relative;
      text-align: center;
      width: fit-content;
      background-color: #6842ef;

      &:hover {
        text-decoration: none;
        color: #fff;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        width: 100%;
        height: inherit;
        border-radius: 5px;
        background: rgb(43, 167, 255);
        background: linear-gradient(
          90deg,
          rgba(43, 167, 255, 1) 0%,
          rgba(104, 66, 239, 1) 100%
        );
        transition: all 0.5s;
      }

      &:hover::before {
        transform: scaleX(1);
      }

      span {
        position: relative;
        z-index: 1;
      }
    }
  }

  .ir-ws-an-profile-content {
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1499px) {
  .ir-ws-admin-side-menu-nav {
    flex-wrap: nowrap !important;
    overflow-y: auto;
  }
}
